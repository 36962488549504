import { createContext, useState, useMemo, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import storage from '../../storage';
import { contextTypes, getProviderInitialState, ContextType, Authentication } from './utility';
import api from '../../api';
import { Constants } from '../../constants';
import useReloadOnLoggedInUserChanged from '../../hooks/useReloadOnLoggedInUserChanged';
import { analytics } from '../../resources';
import useNetworkStatus from '../../hooks/useNetworkStatus';
import utils from '../../shared/utils';
import { RequestErrorCode, ResponseStatus } from '../../enums';
import { UserData } from '../../types/Authentications';
import Bugsnag from '../../bugsnag';

const AuthContext = createContext({
  ...contextTypes,
});

type AuthProviderTypes = {
  children: React.ReactNode;
};

const logoutAction = () => ({ type: Constants.LOGOUT_ACTION.type });

export function AuthProvider({ children }: AuthProviderTypes) {
  const [auth, setAuth] = useState<Authentication>(getProviderInitialState);
  const { isOnline } = useNetworkStatus();
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    storage.logout();
    analytics.logout();
    setAuth(getProviderInitialState);
    dispatch(logoutAction());
  }, [dispatch]);

  const setUserData = useCallback((data: UserData, email?: string) => {
    const userData = {
      ...data,
      id: String(data.id),
      roles: data.roles.sort(({ branch_name: branchName1 }, { branch_name: branchName2 }) =>
        branchName1.localeCompare(branchName2),
      ),
    };
    storage.setUserData(userData);
    storage.setUserId(Number(userData.id));
    Bugsnag.setUser(data.id, email, data.name);
    setAuth((prev) => ({
      ...prev,
      isAuthenticated: true,
      userData,
      ...(email ? { email } : {}),
    }));
  }, []);

  const login = useCallback(
    async (email: string, password: string): Promise<string> => {
      try {
        setAuth((prev) => ({ ...prev, isLoading: true, isFetched: true, resMessage: '' }));
        const response = await api.authentication.postLogin({ email, password });
        if (response?.data?.status === ResponseStatus.Success) {
          storage.setEmail(email);
          setUserData(response.data.result, email);
        } else {
          setAuth((prev) => ({ ...prev, resMessage: response.data?.user_message }));
        }
        return response?.data?.status;
      } catch (error: unknown) {
        setAuth((prev) => ({
          ...prev,
          error: utils.general.convertAxiosError(error as AxiosError),
        }));
        return Constants.RES_STATUS.FAIL;
      } finally {
        setAuth((prev) => ({ ...prev, isLoading: false }));
      }
    },
    [setUserData],
  );

  const fetchUserData = useCallback(
    async (accountId: string) => {
      try {
        setAuth((prev) => ({ ...prev, isLoading: true }));
        const response = await api.authentication.postGetUserBranches(accountId);

        if (response?.data?.status === ResponseStatus.Success) {
          setUserData(response.data.result);
        }
      } catch (error) {
        setAuth((prev) => ({
          ...prev,
          error: utils.general.convertAxiosError(error as AxiosError),
        }));
      } finally {
        setAuth((prev) => ({ ...prev, isFetched: true, isLoading: false }));
      }
    },
    [setUserData],
  );

  useEffect(() => {
    if (!!auth.userData.id && !auth.isFetched) {
      fetchUserData(auth.userData.id);
    }
  }, [auth.isFetched, auth.userData.id, fetchUserData]);

  useEffect(() => {
    if (
      isOnline &&
      auth.isFetched &&
      !auth.isAuthenticated &&
      !auth.isLoading &&
      !!auth.userData.id &&
      !storage.isAppRefreshedWhenConnectionIsBack() &&
      auth.error?.code === RequestErrorCode.ERR_NETWORK
    ) {
      storage.setAppRefreshedWhenConnectionIsBack();
      window.location.reload();
    }
  }, [
    auth.error?.code,
    auth.isAuthenticated,
    auth.isFetched,
    auth.isLoading,
    auth.userData.id,
    isOnline,
  ]);

  useReloadOnLoggedInUserChanged();

  const providerValue: ContextType = useMemo(
    () => ({
      auth,
      setAuth,
      logout,
      login,
    }),
    [auth, logout, login],
  );
  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

export default AuthContext;

import { useEffect } from 'react';

import { useRegisterSW } from 'virtual:pwa-register/react';

function useServiceWorker() {
  const reloadSW = '__RELOAD_SW__';

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(_, r) {
      if (reloadSW) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        r &&
          setInterval(() => {
            r.update();
          }, 20000 /* 20s for testing purposes */);
      } else {
        // eslint-disable-next-line prefer-template, no-console
        console.log('SW Registered: ' + r);
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error);
    },
  });

  useEffect(() => {
    if (needRefresh) {
      setNeedRefresh(false);
      updateServiceWorker();
    }
  }, [needRefresh, setNeedRefresh, updateServiceWorker]);
}

export default useServiceWorker;

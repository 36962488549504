/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import {
  WeekShifts,
  SelectedShift,
  AddShiftRequest,
  AddShiftResponse,
  UpdateShiftRequest,
  DeleteShiftRequest,
  AddShiftSuccessPayload,
  UpdateShiftSuccessPayload,
  DeleteShiftSuccessPayload,
  ShiftsNamesCount,
  DeleteMultiShiftRequest,
  UpdateMultiShiftRequest,
  UpdateMultiShiftSuccessPayload,
  DeleteMultiShiftSuccessPayload,
} from '../../types/Shift';
import utils from '../../shared/utils';

interface ActionType<T, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data?: T;
}

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

export interface InitialState {
  addShift: ActionType<AddShiftRequest, ResponseSuccess<AddShiftResponse>>;
  deleteShift: ActionType<DeleteShiftRequest, ResponseSuccess<boolean>>;
  deleteMultiShift: ActionType<DeleteMultiShiftRequest, ResponseSuccess<boolean>>;
  updateShift: ActionType<UpdateShiftRequest, ResponseSuccess<{ shift_id: number }>>;
  updateMultiShift: ActionType<UpdateMultiShiftRequest, ResponseSuccess<number[]>>;
  fetchShifts: ActionType<WeekShifts[], ResponseSuccess<WeekShifts[]>>;
  shiftNamesCount: ShiftsNamesCount;
  selectedShifts: {
    data: SelectedShift[];
    allShiftsHaveSameName: boolean;
  };
}

const initialState: InitialState = {
  addShift: {
    ...ACTIONS_INITIAL_STATE,
  },
  deleteShift: {
    ...ACTIONS_INITIAL_STATE,
  },
  deleteMultiShift: {
    ...ACTIONS_INITIAL_STATE,
  },
  updateShift: {
    ...ACTIONS_INITIAL_STATE,
  },
  updateMultiShift: {
    ...ACTIONS_INITIAL_STATE,
  },
  fetchShifts: {
    ...ACTIONS_INITIAL_STATE,
    data: [],
  },
  selectedShifts: {
    data: [],
    allShiftsHaveSameName: false,
  },
  shiftNamesCount: {},
};

const updateAllSelectedShiftsHaveSameName: (state: InitialState) => void = (state) => {
  state.selectedShifts.allShiftsHaveSameName = state.selectedShifts.data.every(
    (shift) => shift.name.toLowerCase() === state.selectedShifts.data[0].name.toLowerCase(),
  );
};

const addShift: (
  state: InitialState,
  shift: AddShiftRequest,
  response: ResponseSuccess<AddShiftResponse>,
) => void = (state, shift, response) => {
  shift.weekdays.forEach((weekday, weekdayIndex) => {
    const day = state.fetchShifts.data?.find((dayData) => dayData.weekday === weekday);
    if (day) {
      day.shifts.push({
        start_hour: shift.start_time,
        end_hour: shift.end_time,
        name: shift.name,
        role_requirements: shift.role_requirements,
        id: response.result.shift_ids[weekdayIndex],
        break_time: shift.break_time,
        ...(typeof shift.payment_type !== 'undefined'
          ? {
              custom_payment: {
                payment_type_id: shift.payment_type,
                wage: shift.wage || 0,
              },
            }
          : {}),
      });
    }
  });

  state.fetchShifts.data = utils.shift.sortShifts(state.fetchShifts.data || []);
};

const updateShift: (
  state: InitialState,
  newShift: UpdateShiftRequest,
  newShiftId: number,
) => void = (state, newShift, newShiftId) => {
  const day = state.fetchShifts.data?.find((dayData) => dayData.weekday === newShift.weekday);
  if (day) {
    const shiftIndex = day.shifts.findIndex((editedShift) => editedShift.id === newShift.id);
    if (shiftIndex > -1) {
      day.shifts[shiftIndex] = {
        start_hour: newShift.start_time,
        end_hour: newShift.end_time,
        name: newShift.name,
        role_requirements: newShift.role_requirements,
        ...(!Number.isNaN(newShift.break_time) && Number(newShift.break_time) >= 0
          ? { break_time: newShift.break_time }
          : {}),
        ...(newShift.payment_type && newShift.wage
          ? {
              custom_payment: {
                payment_type_id: newShift.payment_type,
                wage: newShift.wage,
              },
            }
          : {}),
        id: newShiftId,
      };
    }
  }
  state.fetchShifts.data = utils.shift.sortShifts(state.fetchShifts.data || []);
};

const updateMultipleShifts: (
  state: InitialState,
  data: UpdateMultiShiftRequest,
  newShiftIds: number[],
) => void = (state, data, newShiftIds) => {
  state.fetchShifts.data?.forEach((day) => {
    day.shifts.forEach((shift, index) => {
      const shiftIndex = data.shift_ids.indexOf(shift.id);
      if (shiftIndex > -1) {
        day.shifts[index] = {
          start_hour: data.start_time || shift.start_hour,
          end_hour: data.end_time || shift.end_hour,
          name: data.name || shift.name,
          role_requirements:
            data.role_requirements?.map((req) => ({ ...req })) || shift.role_requirements,
          ...(!Number.isNaN(data.break_time) && Number(data.break_time) >= 0
            ? { break_time: data.break_time }
            : {}),
          ...(data.payment_type && data.wage
            ? {
                custom_payment: {
                  payment_type_id: data.payment_type,
                  wage: data.wage,
                },
              }
            : {}),
          id: newShiftIds?.[shiftIndex] || shift.id,
        };
      }
    });
  });
  state.fetchShifts.data = utils.shift.sortShifts(state.fetchShifts.data || []);
};

const deleteShift: (state: InitialState, deletedShift: DeleteShiftRequest) => void = (
  state,
  deletedShift,
) => {
  const day = state.fetchShifts.data?.find((dayData) => dayData.weekday === deletedShift.weekday);
  if (day) {
    day.shifts = day.shifts.filter((shift) => shift.id !== deletedShift.id);
  }
};

const deleteMultipleShifts: (
  state: InitialState,
  deletedShifts: DeleteMultiShiftRequest,
) => void = (state, deletedShifts) => {
  state.fetchShifts.data?.forEach((day) => {
    day.shifts = day.shifts.filter((shift) => !deletedShifts.shift_ids.includes(shift.id));
  });
};

const selectAll: (state: InitialState, shiftName: string) => void = (state, shiftName) => {
  state.selectedShifts.data =
    state.fetchShifts.data?.reduce((prev: SelectedShift[], day) => {
      const dayShifts: SelectedShift[] = day.shifts
        .filter((dayShift) => dayShift.name.toLowerCase() === shiftName.toLowerCase())
        .map((selectedShift) => ({
          ...selectedShift,
          weekday: day.weekday,
          isSelectingBySelectAll:
            state.selectedShifts.data.findIndex((oldShift) => oldShift.id === selectedShift.id) ===
            -1,
        }));
      prev.push(...dayShifts);
      return prev;
    }, [] as SelectedShift[]) || [];
};

export const shift = createSlice({
  name: 'manage/shift',
  initialState,
  reducers: {
    // fetch data
    fetchShiftsStarted: (state) => {
      state.fetchShifts = { ...initialState.fetchShifts, isLoading: true };
    },
    fetchShiftstSuccess: (state, action: PayloadAction<WeekShifts[]>) => {
      state.fetchShifts.isLoading = false;
      state.fetchShifts.data = utils.shift.sortShifts(action.payload);
      state.shiftNamesCount = utils.shift.getWeekShiftNamesCount(action.payload);
    },
    fetchShiftsError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchShifts.isLoading = false;
      state.fetchShifts.data = [];
      state.shiftNamesCount = {};
      if (utils.general.isRequestError(action.payload)) {
        state.fetchShifts.error = action.payload;
      } else {
        state.fetchShifts.response = action.payload;
      }
    },

    // add shift
    addShiftStarted: (state) => {
      state.addShift = { ...initialState.addShift, isLoading: true };
    },
    addShiftSuccess: (state, action: PayloadAction<AddShiftSuccessPayload>) => {
      state.addShift.isLoading = false;
      state.addShift.data = action.payload.shift;
      state.addShift.response = action.payload.response;
      addShift(state, action.payload.shift, action.payload.response);
      state.shiftNamesCount = utils.shift.getWeekShiftNamesCount(state.fetchShifts.data || []);
    },
    addShiftError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.addShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addShift.error = action.payload;
      } else {
        state.addShift.response = action.payload;
      }
    },

    // update shift
    updateShiftStarted: (state, action: PayloadAction<UpdateShiftRequest>) => {
      state.updateShift = { ...initialState.updateShift, isLoading: true, data: action.payload };
    },
    updateShiftSuccess: (state, action: PayloadAction<UpdateShiftSuccessPayload>) => {
      state.updateShift.isLoading = false;
      state.updateShift.data = action.payload.shift;
      state.updateShift.response = action.payload.response;
      updateShift(state, action.payload.shift, action.payload.response.result.shift_id);
      state.shiftNamesCount = utils.shift.getWeekShiftNamesCount(state.fetchShifts.data || []);
    },
    updateShiftError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateShift.error = action.payload;
      } else {
        state.updateShift.response = action.payload;
      }
    },

    // delete shift
    deleteShiftStarted: (state) => {
      state.deleteShift = { ...initialState.deleteShift, isLoading: true };
    },
    deleteShiftSuccess: (state, action: PayloadAction<DeleteShiftSuccessPayload>) => {
      state.deleteShift.isLoading = false;
      state.deleteShift.data = action.payload.shift;
      state.deleteShift.response = action.payload.response;
      deleteShift(state, action.payload.shift);
      state.shiftNamesCount = utils.shift.getWeekShiftNamesCount(state.fetchShifts.data || []);
    },
    deleteShiftError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.deleteShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deleteShift.error = action.payload;
      } else {
        state.deleteShift.response = action.payload;
      }
    },

    // update multiple shifts
    updateMultiShiftStarted: (state, action: PayloadAction<UpdateMultiShiftRequest>) => {
      state.updateMultiShift = {
        ...initialState.updateMultiShift,
        isLoading: true,
        data: {
          ...action.payload,
          role_requirements: action.payload.role_requirements?.map((req) => ({ ...req })),
        },
      };
    },
    updateMultiShiftSuccess: (state, action: PayloadAction<UpdateMultiShiftSuccessPayload>) => {
      state.updateMultiShift.isLoading = false;
      state.updateMultiShift.data = {
        ...action.payload.data,
        role_requirements: action.payload.data.role_requirements?.map((req) => ({ ...req })),
      };
      state.updateMultiShift.response = action.payload.response;
      updateMultipleShifts(state, action.payload.data, action.payload.response.result);
      state.shiftNamesCount = utils.shift.getWeekShiftNamesCount(state.fetchShifts.data || []);
    },
    updateMultiShiftError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateMultiShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateMultiShift.error = action.payload;
      } else {
        state.updateMultiShift.response = action.payload;
      }
    },

    // delete multiple shifts
    deleteMultiShiftStarted: (state) => {
      state.deleteMultiShift = { ...initialState.deleteMultiShift, isLoading: true };
    },
    deleteMultiShiftSuccess: (state, action: PayloadAction<DeleteMultiShiftSuccessPayload>) => {
      state.deleteMultiShift.isLoading = false;
      state.deleteMultiShift.data = action.payload.data;
      state.deleteMultiShift.response = action.payload.response;
      deleteMultipleShifts(state, action.payload.data);
      state.shiftNamesCount = utils.shift.getWeekShiftNamesCount(state.fetchShifts.data || []);
    },
    deleteMultiShiftError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.deleteMultiShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deleteMultiShift.error = action.payload;
      } else {
        state.deleteMultiShift.response = action.payload;
      }
    },

    // selecting shifts
    selectShift: (state, action: PayloadAction<SelectedShift>) => {
      const isShiftExists =
        state.selectedShifts.data.findIndex(
          (item) => action.payload.weekday === item.weekday && action.payload.id === item.id,
        ) > -1;
      if (!isShiftExists) {
        state.selectedShifts.data.push(action.payload);
        updateAllSelectedShiftsHaveSameName(state);
      }
    },
    unSelectShift: (state, { payload }: { payload: SelectedShift }) => {
      state.selectedShifts.data = state.selectedShifts.data.filter(
        (item) => !(item.id === payload.id && item.weekday === payload.weekday),
      );
      updateAllSelectedShiftsHaveSameName(state);
    },
    selectAllShifts: (state, { payload }: { payload: string }) => {
      selectAll(state, payload);
      updateAllSelectedShiftsHaveSameName(state);
    },
    undoSelectAllShifts: (state) => {
      state.selectedShifts.data = state.selectedShifts.data.filter(
        (dayShift) => !dayShift.isSelectingBySelectAll,
      );
      updateAllSelectedShiftsHaveSameName(state);
    },
    clearSelectedShifts: (state) => {
      state.selectedShifts.data = [];
      state.selectedShifts.allShiftsHaveSameName = false;
    },

    resetDeleteSlice: (state) => {
      state.deleteShift = { ...initialState.deleteShift };
    },

    resetUpdateSlice: (state) => {
      state.updateShift = { ...initialState.updateShift };
    },

    resetAddSlice: (state) => {
      state.addShift = { ...initialState.addShift };
    },

    resetMultiActionsShiftsSlices: (state) => {
      state.updateMultiShift = { ...initialState.updateMultiShift };
      state.deleteMultiShift = { ...initialState.deleteMultiShift };
    },

    clearSlices: (state) => {
      state.addShift = { ...initialState.addShift };
      state.deleteShift = { ...initialState.deleteShift };
      state.deleteMultiShift = { ...initialState.deleteMultiShift };
      state.updateShift = { ...initialState.updateShift };
      state.updateMultiShift = { ...initialState.updateMultiShift };
      state.fetchShifts = { ...initialState.fetchShifts };
      state.shiftNamesCount = { ...initialState.shiftNamesCount };
      state.selectedShifts = { ...initialState.selectedShifts };
    },
  },
});

export default shift.reducer;

export const { actions } = shift;

const selectFetchShiftsState = (state: State<InitialState>) => state.shiftSlice.fetchShifts;
const selectSelectedShiftState = (state: State<InitialState>) => state.shiftSlice.selectedShifts;
const selectAddShiftState = (state: State<InitialState>) => state.shiftSlice.addShift;
const selectUpdateShiftState = (state: State<InitialState>) => state.shiftSlice.updateShift;
const selectDeleteShiftState = (state: State<InitialState>) => state.shiftSlice.deleteShift;
const selectShiftNamescount = (state: State<InitialState>) => state.shiftSlice.shiftNamesCount;
const selectUpdateMultiShiftState = (state: State<InitialState>) =>
  state.shiftSlice.updateMultiShift;
const selectDeleteMultiShiftState = (state: State<InitialState>) =>
  state.shiftSlice.deleteMultiShift;

export const selectors = {
  selectFetchShiftsState,
  selectSelectedShiftState,
  selectAddShiftState,
  selectUpdateShiftState,
  selectDeleteShiftState,
  selectShiftNamescount,
  selectUpdateMultiShiftState,
  selectDeleteMultiShiftState,
};

import { useRef, useEffect, useState, useMemo, RefObject } from 'react';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const usePreviousPersistent = <T>(value: T) => {
  const ref = useRef<{ value: T; prev: T | null }>({
    value,
    prev: null,
  });

  const current = ref.current.value;

  if (value !== current) {
    ref.current = {
      value,
      prev: current,
    };
  }

  // return the previous value only
  return ref.current.prev;
};

export const useIsInViewport = (ref: RefObject<HTMLDivElement>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }),
    [],
  );

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export default {
  usePrevious,
  useIsInViewport,
};

import * as yup from 'yup';
import { Constants } from '../../constants';
import { DateFormats } from '../../types/Date';
import dateUtils from './date';

export const startTime: () => yup.StringSchema<string, yup.AnyObject, undefined, ''> = () =>
  yup
    .string()
    .required('Start time is Required')
    .matches(Constants.REGEXP.FULL_TIME_24, 'Invalid value of start time');

export const endTime: () => yup.StringSchema<string, yup.AnyObject, undefined, ''> = () =>
  yup
    .string()
    .required('End time is Required')
    .matches(Constants.REGEXP.FULL_TIME_24, 'Invalid value of end time');

export const shiftName: (
  shiftNames?: string[],
) => yup.StringSchema<string, yup.AnyObject, undefined, ''> = (shiftNames = []) =>
  yup
    .string()
    .trim()
    .required('Shift name is Required')
    .test('New shift', 'Shift name already exists on this day', (value) => {
      return shiftNames.indexOf(value.toLowerCase()) === -1;
    });

export const breakTime: () => yup.NumberSchema<
  number | undefined,
  yup.AnyObject,
  undefined,
  ''
> = () =>
  yup
    .number()
    .typeError('Break time must be a number')
    .moreThan(-1, 'Negative values not allowed for break time');

export const wage: () => yup.NumberSchema<number | undefined, yup.AnyObject, undefined, ''> = () =>
  yup
    .number()
    .typeError('Wage must be a number')
    .moreThan(-1, 'Negative values not allowed for wage');

export const shiftRoleRequirements: () => yup.ArraySchema<
  { num?: number | undefined }[] | undefined,
  yup.AnyObject,
  '',
  ''
> = () =>
  yup.array().of(
    yup.object().shape({
      num: yup
        .number()
        .typeError('Requirement must be a number')
        .moreThan(-1, 'Negative values not allowed for requirements'),
    }),
  );

export const date = (
  message: string = '',
  format: DateFormats = 'YYYY-MM-DD',
): yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''> =>
  yup
    .string()
    .test('validate date', message || 'Invalid date value', (value) =>
      dateUtils.getDate(value || '', format).isValid(),
    );

export const email: () => yup.StringSchema<string, yup.AnyObject, undefined, ''> = () =>
  yup.string().trim().required('Required').email('Invalid email').lowercase('');

export default {
  startTime,
  endTime,
  shiftName,
  breakTime,
  wage,
  shiftRoleRequirements,
  date,
  email,
};

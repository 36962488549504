import authentication from './authentication';
import employee from './employee';
import general from './general';
import notifications from './notifications';
import openShift from './openShift';
import reporting from './reporting';
import requests from './requests';
import roles from './roles';
import sales from './sales';
import schedule from './schedule';
import settings from './settings';
import shifts from './shifts';
import punch from './punch';

export default {
  authentication,
  employee,
  general,
  notifications,
  openShift,
  reporting,
  requests,
  roles,
  sales,
  schedule,
  settings,
  shifts,
  punch,
};

const WEEKLY_SCHEDULE_LINK = '/dashboard';
const DAILY_SCHEDULE_LINK = '/dashboard/daily';
const REQUESTS_LINK = '/requests';
const MANAGE_SHIFTS_LINK = '/manage/shifts';
const MANAGE_POSITIONS_LINK = '/manage/positions';
const MANAGE_EMPLOYEES_LINK = '/manage/employees';
const MANAGER_REPORTING_LINK = '/reporting/attendance';
const NOTIFICATIONS_LINK = '/notifications';
const SETTINGS_LINK = '/settings';
const LOGIN_LINK = '/';

export default {
  WEEKLY_SCHEDULE_LINK,
  DAILY_SCHEDULE_LINK,
  REQUESTS_LINK,
  MANAGE_SHIFTS_LINK,
  MANAGE_POSITIONS_LINK,
  MANAGE_EMPLOYEES_LINK,
  MANAGER_REPORTING_LINK,
  NOTIFICATIONS_LINK,
  SETTINGS_LINK,
  LOGIN_LINK,
};

/* eslint-disable no-param-reassign */
import {
  AccountAttendanceReportRequest,
  AttendanceReportResponse,
  Employee,
  Position,
  SummaryPosition,
} from '../../../api/reporting';
import { PaymentType } from '../../../enums';
import { ResponseSuccess } from '../../../types/Response';
import general from '../general';
import punchTools, { AccountMissingPunches, MissingPunches } from './punches';

const updateAccountSummary = (
  newData: AttendanceReportResponse,
  employee: Employee,
  dates: string[],
  newEmployeeData: Employee | undefined,
) => {
  dates.forEach((date) => {
    const dateData = employee.dates[date];
    Object.keys(dateData ?? {}).forEach((branchId) => {
      Object.keys(dateData?.[branchId]?.positions ?? {}).forEach((positionId) => {
        if (employee?.summary?.[branchId]?.positions?.[positionId]) {
          const oldPositionData = dateData?.[branchId]?.positions?.[positionId];
          const oldPositionSummary = employee?.summary?.[branchId]?.positions?.[positionId];
          if (oldPositionData && oldPositionSummary) {
            Object.keys(oldPositionSummary).forEach((field) => {
              if (
                field in oldPositionData &&
                field in oldPositionSummary &&
                !Number.isNaN(oldPositionData[field as keyof Position]) &&
                !Number.isNaN(oldPositionSummary[field as keyof SummaryPosition]) &&
                !(
                  field === 'total_allowance_punch' &&
                  (oldPositionData[field as keyof Position] === -1 ||
                    oldPositionSummary[field as keyof SummaryPosition] === -1)
                ) &&
                !(oldPositionData.payment_type === PaymentType.MONTHLY && field === 'total')
              ) {
                const oldValue = Number(oldPositionData[field as keyof Position]) ?? 0;
                oldPositionSummary[field as keyof SummaryPosition] -= oldValue;
              }
            });
          }
          const summaryCurrency =
            newData?.roles?.find?.((role) => role.id === +positionId)?.currency_id?.toString() ||
            '';
          // remove old totals
          if (summaryCurrency && newData?.summary?.sum?.[summaryCurrency] >= 0) {
            newData.summary.sum[summaryCurrency] -= oldPositionData?.total_allowance ?? 0;
          }

          if (summaryCurrency && newData?.summary?.sum_punch?.[summaryCurrency] >= 0) {
            if (oldPositionSummary?.total_allowance_punch !== -1)
              newData.summary.sum_punch[summaryCurrency] -=
                oldPositionData?.total_allowance_punch ?? 0;
          }
        }
      });
    });
  });

  if (newEmployeeData?.summary) {
    Object.keys(newEmployeeData?.summary).forEach((branchId) => {
      const positions = newEmployeeData?.summary[branchId]?.positions;
      Object.keys(positions || {}).forEach((positionId) => {
        const oldPositionSummary = employee?.summary?.[branchId]?.positions?.[positionId];
        const newPositionSummary = positions?.[positionId];
        if (!!oldPositionSummary && !!newPositionSummary) {
          Object.keys(oldPositionSummary).forEach((field) => {
            if (
              field in newPositionSummary &&
              field in oldPositionSummary &&
              !Number.isNaN(newPositionSummary[field as keyof SummaryPosition]) &&
              !Number.isNaN(oldPositionSummary[field as keyof SummaryPosition]) &&
              !(
                field === 'total_allowance_punch' &&
                (newPositionSummary[field as keyof SummaryPosition] === -1 ||
                  oldPositionSummary[field as keyof SummaryPosition] === -1)
              )
            ) {
              const newValue = Number(newPositionSummary[field as keyof SummaryPosition]) ?? 0;
              oldPositionSummary[field as keyof SummaryPosition] += newValue;
            }
          });

          const summaryCurrency =
            newData?.roles?.find?.((role) => role.id === +positionId)?.currency_id?.toString() ||
            '';

          // add new totals
          if (newData?.summary?.sum[summaryCurrency] >= 0) {
            newData.summary.sum[summaryCurrency] += newPositionSummary.total_allowance;
          }

          if (newData?.summary?.sum_punch[summaryCurrency] >= 0) {
            if (oldPositionSummary.total_allowance_punch !== -1)
              newData.summary.sum_punch[summaryCurrency] +=
                newPositionSummary.total_allowance_punch;
          }
        }
      });
    });
  }
};

const updateAccountData = (
  data: AttendanceReportResponse,
  missingPunches: MissingPunches,
  payload: {
    response: ResponseSuccess<AttendanceReportResponse>;
    data: AccountAttendanceReportRequest;
  },
) => {
  if (!data) return;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { account_id, start_date, end_date, branch_ids: branchIds } = payload.data;

  const employee = data?.employees?.find((account) => account.account_id === account_id);
  if (employee) {
    const newEmployeeData = payload.response.result.employees?.find(
      (emp) => emp.account_id === account_id,
    );
    const dates = Object.keys(employee.dates).filter(
      (date) => date >= start_date && date <= end_date,
    );

    updateAccountSummary(data, employee, dates, newEmployeeData);

    // remove missing punches
    if (missingPunches?.[account_id]) {
      delete missingPunches[account_id];
    }

    dates.forEach((date) => {
      branchIds.forEach((branchId) => {
        if (newEmployeeData?.dates?.[date]?.[branchId] && employee.dates[date]?.[branchId]) {
          employee.dates[date] = {
            ...employee.dates[date],
            [branchId]: newEmployeeData.dates?.[date]?.[branchId],
          };
        } else delete employee.dates[date]?.[branchId];
      });

      if (!Object.keys(employee.dates?.[date] || {}).length) {
        delete employee.dates[date];
      }
    });

    const accountMissingPunches = punchTools.getAccountMissingPunches(employee);
    if (Object.keys(accountMissingPunches).length)
      missingPunches[account_id] = accountMissingPunches;
    if (!Object.keys(employee.dates).length && data.employees)
      data.employees = data.employees.filter((emp) => emp.account_id !== account_id);
  }
};

const getAccountMissingPunchesNotInTentative = (
  tentativePunches: AccountMissingPunches,
  missingPunches: AccountMissingPunches,
) => {
  if (!tentativePunches) return missingPunches;
  const data = general.cloneData(missingPunches);
  // remove missing punches that are added as tentative punches
  Object.keys(tentativePunches || {}).forEach((date) => {
    Object.keys(tentativePunches[date] || {}).forEach((branchId) => {
      Object.keys(tentativePunches[date][branchId] || {}).forEach((positionId) => {
        if (data?.[date]?.[branchId]?.[positionId]) {
          delete data?.[date]?.[branchId]?.[positionId];
        }
      });
      if (Object.keys(data?.[date]?.[branchId] ?? {}).length === 0) delete data[date][branchId];
    });
    if (Object.keys(data?.[date] ?? {}).length === 0) delete data[date];
  });
  return data;
};

export default {
  updateAccountData,
  getAccountMissingPunchesNotInTentative,
};

import {
  AddScheduleRequestData,
  AddScheduleResponse,
  UpdateScheduleRequestData,
} from '../types/Schedule';
import { UpdateSelectedSchedulesData, WeeklyScheduleResponse } from '../types/WeeklySchedule';
import api from './axios';
import { Response } from '../types/Response';
import { DailyScheduleResponseData } from '../types/DailySchedule';

const getSchedule = (branchId: number, date: string) =>
  api.get(`branch/${branchId}/schedule/week?date=${date}`) as Promise<
    Response<WeeklyScheduleResponse>
  >;
const postSchedule = (branchId: number, data: AddScheduleRequestData) =>
  api.post(`branch/${branchId}/schedule`, data) as Promise<Response<AddScheduleResponse>>;
const deleteSchedule = (branchId: number, scheduleId: number) =>
  api.delete(`branch/${branchId}/schedule/${scheduleId}`) as Promise<Response<boolean>>;
const deleteMultipleSchedules = (branchId: number, data: { schedule_ids: number[] }) =>
  api.post(`branch/${branchId}/schedule/multiple/delete`, data) as Promise<Response<boolean>>;
const putSchedule = (branchId: number, scheduleId: number, data: UpdateScheduleRequestData) =>
  api.put(`branch/${branchId}/schedule/${scheduleId}`, data) as Promise<Response<boolean>>;
const postPublishSchedule = (branchId: number, data: { date: string }) =>
  api.post(`branch/${branchId}/schedule/publish`, data) as Promise<Response<boolean>>;

const getDailySchedule = (branchId: number, date: string) =>
  api.get(`branch/${branchId}/schedule/day?date=${date}`) as Promise<
    Response<DailyScheduleResponseData>
  >;

const postMultipleSchedules = (
  branchId: number,
  data: {
    manager_id: number;
    schedules: AddScheduleRequestData[];
  },
) =>
  api.post(`/branch/${branchId}/schedule/multiple`, data) as Promise<
    Response<{ schedule_ids: number[] }>
  >;
const editMultipleSchedules = (branchId: number, data: UpdateSelectedSchedulesData) =>
  api.post(`/branch/${branchId}/schedule/multiple/edit`, data) as Promise<Response<boolean>>;

export default {
  getDailySchedule,
  getSchedule,
  postMultipleSchedules,
  postPublishSchedule,
  postSchedule,
  putSchedule,
  deleteMultipleSchedules,
  deleteSchedule,
  editMultipleSchedules,
};

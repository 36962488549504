/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useCallback } from 'react';
import DialogContainer, { DialogProps } from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import LeftRightArrows from '../LeftRightArrows/LeftRightArrows';
import MuiDialogTitle from './MuiDialogTitle';
import { DialogName, KeyboardEventKeys } from '../../enums';
import { closeDialog } from '../../store/slices/dialogSlice';
import utils from '../../shared/utils';
import './Dialog.scss';

interface DateDataProps {
  setDate: (date: string, numOfDays: number) => void;
  date: string;
}

function DateData(props: DateDataProps) {
  const { date, setDate } = props;

  return (
    <div>
      <LeftRightArrows
        handleLeftClick={() => setDate(date, -1)}
        handleRightClick={() => setDate(date, 1)}
      />
      <div className="duration-cont">{utils.date.formatDate(date, 'ddd DD, MMM YYYY')}</div>
    </div>
  );
}

export interface MuiDialogProps extends Omit<DialogProps, 'title'> {
  setDate?: ((date: string | undefined, numOfDays: number) => void) | undefined;
  date?: string | undefined;
  dialogId: DialogName;
  open: boolean;
  title: string | JSX.Element | undefined;
  children: React.ReactNode;
  TitleProps?: DialogTitleProps | undefined;
  onExit?: () => void;
}

const MuiDialog: FC<MuiDialogProps> = function MuiDailog(props: MuiDialogProps) {
  const { setDate, date, dialogId, open, children, title, TitleProps, onExit, ...restProps } =
    props;
  const dispatch = useDispatch();

  const changeData = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KeyboardEventKeys.ArrowLeft) {
        if (setDate) {
          setDate(date, -1);
        }
        return;
      }

      if (event.key === KeyboardEventKeys.ArrowRight) {
        if (setDate) {
          setDate(date, 1);
        }
      }
    },
    [date, setDate],
  );

  const onHide = useCallback(() => {
    if (onExit) onExit();
    dispatch(closeDialog(dialogId));
    return undefined;
  }, [dialogId, dispatch, onExit]);

  useEffect(() => {
    document.addEventListener('keydown', changeData);

    return () => {
      document.removeEventListener('keydown', changeData);
    };
  }, [changeData]);

  return (
    <DialogContainer
      {...restProps}
      id={dialogId}
      open={open}
      aria-labelledby={dialogId}
      onClose={onHide}
      TransitionProps={{ exit: false }}
    >
      <MuiDialogTitle {...TitleProps} onClose={onHide}>
        <div className="dialog-header">
          <p>{title}</p>

          {date && setDate && (
            <div className="date">
              <DateData date={date} setDate={setDate} />
            </div>
          )}
        </div>
      </MuiDialogTitle>
      {open && children}
    </DialogContainer>
  );
};

MuiDialog.defaultProps = {
  TitleProps: {},
  date: undefined,
  setDate: undefined,
  onExit: () => {},
};

export default MuiDialog;

import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import {
  addScheduleFail,
  addScheduleStarted,
  addScheduleSuccess,
  updateScheduleFail,
  updateScheduleStarted,
  updateScheduleSuccess,
  deleteScheduleFail,
  deleteScheduleStarted,
  deleteScheduleSuccess,
} from '../slices/manageScheduleSlice';
import { ADD_SCHEDULE, UPDATE_SCHEDULE, DELETE_SCHEDULE } from '../actionTypes/manageSchedule';
import {
  AddScheduleMethod,
  AddScheduleRequestData,
  AddScheduleResponse,
  Schedule,
  UpdateScheduleRequestData,
} from '../../types/Schedule';
import { actions } from '../slices/weeklyScheduleSlice';
import weeklyScheduleActions from '../actionTypes/weeklySchedule';
import utils from '../../shared/utils';
import { isResponseSuccess } from '../../shared/utils/general';
import { Response } from '../../types/Response';

function* addSchedule({
  payload,
}: PayloadAction<{
  branchId: number;
  schedule: AddScheduleRequestData;
  roleId: number;
  method: AddScheduleMethod;
}>) {
  try {
    yield put(
      addScheduleStarted({
        method: payload.method,
        roleId: payload.roleId,
        schedule: payload.schedule,
        branchId: payload.branchId,
      }),
    );
    const response: Response<AddScheduleResponse> = yield call(() =>
      api.schedule.postSchedule(payload.branchId, payload.schedule),
    );
    if (isResponseSuccess(response.data)) {
      yield put(
        addScheduleSuccess({
          response: response.data,
          schedule: payload.schedule,
        }),
      );
      yield put(
        actions.addSchedule({
          branchId: payload.branchId,
          schedule: payload.schedule,
          scheduleId: response.data.result.schedule_id,
          roleId: payload.roleId,
        }),
      );
    } else {
      yield put(addScheduleFail(response.data));
    }
  } catch (error) {
    yield put(addScheduleFail(utils.general.convertAxiosError(error)));
  }
}

function* updateSchedule({
  payload,
}: PayloadAction<{
  branchId: number;
  schedule: UpdateScheduleRequestData;
  roleId: number;
  isCopyFrom: boolean;
}>) {
  try {
    yield put(updateScheduleStarted());
    if (!payload.isCopyFrom) {
      const response: Response<boolean> = yield call(() =>
        api.schedule.putSchedule(payload.branchId, payload.schedule.id, payload.schedule),
      );
      if (isResponseSuccess(response.data)) {
        yield put(
          updateScheduleSuccess({
            response: response.data,
            data: {
              branchId: payload.branchId,
              roleId: payload.roleId,
              schedule: payload.schedule,
            },
          }),
        );
        yield put(
          actions.updateSchedule({
            schedule: payload.schedule,
            roleId: payload.roleId,
            isCopyFrom: false,
          }),
        );
      } else {
        yield put(updateScheduleFail(response.data));
      }
    } else {
      yield put(
        updateScheduleSuccess({
          response: utils.general.getEmptySuccessResponse(true),
          data: {
            branchId: payload.branchId,
            roleId: payload.roleId,
            schedule: payload.schedule,
          },
        }),
      );
      yield put(
        actions.updateSchedule({
          schedule: payload.schedule,
          roleId: payload.roleId,
          isCopyFrom: true,
        }),
      );
    }
  } catch (error) {
    yield put(updateScheduleFail(utils.general.convertAxiosError(error)));
  }
}

function* deleteSchedule({
  payload,
}: PayloadAction<{
  branchId: number;
  schedule: Schedule;
  accountId: number;
  roleId: number;
  date: string;
  isCopyFrom: boolean;
}>) {
  try {
    if (!payload.isCopyFrom) {
      yield put(deleteScheduleStarted());
      const response: Response<boolean> = yield call(() =>
        api.schedule.deleteSchedule(payload.branchId, payload.schedule.id),
      );
      if (isResponseSuccess(response.data)) {
        yield put(
          deleteScheduleSuccess({
            response: response.data,
            data: {
              accountId: payload.accountId,
              date: payload.date,
              schedule: payload.schedule,
            },
          }),
        );
        yield put(
          actions.deleteSchedule({
            scheduleId: payload.schedule.id,
            date: payload.date,
            roleId: payload.roleId,
            accountId: payload.accountId,
            isPublished: payload.schedule.is_published,
            isCopyFrom: false,
          }),
        );
      } else {
        yield put(deleteScheduleFail(response.data));
      }
    } else {
      yield put(
        deleteScheduleSuccess({
          response: utils.general.getEmptySuccessResponse(true),
          data: {
            accountId: payload.accountId,
            date: payload.date,
            schedule: payload.schedule,
          },
        }),
      );

      yield put(
        actions.deleteSchedule({
          scheduleId: payload.schedule.id,
          date: payload.date,
          roleId: payload.roleId,
          accountId: payload.accountId,
          isPublished: payload.schedule.is_published,
          isCopyFrom: true,
        }),
      );
    }
  } catch (error) {
    yield put(deleteScheduleFail(utils.general.convertAxiosError(error)));
  }
}

function* watchManageSchedule() {
  yield all([
    takeLatest(ADD_SCHEDULE, addSchedule),
    takeLatest(UPDATE_SCHEDULE, updateSchedule),
    takeLatest(DELETE_SCHEDULE, deleteSchedule),
    takeEvery(weeklyScheduleActions.QUICK_ADD_SHIFT, addSchedule),
    takeEvery(weeklyScheduleActions.ADD_SHIFT_BY_PREFERENCES, addSchedule),
  ]);
}

export default watchManageSchedule;

import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import {
  ADD_POSITION,
  GET_POSITIONS,
  UPDATE_POSITION,
  UPDATE_POSITION_INDEX,
  DELETE_POSITION,
} from '../actionTypes/positions';
import {
  updatePositionIndexStarted,
  fetchPositionsStarted,
  createPositionStarted,
  updatePositionStarted,
  deletePositionStarted,
  fetchPositionsError,
  createPositionError,
  updatePositionError,
  deletePositionError,
  fetchPositionsSuccess,
  createPositionSuccess,
  updatePositionSuccess,
  deletePositionSuccess,
  updatePositionIndexError,
  updatePositionIndexSuccess,
} from '../slices/positionsSlice';
import {
  AddPositionRequest,
  AddPositionResponse,
  DeleteActionData,
  ManagePositionRequest,
  Position,
} from '../../types/Positions';
import { Response } from '../../types/Response';
import utils from '../../shared/utils';

function* createPosition({
  payload,
}: PayloadAction<{ branchId: number; data: AddPositionRequest }>) {
  try {
    yield put(createPositionStarted());
    const response: Response<AddPositionResponse> = yield call(() =>
      api.roles.postRole(payload.branchId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(createPositionSuccess({ role: payload.data, response: response.data }));
    } else {
      yield put(createPositionError(response.data));
    }
  } catch (error) {
    yield put(createPositionError(utils.general.convertAxiosError(error)));
  }
}

function* updatePosition({
  payload,
}: PayloadAction<{ branchId: number; data: ManagePositionRequest }>) {
  try {
    yield put(updatePositionStarted());
    const response: Response<{ role_id: number }> = yield call(() =>
      api.roles.putRole(payload.branchId, payload.data.id, {
        role_name: payload.data.role_name,
      }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(updatePositionSuccess({ role: payload.data, response: response.data }));
    } else {
      yield put(updatePositionError(response.data));
    }
  } catch (error) {
    yield put(updatePositionError(utils.general.convertAxiosError(error)));
  }
}

function* deletePosition({ payload }: PayloadAction<{ branchId: number; data: DeleteActionData }>) {
  try {
    yield put(deletePositionStarted());
    const response: Response<boolean> = yield call(() =>
      api.roles.deleteRole(payload.branchId, payload.data.role.id, {
        alternate_role_id: payload.data.alternate_role,
      }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(deletePositionSuccess({ data: payload.data, response: response.data }));
    } else {
      yield put(deletePositionError(response.data));
    }
  } catch (error) {
    yield put(deletePositionError(utils.general.convertAxiosError(error)));
  }
}

function* updatePositionIndex({ payload }: PayloadAction<{ branchId: number; items: Position[] }>) {
  try {
    const newItems = payload.items.map((value) => ({ role_id: value.id, role_index: value.index }));
    yield put(updatePositionIndexStarted());
    const response: Response<boolean> = yield call(() =>
      api.roles.updateRoleIndex(payload.branchId, { newItems }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(updatePositionIndexSuccess({ items: payload.items, response: response.data }));
    } else {
      yield put(updatePositionIndexError(response.data));
    }
  } catch (error) {
    yield put(updatePositionIndexError(utils.general.convertAxiosError(error)));
  }
}

function* fetchPositions({ payload }: PayloadAction<{ branchId: number }>) {
  try {
    yield put(fetchPositionsStarted());
    const response: Response<Position[]> = yield call(() => api.roles.getRoles(payload.branchId));
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(fetchPositionsSuccess(response.data));
    } else {
      yield put(fetchPositionsError(response.data));
    }
  } catch (error) {
    yield put(fetchPositionsError(utils.general.convertAxiosError(error)));
  }
}

function* watchManagePositions() {
  yield all([
    takeLatest(ADD_POSITION, createPosition),
    takeLatest(GET_POSITIONS, fetchPositions),
    takeLatest(DELETE_POSITION, deletePosition),
    takeLatest(UPDATE_POSITION_INDEX, updatePositionIndex),
    takeLatest(UPDATE_POSITION, updatePosition),
  ]);
}

export default watchManagePositions;

export default {
  WEEKLY_SCHEDULE: 'Weekly Schedule',
  DAILY_SCHEDULE: 'Daily Schedule',
  EMPLOYEES: 'Employees',
  LOGIN: 'Login',
  DEFAULT_SHIFTS: 'Default Shifts',
  NOTIFICATIONS: 'Notifications',
  POSITIONS: 'Positions',
  ATTENDANCE_REPORT: 'Attendance Report',
  REQUESTS: 'Requests',
  SETTINGS: 'Settings',
  MOBILE_SCREEN: 'Mobile Screen',
};

import {
  Employee,
  Branch,
  Role,
  Position,
  AttendanceReportShift,
  Punch,
} from '../../../api/reporting';
import { Constants } from '../../../constants';
import { MissingPunchPair } from './punches';
import dateUtils from '../date';
import {
  AccountData,
  ShiftError,
  SummaryRow,
  SummaryRowPosition,
  SummaryRowTotal,
  PunchInReportData,
  PunchOutReportData,
  SelectedAttendanceTableColumn,
} from '../../../modules/Reporting/types/ReportTable';
import { UserRoleSettings } from '../../../context/SettingsContext/utility';
import { Permissions } from '../../../enums';
import { RoleData } from '../../../types/Authentications';

const {
  NO_SHOW_ERROR,
  PUNCH_WITH_NO_SHIFT_ERROR,
  EARLY_PUNCHIN_ERROR,
  LATE_PUNCHIN_ERROR,
  EARLY_PUNCHOUT_ERROR,
  LATE_PUNCHOUT_ERROR,
} = Constants.REPORT_SHIFT_ERRORS;

export const getAccountSummaryRowData = (
  account: Employee,
  branches: Branch[],
  roles: Role[],
  firstPaymentType: number | undefined,
  showVariance: boolean,
) => {
  const rows = [];
  const accountSummary = account.summary;
  const summaryRow: SummaryRow = {
    rowSpan: 0,
    value: '',
    dates: [],
    branches: [],
    positions: [],
    isSummaryRow: true,
    isSummaryTotalRow: false,
    isPositionRow: false,
    key: `emplyee-summary-row-${account.account_id}`,
    showVariance,
    accountId: account.account_id,
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const _total: { [key: string]: { value: number } } = {};
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  let _totalVariance = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  let _totalPunching = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  let _totalHoliday = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  let _totalOvertime = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  let _totalRegular = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const _sum: { [key: string]: number } = {};
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const _sumPunching: { [key: string]: number } = {};
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const branchIds = Object.keys(accountSummary);
  branchIds?.forEach?.((branchId) => {
    const branchData = branches?.find((branch) => branch.id === +branchId);
    const positionIds = Object.keys(accountSummary[branchId]?.positions ?? {});
    summaryRow.branches.push({
      value: branchIds.length > 1 ? branchData?.name : '',
      rowSpan: positionIds.length,
      key: `${account.account_id}-${branchId}`,
    });
    summaryRow.rowSpan += positionIds.length;
    positionIds.forEach((positionId) => {
      const positionData = roles.find?.((role) => role.id === +positionId);
      const position = accountSummary[branchId]?.positions?.[positionId];
      const paymentType = +(firstPaymentType || positionData?.payment_type || 0);
      const positionRowData: SummaryRowPosition | undefined = position
        ? {
            key: `${account.account_id}-${branchId}-${positionId}`,
            position: branchIds.length > 1 || positionIds.length > 1 ? positionData?.name : '',
            variance: position?.variance,
            rowSpan: 1,
            paymentType: +(positionData?.payment_type ?? 0),
            firstPaymentType,
            currencySymbol: positionData?.currency_symbol ?? '',
            total: position.total,
            totalAllowanceHoliday: +position.total_allowance_holiday,
            totalAllowanceOvertime: +position.total_allowance_overtime,
            totalAllowanceRegular: +position.total_allowance_regular,
            totalHoliday: +position.total_holiday,
            totalOvertime: +position.total_overtime,
            totalRegular: +position.total_regular,
            totalPunch: +position.total_punch,
            totalPunchHoliday: +position.total_punch_holiday,
            totalPunchOvertime: +position.total_punch_overtime,
            totalPunchRegular: +position.total_punch_regular,
            totalAllowance: +position.total_allowance,
            totalAllowancePunch: +position.total_allowance_punch,
            totalAllowancePunchHoliday: +position.total_allowance_punch_holiday,
            totalAllowancePunchOverTime: +position.total_allowance_punch_overtime,
            totalAllowancePunchRegular: +position.total_allowance_punch_regular,
            showVariance,
            positionId: +positionId,
            branchId: +branchId,
            isSummaryTotalRow: false,
            isSummaryRow: true,
            isPositionRow: false,
          }
        : undefined;
      if (positionRowData) summaryRow.positions.push(positionRowData);

      // ------ calculate totals
      if (position) {
        _totalVariance += position.variance;
        _totalPunching += position.total_punch;
        _totalHoliday += +position.total_holiday;
        _totalOvertime += +position.total_overtime;
        _totalRegular += +position.total_regular;
      }
      if (!_total[paymentType]) {
        _total[paymentType] = { value: 0 };
        _total[paymentType].value = position?.total ?? 0;
      } else {
        _total[paymentType].value += position?.total ?? 0;
      }

      if (!_sum[positionData?.currency_symbol ?? '']) {
        _sum[positionData?.currency_symbol ?? ''] = position?.total_allowance ?? 0;
      } else _sum[positionData?.currency_symbol ?? ''] += position?.total_allowance ?? 0;

      if (position && +position.total_allowance_punch !== -1)
        if (!_sumPunching[positionData?.currency_symbol ?? '']) {
          _sumPunching[positionData?.currency_symbol ?? ''] = position.total_allowance_punch;
        } else _sumPunching[positionData?.currency_symbol ?? ''] += position.total_allowance_punch;
    });
  });

  summaryRow.dates.push({
    value: '',
    rowSpan: summaryRow.branches.reduce((prev, branch) => prev + branch.rowSpan, 0),
  });

  rows.push(summaryRow);

  if (branchIds.length > 1) {
    const totalSummaryRow: SummaryRowTotal = {
      rowSpan: 1,
      value: '',
      dates: [],
      branches: [],
      positions: [],
      isSummaryTotalRow: true,
      isSummaryRow: false,
      key: `emplyee-summary-total-row-${account.account_id}`,
      showVariance,
      accountId: account.account_id,
      isPositionRow: false,
    };
    totalSummaryRow.positions.push({
      key: `${account.account_id}-total`,
      rowSpan: 1,
      total: _total,
      variance: _totalVariance,
      totalPunch: _totalPunching,
      totalAllowance: _sum,
      totalAllowancePunch: _sumPunching,
      showVariance,
      totalHoliday: _totalHoliday,
      totalOvertime: _totalOvertime,
      totalRegular: _totalRegular,
      isPositionRow: false,
      isSummaryRow: false,
      isSummaryTotalRow: true,
    });
    totalSummaryRow.dates.push({ rowSpan: 1, value: '' });
    totalSummaryRow.branches.push({
      rowSpan: 1,
      value: 'Total',
      key: `${account.account_id}-summarytotal`,
    });
    rows.push(totalSummaryRow);
  }

  return rows;
};

const getShiftErrors = (shift: AttendanceReportShift) => {
  const error: ShiftError = {
    noShow: null,
    punchWithNoShift: null,
    earlyPunchIn: null,
    latePunchIn: null,
    earlyPunchOut: null,
    latePunchOut: null,
  };

  if (shift.shift_errors?.find((_error) => _error.type_id === NO_SHOW_ERROR.ID)) {
    error.noShow = NO_SHOW_ERROR.NAME;
  } else if (
    shift.shift_errors?.find((_error) => _error.type_id === PUNCH_WITH_NO_SHIFT_ERROR.ID)
  ) {
    error.punchWithNoShift = PUNCH_WITH_NO_SHIFT_ERROR.NAME;
  }

  if (shift.shift_errors?.find((_error) => _error.type_id === EARLY_PUNCHIN_ERROR.ID)) {
    error.earlyPunchIn = EARLY_PUNCHIN_ERROR.NAME;
  } else if (shift.shift_errors?.find((_error) => _error.type_id === LATE_PUNCHIN_ERROR.ID)) {
    error.latePunchIn = LATE_PUNCHIN_ERROR.NAME;
  }

  if (shift.shift_errors?.find((_error) => _error.type_id === EARLY_PUNCHOUT_ERROR.ID)) {
    error.earlyPunchOut = EARLY_PUNCHOUT_ERROR.NAME;
  } else if (shift.shift_errors?.find((_error) => _error.type_id === LATE_PUNCHOUT_ERROR.ID)) {
    error.latePunchOut = LATE_PUNCHOUT_ERROR.NAME;
  }

  return error;
};

function getPositionRowData(position: Position, positionId: number, roles: Role[]) {
  return {
    position: roles?.find?.((_role) => _role.id === +positionId)?.name,
    employeeCode: position.employee_code,
    wage: +position.wage,
    totalHoliday: +position.total_holiday,
    totalOvertime: +position.total_overtime,
    totalRegular: +position.total_regular,
    totalPunchHoliday: +position.total_punch_holiday,
    totalPunchOvertime: +position.total_punch_overtime,
    totalPunchRegular: +position.total_punch_regular,
    variance: position.variance,
    punches: position.punches,
    paymentType: +position.payment_type,
    total: +position.total,
    totalAllowanceHoliday: +position.total_allowance_holiday,
    totalAllowanceOvertime: +position.total_allowance_overtime,
    totalAllowanceRegular: +position.total_allowance_regular,
    totalPunch: +position.total_punch,
    totalAllowance: position.total_allowance,
    totalAllowancePunch: +position.total_allowance_punch,
    totalAllowancePunchHoliday: +position.total_allowance_punch_holiday,
    totalAllowancePunchOverTime: +position.total_allowance_punch_overtime,
    totalAllowancePunchRegular: +position.total_allowance_punch_regular,
    firstPaymentType: position.shifts[0]?.custom_payment?.payment_type_id,
    currencySymbol: position.currency_symbol,
    positionId,
    shifts: position.shifts.map((shift) => ({
      id: shift.id,
      start_time: shift.start_time,
      end_time: shift.end_time,
      break_time: shift.break_time,
      note: shift.note,
      error: getShiftErrors(shift),
      name: shift.name,
      custom_payment: shift.custom_payment
        ? {
            payment_type_id: +shift.custom_payment.payment_type_id,
            wage: shift.custom_payment.wage,
          }
        : undefined,
    })),
  };
}
const convertDataToRowData = (data: Employee[], branches: Branch[], roles: Role[]) => {
  const todayDate = dateUtils.now(true);
  const rows: (AccountData | SummaryRow | SummaryRowTotal)[] = [];
  let showSummaryVariance = false;
  data?.forEach?.((account) => {
    let empSpan = 0;
    const accountData: AccountData = {
      rowSpan: 1,
      value: account.name,
      dates: [],
      branches: [],
      positions: [],
      isSummaryRow: false,
      key: `emplyee-row-${account.account_id}`,
      accountId: account.account_id,
      isSummaryTotalRow: false,
      isPositionRow: true,
    };
    rows.push(accountData);

    const sortedDates = Object.keys(account.dates).sort((a, b) => a.localeCompare(b));
    let firstPaymentType: number | undefined;

    sortedDates.forEach((dateKey) => {
      const dateData = {
        value: dateUtils.formatDate(dateKey, 'DD/MM/YYYY'),
        rowSpan: 1,
      };
      accountData.dates.push(dateData);
      const branchIds = account.dates[dateKey];
      let dateSpan = 0;
      Object.keys(branchIds ?? {}).forEach((branchId) => {
        const branch = branchIds?.[branchId];
        const branchData = {
          value: branches.find((_branch) => _branch.id === +branchId)?.name,
          key: `${account.account_id}-${dateKey}-${branchId}`,
          rowSpan: 1,
        };
        accountData.branches.push(branchData);
        const positionIds = Object.keys(branch?.positions ?? {});
        let branchSpan = 0;

        positionIds.forEach((roleId) => {
          const role = branch?.positions?.[roleId];
          if (role) {
            const positionRowData = getPositionRowData(role, +roleId, roles);
            const positionSpan = Math.max(role.shifts.length, role.punches.length) || 1;
            branchSpan += positionSpan;
            if (!firstPaymentType) firstPaymentType = positionRowData.firstPaymentType;
            const showVariance = dateKey <= todayDate && !!role.punches.length;
            accountData.positions.push({
              ...positionRowData,
              isPunchInFuture: todayDate < dateKey,
              date: dateKey,
              branchId: +branchId,
              rowSpan: positionSpan,
              showVariance,
              key: `${account.account_id}-${dateKey}-${branchId}-${roleId}`,
              isPositionRow: true,
              isSummaryRow: false,
              isSummaryTotalRow: false,
            });
            showSummaryVariance = showSummaryVariance || showVariance;
          }
        });
        branchData.rowSpan = branchSpan;
        dateSpan += branchSpan;
      });
      dateData.rowSpan = dateSpan;
      empSpan += dateSpan;
    });
    accountData.rowSpan = empSpan;
    rows.push(
      ...getAccountSummaryRowData(account, branches, roles, firstPaymentType, showSummaryVariance),
    );
  });
  return rows;
};

const LOGIN_DATA_KEY = 'SCHEDEX_USER_DATA';
const mergeTentativePunches = (
  punches: Punch[],
  tentativePunches: MissingPunchPair[],
  branchId: number,
  accountId: number,
  tentative = true,
) => {
  const userData = JSON.parse(localStorage.getItem(LOGIN_DATA_KEY) ?? '');
  const result: { in: PunchInReportData; out?: PunchOutReportData }[] = JSON.parse(
    JSON.stringify(punches),
  );
  const commonPunchData = {
    account_id: accountId,
    branch_id: branchId,
    manager_id: userData?.id,
    manager_name: userData?.name,
    is_original: true,
    manager_note: null,
    note: null,
    distance: 0,
    isTentative: tentative,
    geofence_id: 0,
    geofence_name: '',
  };

  tentativePunches?.forEach((punch) => {
    const punchIn: PunchInReportData | undefined =
      'in' in punch
        ? {
            ...commonPunchData,
            date: punch.in?.value || '',
            manager_note: punch.in?.note || '',
            overlap: punch.in?.overlap || false,
            message: punch.in?.message,
            is_valid: 1,
          }
        : undefined;

    const punchOut: PunchOutReportData | undefined =
      'out' in punch
        ? {
            ...commonPunchData,
            date: punch.out?.value || '',
            manager_note: punch.out?.note || '',
            overlap: punch.out?.overlap || false,
            message: punch.out?.message,
            is_valid: 1,
          }
        : undefined;

    if (punchIn && punchOut && !punch.out?.isNoPunchOut) {
      result.push({
        in: punchIn,
        out: punchOut,
      });
    } else if (punchIn && !punchOut) {
      result.push({
        in: punchIn,
      });
    } else if (!punchIn && !!punchOut && punch?.out?.isNoPunchOut) {
      const noPunchOutPair = result.find(
        (punchPair) => punchPair.in && punchPair.out && punchPair.out?.date === null,
      );
      if (noPunchOutPair) {
        noPunchOutPair.out = {
          ...noPunchOutPair.out,
          id: punch.out.id,
          ...punchOut,
        };
      }
    } else if (!punch.in && punch.out && !punch.out.isNoPunchOut) {
      const punchInWithNoOutPair = result.find((punchPair) => punchPair.in && !punchPair.out);
      if (punchInWithNoOutPair) {
        punchInWithNoOutPair.out = punchOut;
      }
    }
  });

  return result?.sort((a, b) => Number(a.in.date?.localeCompare(b.in?.date ?? '')));
};

export const isColumnHidden = (
  column: SelectedAttendanceTableColumn,
  userSettings: UserRoleSettings,
) =>
  (Object.values(Constants.REPORT_COLUMNS.PUNCHING_FEATURE_COLUMNS).includes(column.value) &&
    !userSettings?.features.allowPunching) ||
  (!userSettings?.permissions?.includes(Permissions.VIEW_FINANCIALS) &&
    [
      Constants.REPORT_COLUMNS.WAGE,
      Constants.REPORT_COLUMNS.SUM,
      Constants.REPORT_COLUMNS.PUNCHING_FEATURE_COLUMNS.SUM_PUNCHING,
    ].includes(column.value)) ||
  (column.value === Constants.REPORT_COLUMNS.ID &&
    userSettings?.branch_settings?.has_branch_employee_code !== 1);

export const getUserSettingsFromMultipleBranches = (
  branchIds: number[],
  userRoleSettings: UserRoleSettings,
  roles: RoleData[],
) => {
  const permissions: Permissions[] = [];
  const permissionsSet: Set<Permissions> = new Set();
  const userSettings: UserRoleSettings = {
    ...userRoleSettings,
    permissions,
    branch_settings: {
      ...userRoleSettings.branch_settings,
      report_display_minutes_after_dot: 0,
      report_add_annual_leave_column: 0,
      report_show_overtime_column: 0,
      report_show_holiday_column: 0,
      report_show_overtime_and_holiday_for_scheduled: 0,
      custom_payment_supported: 0,
      exported_report_round_to_quarter: 0,
      has_branch_employee_code: 0,
      report_display_decimal_time: 0,
    },
  };

  branchIds.forEach((branchId) => {
    const role = roles.find((_role) => _role.branch_id === branchId);
    // permissions
    role?.permissions?.forEach((permission) => permissionsSet.add(permission));
    userSettings.permissions = Array.from(permissionsSet);
    // branch settings
    userSettings.branch_settings.report_display_minutes_after_dot =
      role?.branch_settings.report_display_minutes_after_dot ||
      userSettings.branch_settings.report_display_minutes_after_dot;

    userSettings.branch_settings.report_add_annual_leave_column =
      role?.branch_settings.report_add_annual_leave_column ||
      userSettings.branch_settings.report_add_annual_leave_column;

    userSettings.branch_settings.report_show_overtime_column =
      role?.branch_settings.report_show_overtime_column ||
      userSettings.branch_settings.report_show_overtime_column;

    userSettings.branch_settings.report_show_holiday_column =
      role?.branch_settings.report_show_holiday_column ||
      userSettings.branch_settings.report_show_holiday_column;

    userSettings.branch_settings.report_show_overtime_and_holiday_for_scheduled =
      role?.branch_settings.report_show_overtime_and_holiday_for_scheduled ||
      userSettings.branch_settings.report_show_overtime_and_holiday_for_scheduled;

    userSettings.branch_settings.custom_payment_supported =
      role?.branch_settings.custom_payment_supported ||
      userSettings.branch_settings.custom_payment_supported;

    userSettings.branch_settings.exported_report_round_to_quarter =
      role?.branch_settings.exported_report_round_to_quarter ||
      userSettings.branch_settings.exported_report_round_to_quarter;

    userSettings.branch_settings.has_branch_employee_code =
      role?.branch_settings.has_branch_employee_code ||
      userSettings.branch_settings.has_branch_employee_code;

    userSettings.branch_settings.report_display_decimal_time =
      role?.branch_settings.report_display_decimal_time ||
      userSettings.branch_settings.report_display_decimal_time;
  });
  return userSettings;
};

export default {
  convertDataToRowData,
  mergeTentativePunches,
  isColumnHidden,
  getUserSettingsFromMultipleBranches,
};

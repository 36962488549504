const GET_ATTENDACE_REPORT = 'reporting/attendace_report/get';
const GET_ACCOUNT_ATTENDACE_REPORT = 'reporting/account_attendace_report/get';
const GET_SHIFT = 'reporting/shift/get';
const SUBMIT_TENTATIVE_PUNCHES = 'reporting/tentative_punches_submit';
const GET_SUMMARY_REPORT = 'reporting/summary_report/get';

export default {
  GET_ACCOUNT_ATTENDACE_REPORT,
  GET_ATTENDACE_REPORT,
  GET_SHIFT,
  SUBMIT_TENTATIVE_PUNCHES,
  GET_SUMMARY_REPORT,
};

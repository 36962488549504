import { PAYMENTS } from '../../constants/PaymentTypes';
import { SelectOption } from '../../types/Shared';

const PAYMENT_TYPES: SelectOption[] = [
  { value: '0', label: 'None' },
  ...Object.values(PAYMENTS).map((payment) => ({
    label: payment.label,
    value: String(payment.id),
  })),
];

type OmitOptions = {
  monthly?: boolean;
  perShift?: boolean;
  hourly?: boolean;
  none?: boolean;
  perDay?: boolean;
};

const getPaymentsForSelect = (omits?: OmitOptions) =>
  PAYMENT_TYPES.filter((payment) => {
    if (+payment.value === PAYMENTS.HOURLY.id && omits?.hourly) {
      return false;
    }

    if (+payment.value === PAYMENTS.PER_DAY.id && omits?.perDay) {
      return false;
    }

    if (+payment.value === PAYMENTS.MONTHLY.id && omits?.monthly) {
      return false;
    }

    if (+payment.value === PAYMENTS.PER_SHIFT.id && omits?.perShift) {
      return false;
    }

    if (payment.value === '0' && omits?.none) {
      return false;
    }

    return true;
  });

export default {
  getPaymentsForSelect,
};

import { SalesProjectionMethod, SalesProjectionMethodLabel } from '../../enums';
import { Sales } from '../../types/Sales';
import { EmployeeWeekDaysPayroll, WeeklyScheduleData } from '../../types/WeeklySchedule';
import { WeekDay, getDate } from './date';
import generalUtils from './general';

export const getScheduleLaborCosts = (schedule: WeeklyScheduleData, weekdays: WeekDay[]) => {
  let fixedWages = 0;
  let othertherWages = 0;
  const schedulePayroll = weekdays.reduce((accr, day) => {
    return { ...accr, [day.weekday]: { fixed_wages: 0, other_wages: 0 } };
  }, {} as EmployeeWeekDaysPayroll);

  schedule.forEach((role) => {
    role.data.forEach((account) => {
      Object.keys(account.payroll).forEach((weekday) => {
        const fixedLaborCost = account.payroll[weekday].fixed_wages;
        const otherLaborCosts = account.payroll[weekday].other_wages;

        schedulePayroll[weekday].fixed_wages += fixedLaborCost;
        schedulePayroll[weekday].other_wages += otherLaborCosts;
        othertherWages += otherLaborCosts;
        fixedWages += fixedLaborCost;
      });
    });
  });

  return {
    totalFixedWages: fixedWages,
    totalOtherWages: othertherWages,
    schedulePayroll,
  };
};

const NUM_OF_DAYS_IN_WEEK = 7;

export const getSchedulePayrollData = (data: Sales, totalPayroll: number) => {
  let totalActualSalesPercentage: React.ReactElement | number | string | undefined;
  let totalProjectedSalesPercentage: React.ReactElement | number | string | undefined;
  let totalProjectedSales = 0;
  const salesPercentageCap: number[] = [];
  let isSPActualSalesExceedCap = false;
  let isSPProjectedSalesExceedCap = false;
  let weekHasActualSales = false;
  let weekHasProjectedSales = false;
  const projectedSales: number[] = [];
  const projectedSalesComputationMethods: { [key: string]: string[] } = {
    [SalesProjectionMethodLabel.COPY_FROM_LAST_WEEK]: [],
    [SalesProjectionMethodLabel.COMPUTE_TOTAL_SALES_OF_LAST_WEEK]: [],
  };

  if (data.previous_week.length && data.current_week?.length) {
    let totalActualSales = 0;
    const weeklyCap = data.current_week[6].sales_percentage_cap;

    const lastWeekSales = data.previous_week.slice(7);
    const lastTwoWeeksSales = data.previous_week.slice(0, 7);

    // check if the the last week has projected sales and calculate the total of projected sales
    lastWeekSales.forEach((day, index) => {
      const daySales = day.sales_values.length
        ? day.sales_values
        : lastTwoWeeksSales[index].sales_values;
      weekHasProjectedSales = weekHasProjectedSales || daySales.length > 0;
      totalProjectedSales += daySales.reduce((accr, sale) => accr + sale.value, 0);
    });

    // compute the projected sales
    lastWeekSales.forEach((day, index) => {
      if (
        data.current_week[index].sales_projection_computation ===
        SalesProjectionMethod.COPY_FROM_LAST_WEEK
      ) {
        projectedSales.push(day.sales_values.reduce((accr, current) => accr + current.value, 0));
      } else if (
        data.current_week[index].sales_projection_computation ===
        SalesProjectionMethod.COMPUTE_TOTAL_SALES_OF_LAST_WEEK
      ) {
        projectedSales.push(totalProjectedSales / NUM_OF_DAYS_IN_WEEK);
      }
    });

    data.current_week.forEach((day) => {
      weekHasActualSales = weekHasActualSales || day.sales_values.length > 0;
      totalActualSales += day.sales_values.reduce((accr, sale) => accr + sale.value, 0);
      salesPercentageCap.push(day.sales_percentage_cap);

      // get the current week computation methods that applied on the projected sales
      if (day.sales_projection_computation === SalesProjectionMethod.COPY_FROM_LAST_WEEK) {
        projectedSalesComputationMethods[SalesProjectionMethodLabel.COPY_FROM_LAST_WEEK].push(
          getDate(day.date).format('ddd'),
        );
      }

      if (
        day.sales_projection_computation === SalesProjectionMethod.COMPUTE_TOTAL_SALES_OF_LAST_WEEK
      ) {
        projectedSalesComputationMethods[
          SalesProjectionMethodLabel.COMPUTE_TOTAL_SALES_OF_LAST_WEEK
        ].push(getDate(day.date).format('ddd'));
      }
    });

    if (totalActualSales !== 0) {
      totalActualSalesPercentage = generalUtils.toFixedDecimals(
        (totalPayroll / totalActualSales) * 100,
        2,
      );

      isSPActualSalesExceedCap = totalActualSalesPercentage > weeklyCap;
      totalActualSalesPercentage = `${totalActualSalesPercentage}%`;
    } else {
      totalActualSalesPercentage = 'N/A';
    }

    if (totalProjectedSales !== 0) {
      totalProjectedSalesPercentage = generalUtils.toFixedDecimals(
        (totalPayroll / totalProjectedSales) * 100,
        2,
      );
      isSPProjectedSalesExceedCap = Number(totalProjectedSalesPercentage) > weeklyCap;
      totalProjectedSalesPercentage = `${totalProjectedSalesPercentage}%`;
    } else {
      totalProjectedSalesPercentage = 'N/A';
    }
  }

  return {
    totalActualSalesPercentage,
    totalProjectedSalesPercentage,
    totalProjectedSales,
    salesPercentageCap,
    isSPActualSalesExceedCap,
    isSPProjectedSalesExceedCap,
    weekHasActualSales,
    weekHasProjectedSales,
    projectedSales,
    projectedSalesComputationMethods,
  };
};

export const getPayrollPercentage: (
  sales: (number | null)[],
  payroll: { fixed_wages: number; other_wages: number }[],
) => (null | string | number)[] = (sales, payroll) =>
  sales.map((sale, index) => {
    if (sale === 0) return 'N/A';
    if (sale === null) return null;
    return ((payroll[index].fixed_wages + payroll[index].other_wages) / sale) * 100;
  });

export default {
  getScheduleLaborCosts,
  getSchedulePayrollData,
  getPayrollPercentage,
};

import { FC, memo, useEffect, useCallback } from 'react';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import './style.scss';

interface RouterPromptProps {
  when: boolean;
  onLeave?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  className?: string;
  message: string | (string | JSX.Element)[];
}

const RouterPrompt: FC<RouterPromptProps> = memo(function RouterPrompt(props: RouterPromptProps) {
  const { when, onLeave, onCancel, okText, cancelText, className, message } = props;

  // Allow the submission navigation to the same route to go through
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname,
    [when],
  );
  const blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
      onCancel?.();
    }
  }, [blocker, when, onCancel]);

  useEffect(() => {
    return () => {
      blocker.reset?.();
    };
  }, [blocker]);

  return (
    <Dialog
      className={`discard-changes-dialog ${className || ''}`.trim()}
      open={blocker.state === 'blocked'}
    >
      <DialogContent>
        <DialogContentText className="message">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (blocker.state !== 'proceeding') {
              onLeave?.();
              blocker.proceed?.();
            }
          }}
          variant="outlined"
          color="error"
        >
          {okText}
        </Button>
        <Button
          onClick={() => {
            if (blocker.state !== 'proceeding') {
              onCancel?.();
              blocker.reset?.();
            }
          }}
          color="primary"
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

RouterPrompt.defaultProps = {
  className: '',
  cancelText: 'Cancel',
  okText: 'Leave',
  onCancel: () => {},
  onLeave: () => {},
};

export default RouterPrompt;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResponseError, RequestError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import utils from '../../shared/utils';
import {
  AddEmployeeParams,
  AddEmployeesResponseDataResult,
  CheckAccountResponseDataResult,
  Country,
  Employee,
  ImportEmployeeParams,
  ResetEmployeePasswordParams,
  UpdateEmployeeAnnualLeaveParams,
  UpdateEmployeeParams,
  UpdateEmployeeRoleParams,
} from '../../types/Employees';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data?: Req;
}

export type InitialState = {
  fetchEmployees: ActionType<Employee[], ResponseSuccess<Employee[]>>;
  createEmployee: ActionType<AddEmployeeParams, ResponseSuccess<AddEmployeesResponseDataResult>>;
  updateEmployee: ActionType<UpdateEmployeeParams, ResponseSuccess<boolean>>;
  resetEmployeePassword: ActionType<ResetEmployeePasswordParams, ResponseSuccess<boolean>>;
  updateAnnualLeave: ActionType<UpdateEmployeeAnnualLeaveParams, ResponseSuccess<boolean>>;
  deleteEmployee: ActionType<{ account_id: number }, ResponseSuccess<boolean>>;
  updateEmployeeRole: ActionType<UpdateEmployeeRoleParams, ResponseSuccess<boolean>>;
  importEmployee: ActionType<ImportEmployeeParams, ResponseSuccess<boolean>>;
  checkEmployeeAccount: ActionType<
    { email: string },
    ResponseSuccess<CheckAccountResponseDataResult>
  >;
  fetchCountries: ActionType<Country[], ResponseSuccess<Country[]>>;
  importedEmployee: CheckAccountResponseDataResult | null;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

const initialState: InitialState = {
  fetchEmployees: { ...ACTIONS_INITIAL_STATE },
  createEmployee: { ...ACTIONS_INITIAL_STATE },
  updateEmployee: { ...ACTIONS_INITIAL_STATE },
  resetEmployeePassword: { ...ACTIONS_INITIAL_STATE },
  deleteEmployee: { ...ACTIONS_INITIAL_STATE },
  updateEmployeeRole: { ...ACTIONS_INITIAL_STATE },
  importEmployee: { ...ACTIONS_INITIAL_STATE },
  checkEmployeeAccount: { ...ACTIONS_INITIAL_STATE },
  fetchCountries: { ...ACTIONS_INITIAL_STATE },
  updateAnnualLeave: { ...ACTIONS_INITIAL_STATE },
  importedEmployee: null,
};

const deleteEmployee = (data: Employee[] | undefined, id: number) =>
  data?.filter((item) => item.id !== id);

const addEmployee = (
  data: Employee[] | undefined,
  accountId: number,
  employee: AddEmployeeParams | ImportEmployeeParams,
) => {
  const newEmployee: Employee = {
    email: employee.email,
    first_name: employee.first_name,
    id: accountId,
    last_name: employee.last_name,
    phone: employee.phone_number,
    phone_number_country_isd: employee.phone_number_country_isd,
    date_created: (employee as AddEmployeeParams)?.password ? utils.date.now() : undefined,
    annual_leave_credit: employee.annual_leave_credit,
    role: {
      employee_code: employee.employee_code,
      currency_id: employee.currency_id,
      currency_symbol: employee.currency_symbol,
      hourly_wage: (employee.hourly_wage || 0).toString(),
      payment_type: Number(employee.payment_type),
      id: employee.role_id,
      name: employee.role_name,
    },
  };
  data?.unshift(newEmployee);
};

const updateEmployee = (
  data: Employee[] | undefined,
  accountId: number,
  newData: UpdateEmployeeParams,
) => {
  data?.forEach((item) => {
    if (item.id === accountId) {
      item.first_name = newData.first_name;
      item.last_name = newData.last_name;
      item.phone = newData.phone_number;
      item.phone_number_country_isd = newData.phone_number_country_isd;
      item.email = newData.email;
    }
  });
};

const updateEmployeeAnnualLeave = (
  data: Employee[] | undefined,
  newData: UpdateEmployeeAnnualLeaveParams,
) => {
  data?.forEach((item) => {
    if (item.id === newData.id) {
      item.annual_leave_credit = newData.annual_leave_credit;
    }
  });
};

const editRoleEmployee = (
  data: Employee[] | undefined,
  accountId: number,
  newData: UpdateEmployeeRoleParams,
) => {
  data?.forEach((item) => {
    if (item.id === accountId) {
      item.role.id = newData.role_id;
      item.role.currency_id = newData.currency_id;
      item.role.payment_type = newData.payment_type;
      item.role.hourly_wage = newData.hourly_wage;
      item.role.name = newData.role_name;
      item.role.employee_code = newData?.employee_code;
    }
  });
};

const EmployeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    fetchEmployeesStarted: (state) => {
      state.fetchEmployees = { ...initialState.fetchEmployees, isLoading: true };
    },
    fetchEmployeesError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchEmployees.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchEmployees.error = action.payload;
      } else {
        state.fetchEmployees.response = action.payload;
      }
    },
    fetchEmployeesSuccess: (state, action: PayloadAction<ResponseSuccess<Employee[]>>) => {
      state.fetchEmployees.isLoading = false;
      state.fetchEmployees.data = action.payload.result;
    },

    createEmployeeStarted: (state) => {
      state.createEmployee = { ...initialState.createEmployee, isLoading: true };
    },
    createEmployeeError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.createEmployee.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.createEmployee.error = action.payload;
      } else {
        state.createEmployee.response = action.payload;
      }
    },
    createEmployeeSuccess: (
      state,
      action: PayloadAction<{
        empData: AddEmployeeParams;
        response: ResponseSuccess<AddEmployeesResponseDataResult>;
      }>,
    ) => {
      state.createEmployee.isLoading = false;
      state.createEmployee.response = action.payload.response;
      addEmployee(
        state.fetchEmployees.data,
        action.payload.response.result.account_id,
        action.payload.empData,
      );
    },

    updateEmployeeStarted: (state, action: PayloadAction<UpdateEmployeeParams>) => {
      state.updateEmployee = {
        ...initialState.updateEmployee,
        isLoading: true,
        data: action.payload,
      };
    },
    updateEmployeeError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateEmployee.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateEmployee.error = action.payload;
      } else {
        state.updateEmployee.response = action.payload;
      }
    },
    updateEmployeeSuccess: (
      state,
      action: PayloadAction<{
        account_id: number;
        empData: UpdateEmployeeParams;
        response: ResponseSuccess<boolean>;
      }>,
    ) => {
      state.updateEmployee.isLoading = false;
      state.updateEmployee.response = action.payload.response;
      updateEmployee(state.fetchEmployees.data, action.payload.account_id, action.payload.empData);
    },

    updateAnnualLeaveStarted: (state, action: PayloadAction<UpdateEmployeeAnnualLeaveParams>) => {
      state.updateAnnualLeave = {
        ...initialState.updateAnnualLeave,
        isLoading: true,
        data: action.payload,
      };
    },
    updateAnnualLeaveError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateAnnualLeave.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateAnnualLeave.error = action.payload;
      } else {
        state.updateAnnualLeave.response = action.payload;
      }
    },
    updateAnnualLeaveSuccess: (
      state,
      action: PayloadAction<{
        data: UpdateEmployeeAnnualLeaveParams;
        response: ResponseSuccess<boolean>;
      }>,
    ) => {
      state.updateAnnualLeave.isLoading = false;
      state.updateAnnualLeave.response = action.payload.response;
      updateEmployeeAnnualLeave(state.fetchEmployees.data, action.payload.data);
    },

    deleteEmployeeStarted: (state, action: PayloadAction<{ account_id: number }>) => {
      state.deleteEmployee = {
        ...initialState.deleteEmployee,
        isLoading: true,
        data: action.payload,
      };
    },
    deleteEmployeeError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.deleteEmployee.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deleteEmployee.error = action.payload;
      } else {
        state.deleteEmployee.response = action.payload;
      }
    },
    deleteEmployeeSuccess: (
      state,
      action: PayloadAction<{ account_id: number; response: ResponseSuccess<boolean> }>,
    ) => {
      state.deleteEmployee.isLoading = false;
      state.deleteEmployee.response = action.payload.response;
      state.fetchEmployees.data = deleteEmployee(
        state.fetchEmployees.data,
        action.payload.account_id,
      );
    },
    updateEmployeeRoleStarted: (state, action: PayloadAction<UpdateEmployeeRoleParams>) => {
      state.updateEmployeeRole = {
        ...initialState.updateEmployeeRole,
        isLoading: true,
        data: action.payload,
      };
    },
    updateEmployeeRoleError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateEmployeeRole.isLoading = false;
      state.fetchEmployees.data = state.fetchEmployees.data?.slice();
      if (utils.general.isRequestError(action.payload)) {
        state.updateEmployeeRole.error = action.payload;
      } else {
        state.updateEmployeeRole.response = action.payload;
      }
    },
    updateEmployeeRoleSuccess: (
      state,
      action: PayloadAction<{
        account_id: number;
        empData: UpdateEmployeeRoleParams;
        response: ResponseSuccess<boolean>;
      }>,
    ) => {
      state.updateEmployeeRole.isLoading = false;
      state.updateEmployeeRole.response = action.payload.response;
      editRoleEmployee(
        state.fetchEmployees.data,
        action.payload.account_id,
        action.payload.empData,
      );
    },
    importEmployeeStarted: (state) => {
      state.importEmployee = { ...initialState.importEmployee, isLoading: true };
    },
    importEmployeeError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.importEmployee.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.importEmployee.error = action.payload;
      } else {
        state.importEmployee.response = action.payload;
      }
    },
    importEmployeeSuccess: (
      state,
      action: PayloadAction<{
        account_id: number;
        empData: ImportEmployeeParams;
        response: ResponseSuccess<boolean>;
      }>,
    ) => {
      state.importEmployee.isLoading = false;
      state.importEmployee.response = action.payload.response;
      addEmployee(state.fetchEmployees.data, action.payload.account_id, action.payload.empData);
    },
    checkEmployeeAccountStarted: (state, action: PayloadAction<{ email: string }>) => {
      state.checkEmployeeAccount = {
        ...initialState.checkEmployeeAccount,
        isLoading: true,
        data: action.payload,
      };
      state.importedEmployee = null;
    },
    checkEmployeeAccountError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.checkEmployeeAccount.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.checkEmployeeAccount.error = action.payload;
      } else {
        state.checkEmployeeAccount.response = action.payload;
      }
    },
    checkEmployeeAccountSuccess: (
      state,
      action: PayloadAction<{
        email: string;
        response: ResponseSuccess<CheckAccountResponseDataResult>;
      }>,
    ) => {
      state.checkEmployeeAccount.isLoading = false;
      state.checkEmployeeAccount.response = action.payload.response;
      if (action.payload.response.result && !action.payload.response.result.roles?.length) {
        state.importedEmployee = action.payload.response.result;
      } else {
        state.importedEmployee = null;
      }
    },
    fetchCountriesStarted: (state) => {
      state.fetchCountries = { ...initialState.fetchCountries, isLoading: true };
    },
    fetchCountriesError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchCountries.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchCountries.error = action.payload;
      } else {
        state.fetchCountries.response = action.payload;
      }
    },
    fetchCountriesSuccess: (state, action: PayloadAction<ResponseSuccess<Country[]>>) => {
      state.fetchCountries.isLoading = false;
      state.fetchCountries.data = action.payload.result;
    },

    resetEmployeePasswordStarted: (state, action: PayloadAction<ResetEmployeePasswordParams>) => {
      state.resetEmployeePassword = {
        ...initialState.resetEmployeePassword,
        isLoading: true,
        data: action.payload,
      };
    },
    resetEmployeePasswordError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.resetEmployeePassword.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.resetEmployeePassword.error = action.payload;
      } else {
        state.resetEmployeePassword.response = action.payload;
      }
    },
    resetEmployeePasswordSuccess: (state, action: PayloadAction<ResponseSuccess<boolean>>) => {
      state.resetEmployeePassword.isLoading = false;
      state.resetEmployeePassword.response = action.payload;
    },

    resetCheckedEmployeeAccount: (state) => {
      state.checkEmployeeAccount = { ...initialState.checkEmployeeAccount };
      state.importedEmployee = null;
    },
    resetRemoveEmployeeSlice: (state) => {
      state.deleteEmployee = { ...initialState.deleteEmployee };
    },
    resetUpdateEmployeeSlice: (state) => {
      state.updateEmployee = { ...initialState.updateEmployee };
      state.updateEmployeeRole = { ...initialState.updateEmployeeRole };
      state.updateAnnualLeave = { ...initialState.updateAnnualLeave };
    },
    resetAddEmployeeSlice: (state) => {
      state.createEmployee = { ...initialState.createEmployee };
      state.importEmployee = { ...initialState.importEmployee };
    },
    resetImportedEmployeeSlice: (state) => {
      state.importedEmployee = null;
    },
    resetImportEmployeeSlice: (state) => {
      state.importEmployee = { ...initialState.importEmployee };
    },
    clearResetEmployeePasswordSlice: (state) => {
      state.resetEmployeePassword = { ...initialState.resetEmployeePassword };
    },

    clearSlices: (state) => {
      state.fetchEmployees = { ...initialState.fetchEmployees };
      state.createEmployee = { ...initialState.createEmployee };
      state.updateEmployee = { ...initialState.updateEmployee };
      state.resetEmployeePassword = { ...initialState.resetEmployeePassword };
      state.deleteEmployee = { ...initialState.deleteEmployee };
      state.updateEmployeeRole = { ...initialState.updateEmployeeRole };
      state.importEmployee = { ...initialState.importEmployee };
      state.checkEmployeeAccount = { ...initialState.checkEmployeeAccount };
      state.fetchCountries = { ...initialState.fetchCountries };
      state.updateAnnualLeave = { ...initialState.updateAnnualLeave };
      state.importedEmployee = null;
    },
  },
});

export default EmployeesSlice.reducer;

export const employeeActions = EmployeesSlice.actions;

export const selectAddEmployeeState = (state: State<InitialState>) =>
  state.employeesSlice.createEmployee;

export const selectUpdateEmployeeState = (state: State<InitialState>) =>
  state.employeesSlice.updateEmployee;

export const selectUpdateEmployeeAnnualLeave = (state: State<InitialState>) =>
  state.employeesSlice.updateAnnualLeave;

export const selectUpdateEmployeeRoleState = (state: State<InitialState>) =>
  state.employeesSlice.updateEmployeeRole;

export const selectDeleteEmployeeState = (state: State<InitialState>) =>
  state.employeesSlice.deleteEmployee;

export const selectFetchEmployeesState = (state: State<InitialState>) =>
  state.employeesSlice?.fetchEmployees;

export const selectImportEmployeeState = (state: State<InitialState>) =>
  state.employeesSlice.importEmployee;

export const selectImportedEmployeeState = (state: State<InitialState>) =>
  state.employeesSlice.importedEmployee;

export const selectFetchCountriesState = (state: State<InitialState>) =>
  state.employeesSlice.fetchCountries;

export const selectCheckEmployeeAccountState = (state: State<InitialState>) =>
  state.employeesSlice.checkEmployeeAccount;

export const selectResetEmployeePasswordState = (state: State<InitialState>) =>
  state.employeesSlice.resetEmployeePassword;

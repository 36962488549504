import shift from './shift';
import payments from './payments';
import date from './date';
import general from './general';
import input from './input';
import position from './position';
import yupValidations from './yupValidations';
import weeklySchedule from './weeklySchedule';
import openShift from './openShifts';
import sales from './sales';

export default {
  shift,
  payments,
  date,
  general,
  input,
  position,
  yupValidations,
  weeklySchedule,
  openShift,
  sales,
};

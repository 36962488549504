/* eslint-disable no-param-reassign */
import _orderBy from 'lodash/orderBy';
import { AttendanceReportResponse, SummaryReportResponse } from '../../../api/reporting';

export const sortDataByRoles = (data: AttendanceReportResponse) => {
  const employeeList = _orderBy(data.employees, [
    (emp) => {
      let empRoleIndex: number = 0;
      Object.values(emp.dates).forEach((branches, dateIndex) => {
        Object.values(branches ?? {}).forEach((branch, branchIndex) => {
          const keys = Object.values(branch?.positions ?? {}).map((elem) => Number(elem?.index));
          const minRoleIndex = Math.min(...keys);
          if (dateIndex === 0 && branchIndex === 0) empRoleIndex = minRoleIndex;
          empRoleIndex = minRoleIndex < empRoleIndex ? minRoleIndex : empRoleIndex;
        });
      });
      return empRoleIndex;
    },
    (emp) => emp.name.toLowerCase(),
  ]);
  data.employees = employeeList;
  return data;
};

export const sortSummaryReport = (data: SummaryReportResponse) => {
  const employees = _orderBy(data.employees, [
    (emp) => {
      let empRoleIndex: number = 0;
      Object.keys(emp.summary).forEach((branchId, branchIndex) => {
        const branch = emp.summary[branchId];
        const keys = Object.values(branch?.positions || {}).map(({ index }) => +index);
        const minRoleIndex = Math.min(...keys);
        if (branchIndex === 0) empRoleIndex = minRoleIndex;
        empRoleIndex = minRoleIndex < empRoleIndex ? minRoleIndex : empRoleIndex;
      });
      return empRoleIndex;
    },
    (emp) => emp.name.toLowerCase(),
  ]);

  return {
    ...data,
    employees,
  };
};

export default {
  sortDataByRoles,
  sortSummaryReport,
};

import { createTheme } from '@mui/material';
import RedColor from '@mui/material/colors/red';

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontSize: 8,
  },
  palette: {
    primary: {
      main: '#1DD0BE',
    },
    text: {
      primary: '#2B3238',
    },
    secondary: {
      main: '#757575',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        fullWidth: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: { fontSize: '.9em', lineHeight: '1.2em' },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '.9em',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        style: {
          margin: '0',
          position: 'absolute',
          bottom: '-20px',
          left: '2px',
          color: RedColor[600],
        },
      },
    },
  },
});

export default theme;

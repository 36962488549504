import { PaymentType } from '../enums';
import { Response } from '../types/Response';
import api from './axios';

export type AccountAttendanceReportRequest = {
  branch_ids: number[];
  end_date: string;
  start_date: string;
  account_id: number;
};

export type PunchType = 'IN' | 'OUT';

export interface PunchData {
  id: number;
  account_id: number;
  branch_id: number;
  manager_id: number | null;
  manager_name: string | null;
  date: string | null;
  location?: string;
  note: string | null;
  manager_note: null | string;
  is_valid: 1 | 0;
  distance: number;
  is_original: boolean;
  geofence_id: number;
  geofence_name: string;
}

export interface PunchIn extends Omit<PunchData, 'date'> {
  date: string;
}

export type PunchOut = PunchData | null;

export type Punch = {
  in: PunchIn;
  out: PunchOut;
};

export type AttendanceReportRequest = {
  start_date: string;
  end_date: string;
  account_id?: number;
  branch_ids: number[];
};

export type AttendanceReportShiftError = {
  id: number;
  name: string;
  type_id: number;
  is_resolved: 0 | 1;
  is_approved: 0 | 1;
};

export type AttendanceReportShift = {
  id: number;
  name: string;
  start_time: string | '-';
  end_time: string | '-';
  is_close: 0 | 1 | '-';
  break_time: number | null;
  date: string;
  note: string | null;
  overlap: number;
  shift_errors: AttendanceReportShiftError[];
  custom_payment?: {
    payment_type_id: PaymentType;
    wage: string;
  };
};

export type Position = {
  index: string;
  payment_type: PaymentType;
  currency_id: number;
  currency_symbol: string;
  employee_code: string | null;
  wage: string;
  wage_factors: string[];
  variance: number;
  total: number;
  total_regular: number;
  total_overtime: number;
  total_holiday: number;
  total_punch: number;
  total_punch_regular: number;
  total_punch_overtime: number;
  total_punch_holiday: number;
  total_allowance: number;
  total_allowance_regular: number;
  total_allowance_overtime: number;
  total_allowance_holiday: number;
  total_allowance_punch: number;
  total_allowance_punch_regular: number;
  total_allowance_punch_overtime: number;
  total_allowance_punch_holiday: number;
  shifts: AttendanceReportShift[];
  punches: Punch[];
};

export type SummaryPosition = {
  variance: number;
  total: number;
  total_regular: number;
  total_overtime: number;
  total_holiday: number;
  total_punch: number;
  total_punch_regular: number;
  total_punch_overtime: number;
  total_punch_holiday: number;
  total_allowance: number;
  total_allowance_regular: number;
  total_allowance_overtime: number;
  total_allowance_holiday: number;
  total_allowance_punch: number;
  total_allowance_punch_regular: number;
  total_allowance_punch_overtime: number;
  total_allowance_punch_holiday: number;
};

export type Role = {
  id: number;
  name: string;
  index: string;
  payment_type: PaymentType;
  currency_id: number;
  currency_symbol: string;
  wage: string;
  employee_code: string | null;
};

export type Branch = {
  name: string;
  id: number;
};

export type Summary = {
  sum: { [branchId: string]: number };
  sum_punch: { [branchId: string]: number };
};

export type EmployeeSummary = {
  [branchId: string]:
    | undefined
    | {
        positions:
          | undefined
          | {
              [Position_id: string]: SummaryPosition | undefined;
            };
      };
};

export type EmployeeDates = {
  [date: string]:
    | undefined
    | {
        [branchId: string]:
          | undefined
          | {
              positions:
                | undefined
                | {
                    [Position_id: string]: Position | undefined;
                  };
            };
      };
};

export type Employee = {
  account_id: number;
  name: string;
  dates: EmployeeDates;
  summary: EmployeeSummary;
};

export type AttendanceReportResponse = {
  employees: Employee[];
  branches: Branch[];
  roles: Role[];
  summary: Summary;
};

export type SummaryReportResponse = {
  branches: { id: number; name: string; company_id: number }[];
  currencies: { id: number; symbol: string }[];
  roles: {
    id: number;
    name: string;
    index: string;
    payment_type: PaymentType;
    currency_id: number;
    currency_symbol: string;
    wage: string;
    employee_code: string | null;
  }[];
  employees: {
    account_id: number;
    name: string;
    summary: {
      [branchId: string]: {
        positions: {
          [positionId: string]: {
            index: string;
            employee_code: string | null;
            variance: number;
            total: {
              [paymentId: string]: number;
            };
            total_regular: {
              [paymentId: string]: number;
            };
            total_overtime: {
              [paymentId: string]: number;
            };
            total_holiday: {
              [paymentId: string]: number;
            };
            total_punch: number;
            total_punch_regular: number;
            total_punch_overtime: number;
            total_punch_holiday: number;
            total_allowance: {
              [currentyId: string]: number;
            };
            total_allowance_punch: number;
            shifts: {
              [shiftName: string]: {
                count: number;
              };
            };
            total_scheduled: {
              [paymentId: string]: number;
            };
            total_scheduled_regular: {
              [paymentId: string]: number;
            };
            total_scheduled_overtime: {
              [paymentId: string]: number;
            };
            total_scheduled_holiday: {
              [paymentId: string]: number;
            };
            shift_issues: {
              [issueName: string]: {
                count: number;
              };
            };
          };
        };
      };
    };
    annual_leaves:
      | {
          [companyId: string]: {
            annual_leave_used: number;
            annual_leave_remaining: number;
          };
        }
      | undefined;
  }[];
};

const getAttendanceReport = (managerId: number, data: AttendanceReportRequest) =>
  api.post(`reporting/v1/${managerId}`, data) as Promise<Response<AttendanceReportResponse>>;

const getSummaryReport = (managerId: number, data: AttendanceReportRequest) =>
  api.post(`reporting/summary/${managerId}`, data) as Promise<Response<SummaryReportResponse>>;

export default {
  getAttendanceReport,
  getSummaryReport,
};

import axios, { AxiosError } from 'axios';
import { ResponseStatus } from '../enums';

const getErrorMessage = (error: AxiosError) =>
  error?.request?.status === 0 ||
  (error?.response && Math.floor(error.response.status / 100) === 4) ||
  (error?.request && Math.floor(error.request.status / 100) === 4) ||
  Math.floor((error?.request?.status || 0) / 100) === 5
    ? "Couldn't reach server"
    : null;

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

api.interceptors.response.use(
  (response) => {
    if (window.location.pathname === '/') {
      return response;
    }
    if (response.status === 200 && response.data.status === ResponseStatus.Fail) {
      // setErrorDialogVisibility(true, [message], 'offline')(store.dispatch);
    }
    return response;
  },
  (error) => {
    const message = getErrorMessage(error);
    if (message) {
      // setErrorDialogVisibility(true, [message], 'offline')(store.dispatch);
    }
    return Promise.reject(error);
  },
);

export default api;

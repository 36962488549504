const GET_DAY_REQUESTS = 'requests/day/get';
const PUT_OFF_REQUEST = 'requests/day/off_request/put';
const PUT_EXCHANGE_REQUEST = 'requests/day/exchange_request/put';
const GET_EXCHANGE_EMPLOYEE = 'requests/day/exchange_employee/get';
const PUT_EXCHANGE_EMPLOYEE = 'requests/day/exchange_employee/put';

export default {
  GET_DAY_REQUESTS,
  GET_EXCHANGE_EMPLOYEE,
  PUT_EXCHANGE_REQUEST,
  PUT_EXCHANGE_EMPLOYEE,
  PUT_OFF_REQUEST,
};

import { Suspense, lazy } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import Layout from '../Layout';
import PrivateRoute from './PrivateRoute';
import AppLoader from '../../components/AppLoader/AppLoader';
import routes from './routes';

/* @vite-ignore */
const Login = lazy(() => import('../../modules/Login/Login'));

function AppRoutes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            {routes.map(({ path, requiredFeatures, Element, id }) => (
              <Route key={id} element={<PrivateRoute requiredFeatures={requiredFeatures} />}>
                <Route
                  path={path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Element />
                    </Suspense>
                  }
                />
              </Route>
            ))}
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<AppLoader />}>
              <Login />
            </Suspense>
          }
        />
      </>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default AppRoutes;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import utils from '../../shared/utils';
import { AddPunchData, EditPunchData } from '../../types/Punch';
import { AddPunchResponse, PunchHistory } from '../../api/punch';
import { State } from '../../types/Store';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data?: Req;
}

export type InitialState = {
  fetchPunchHistory: ActionType<PunchHistory, ResponseSuccess<PunchHistory>>;
  updatePunch: ActionType<EditPunchData, ResponseSuccess<number | boolean>>;
  addPunch: ActionType<AddPunchData, ResponseSuccess<AddPunchResponse>>;
  addedMissingPunchCount: number;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
};

const initialState: InitialState = {
  updatePunch: { ...ACTIONS_INITIAL_STATE },
  addPunch: { ...ACTIONS_INITIAL_STATE },
  fetchPunchHistory: { ...ACTIONS_INITIAL_STATE, data: undefined },
  addedMissingPunchCount: 0,
};

const PunchSlice = createSlice({
  name: 'punch',
  initialState,
  reducers: {
    fetchPunchHistoryStarted: (state) => {
      state.fetchPunchHistory = {
        ...initialState.fetchPunchHistory,
        isLoading: true,
      };
    },
    fetchPunchHistoryError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchPunchHistory.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchPunchHistory.error = action.payload;
      } else {
        state.fetchPunchHistory.response = action.payload;
      }
      state.fetchPunchHistory.data = [];
    },
    fetchPunchHistorySuccess: (state, action: PayloadAction<ResponseSuccess<PunchHistory>>) => {
      state.fetchPunchHistory.isLoading = false;
      state.fetchPunchHistory.response = action.payload;
      state.fetchPunchHistory.data = action.payload.result;
    },

    updatePunchStarted: (state) => {
      state.updatePunch = { ...initialState.updatePunch, isLoading: true };
    },
    updatePunchError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updatePunch.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updatePunch.error = action.payload;
      } else {
        state.updatePunch.response = action.payload;
      }
    },
    updatePunchSuccess: (
      state,
      action: PayloadAction<{ response: ResponseSuccess<number | boolean>; req: EditPunchData }>,
    ) => {
      state.updatePunch.isLoading = false;
      state.updatePunch.response = action.payload.response;
      state.updatePunch.data = action.payload.req;
    },
    addPunchStarted: (state) => {
      state.addPunch = { ...initialState.addPunch, isLoading: true };
    },
    addPunchError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.addPunch.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addPunch.error = action.payload;
      } else {
        state.addPunch.response = action.payload;
      }
    },
    addPunchSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<AddPunchResponse>;
        addedMissingPunches: boolean;
        req: AddPunchData;
      }>,
    ) => {
      state.addPunch.isLoading = false;
      state.addPunch.response = action.payload.response;
      state.addPunch.data = action.payload.req;

      state.addedMissingPunchCount += +action.payload.addedMissingPunches;
    },
    resetSlices: (state) => {
      state.addPunch = { ...ACTIONS_INITIAL_STATE };
      state.updatePunch = { ...ACTIONS_INITIAL_STATE };
      state.fetchPunchHistory = { ...ACTIONS_INITIAL_STATE, data: state.fetchPunchHistory.data };
    },
  },
});

export default PunchSlice.reducer;

export const sliceActions = PunchSlice.actions;

export const selectUpdatePunchState = (state: State<InitialState>) => state.punchSlice.updatePunch;

export const selectAddPunchState = (state: State<InitialState>) => state.punchSlice.addPunch;

export const selectPunchHistoryState = (state: State<InitialState>) =>
  state.punchSlice.fetchPunchHistory;

export const selectAddedMissingPunchCount = (state: State<InitialState>) =>
  state.punchSlice.addedMissingPunchCount;

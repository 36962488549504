import { call, takeLatest, put, all } from 'redux-saga/effects';
import _chunk from 'lodash/chunk';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import actionTypes from '../actionTypes/requests';
import { requestsActions } from '../slices/requestsSlice';
import { Response } from '../../types/Response';
import { MonthlyRequestsData, RequestsCount, MonthlyRequestsResponse } from '../../types/Requests';
import utils from '../../shared/utils';
import { Weekday } from '../../enums';

function* getMonthlyRequests({
  payload,
}: PayloadAction<{
  branchId: number;
  date?: string;
  step?: number;
  firstWeekday: Weekday;
}>) {
  try {
    const monthData = utils.date.getMonthDays({
      firstWeekday: payload.firstWeekday,
      startDate: payload.date,
      step: payload.step,
    });
    yield put(
      requestsActions.fetchMonthlyRequestsStarted({
        date: monthData.startDate,
      }),
    );
    const res: Response<MonthlyRequestsResponse> = yield call(() =>
      api.requests.getRequestsMonth(payload.branchId, monthData.startDate),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      const result: MonthlyRequestsData = _chunk(
        utils.general.mergeArraysByProperty(monthData.monthDays, res.data.result, 'date'),
        7,
      );
      yield put(
        requestsActions.fetchMonthlyRequestsSuccess({
          response: res.data,
          data: result,
        }),
      );
    } else {
      yield put(requestsActions.fetchMonthlyRequestsError(res.data));
    }
  } catch (error) {
    yield put(requestsActions.fetchMonthlyRequestsError(utils.general.convertAxiosError(error)));
  }
}

function* getRequestsCount({ payload }: PayloadAction<{ branchId: number }>) {
  try {
    yield put(requestsActions.fetchRequestsCountStarted());
    const res: Response<RequestsCount> = yield call(() =>
      api.requests.getRequestsCount(payload.branchId),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        requestsActions.fetchRequestsCountSuccess({
          response: res.data,
        }),
      );
    } else {
      yield put(requestsActions.fetchRequestsCountError(res.data));
    }
  } catch (error) {
    yield put(requestsActions.fetchRequestsCountError(utils.general.convertAxiosError(error)));
  }
}

function* watchManageRequests() {
  yield all([
    takeLatest(actionTypes.GET_REQUESTS_COUNT, getRequestsCount),
    takeLatest(actionTypes.GET_MONTHLY_REQUESTS, getMonthlyRequests),
  ]);
}

export default watchManageRequests;

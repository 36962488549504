import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_MULTI,
} from '../actionTypes/notifications';

export const fetchNotifications = (
  accountId: string,
  read: null | unknown,
  index: number,
  limit: number,
) => ({
  type: GET_NOTIFICATIONS,
  payload: {
    accountId,
    read,
    index,
    limit,
  },
});

export const updateNotification = (readStatus: number, id: number) => ({
  type: UPDATE_NOTIFICATION,
  payload: {
    readStatus,
    id,
  },
});

export const updateNotificationMulti = (readStatus: number[], ids: number[]) => ({
  type: UPDATE_NOTIFICATION_MULTI,
  payload: {
    readStatus,
    ids,
  },
});

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PunchHistory } from '../../types/Reporting';
import {
  ResponseError,
  RequestError,
  ResponseSuccess,
  GenericResponseError,
} from '../../types/Response';
import utils from '../../shared/utils';
import { WeekShifts } from '../../types/Shift';
import roleUtils from '../../shared/utils/attendanceReport/roles';
import punchUtils, { MissingPunches } from '../../shared/utils/attendanceReport/punches';
import accountUtils from '../../shared/utils/attendanceReport/account';
import { State } from '../../types/Store';
import { sortShifts } from '../../shared/utils/shift';
import tentativePunchesUtils from '../../shared/utils/attendanceReport/tentativePunches';
import {
  AttendanceReportResponse,
  AttendanceReportRequest,
  AccountAttendanceReportRequest,
  SummaryReportResponse,
} from '../../api/reporting';
import { AddMultiPunchRequest, AddMultiPunchResponseErrorData } from '../../api/punch';
import attendanceReport from '../../shared/utils/attendanceReport';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: Req;
}

export type InitialState = {
  fetchAttendanceReport: ActionType<
    AttendanceReportResponse,
    ResponseSuccess<AttendanceReportResponse>
  >;
  fetchSummaryReport: ActionType<
    SummaryReportResponse | undefined,
    ResponseSuccess<undefined> | undefined
  >;
  fetchAttendanceReportParams: AttendanceReportRequest;
  fetchAccountAttendanceReport: ActionType<
    { args: AccountAttendanceReportRequest; account_report: AttendanceReportResponse },
    ResponseSuccess<AttendanceReportResponse>
  >;
  fetchAccountAttendanceReportParams: AttendanceReportRequest;
  fetchShifts: ActionType<
    { shifts: WeekShifts[]; args: { branch_id: number; date: string } },
    ResponseSuccess<WeekShifts[]>
  >;
  submitTentativePunches: ActionType<
    AddMultiPunchRequest | undefined,
    ResponseSuccess<true> | GenericResponseError<AddMultiPunchResponseErrorData>
  >;
  missingPunches: MissingPunches;
  selectedPunches: MissingPunches;
  tentativePunches: MissingPunches;
  ignoredTentativePunches: MissingPunches;
  selectedPunchesHistory: PunchHistory;
  selectedEmployees: string[];
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
  data: undefined,
};

const initialState: InitialState = {
  fetchAttendanceReport: {
    ...ACTIONS_INITIAL_STATE,
    data: {
      branches: [],
      roles: [],
      summary: {
        sum: {},
        sum_punch: {},
      },
      employees: [],
    },
  },
  fetchSummaryReport: {
    ...ACTIONS_INITIAL_STATE,
  },
  fetchAttendanceReportParams: { branch_ids: [], end_date: '', start_date: '' },
  fetchAccountAttendanceReport: {
    ...ACTIONS_INITIAL_STATE,
    data: {
      args: { account_id: 0, branch_ids: [], end_date: '', start_date: '' },
      account_report: {
        branches: [],
        roles: [],
        summary: {
          sum: {},
          sum_punch: {},
        },
        employees: [],
      },
    },
  },
  fetchAccountAttendanceReportParams: {
    branch_ids: [],
    end_date: '',
    start_date: '',
    account_id: 0,
  },
  fetchShifts: { ...ACTIONS_INITIAL_STATE, data: { args: { branch_id: 0, date: '' }, shifts: [] } },
  submitTentativePunches: { ...ACTIONS_INITIAL_STATE },
  missingPunches: {},
  selectedPunches: {},
  tentativePunches: {},
  selectedPunchesHistory: [],
  selectedEmployees: [],
  ignoredTentativePunches: {},
};

const Reporting = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    fetchAttendanceReportStarted: (state) => {
      state.fetchAttendanceReport = {
        ...initialState.fetchAttendanceReport,
        isLoading: true,
      };
      state.selectedPunches = { ...initialState.selectedPunches };
      state.selectedPunchesHistory = [...initialState.selectedPunchesHistory];
      state.ignoredTentativePunches = { ...initialState.ignoredTentativePunches };
      state.missingPunches = { ...initialState.missingPunches };
      state.submitTentativePunches = { ...initialState.submitTentativePunches };
      state.tentativePunches = { ...initialState.tentativePunches };
      state.selectedEmployees = [...initialState.selectedEmployees];
    },
    fetchAttendanceReportError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchAttendanceReport.isLoading = false;
      state.fetchAttendanceReport.data = {
        branches: [],
        roles: [],
        summary: {
          sum: {},
          sum_punch: {},
        },
        employees: [],
      };
      if (utils.general.isRequestError(action.payload)) {
        state.fetchAttendanceReport.error = action.payload;
      } else {
        state.fetchAttendanceReport.response = action.payload;
      }
    },
    fetchAttendanceReportSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<AttendanceReportResponse>;
        branch_ids: number[];
        end_date: string;
        start_date: string;
      }>,
    ) => {
      state.fetchAttendanceReport.isLoading = false;
      state.fetchAttendanceReport.data = roleUtils.sortDataByRoles(action.payload.response.result);
      state.missingPunches = punchUtils.getMissingPunches(state.fetchAttendanceReport.data);
      state.fetchAttendanceReport.response = action.payload.response;
    },

    setFetchAttendanceReportParams: (state, action: PayloadAction<AttendanceReportRequest>) => {
      state.fetchAttendanceReportParams = {
        ...initialState.fetchAttendanceReportParams,
        ...action.payload,
      };
    },

    fetchAccountAttendanceReportStarted: (
      state,
      action: PayloadAction<AccountAttendanceReportRequest>,
    ) => {
      state.fetchAccountAttendanceReport = {
        ...initialState.fetchAccountAttendanceReport,
        isLoading: true,
        data: { ...initialState.fetchAccountAttendanceReport.data, args: action.payload },
      };
    },
    fetchAccountAttendanceReportError: (
      state,
      action: PayloadAction<RequestError | ResponseError>,
    ) => {
      state.fetchAccountAttendanceReport.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchAccountAttendanceReport.error = action.payload;
      } else {
        state.fetchAccountAttendanceReport.response = action.payload;
      }
    },
    fetchAccountAttendanceReportSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<AttendanceReportResponse>;
        data: AccountAttendanceReportRequest;
      }>,
    ) => {
      state.fetchAccountAttendanceReport.isLoading = false;
      accountUtils.updateAccountData(
        state.fetchAttendanceReport.data,
        state.missingPunches,
        action.payload,
      );
    },
    fetchShiftsStarted: (
      state,
      action: PayloadAction<{
        branchId: number;
        date: string;
      }>,
    ) => {
      state.fetchShifts = {
        ...initialState.fetchShifts,
        isLoading: true,
        data: {
          shifts: [],
          args: { branch_id: action.payload.branchId, date: action.payload.date },
        },
      };
    },
    fetchShiftsError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchShifts.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchShifts.error = action.payload;
      } else {
        state.fetchShifts.response = action.payload;
      }
    },
    fetchShiftsSuccess: (
      state,
      action: PayloadAction<{
        branchId: number;
        date: string;
        response: ResponseSuccess<WeekShifts[]>;
      }>,
    ) => {
      state.fetchShifts = {
        ...state.fetchShifts,
        isLoading: false,
        data: {
          ...state.fetchShifts.data,
          shifts: sortShifts(action.payload.response.result),
          args: { branch_id: action.payload.branchId, date: action.payload.date },
        },
      };
    },
    submitTentativePunchesStarted: (state, action: PayloadAction<AddMultiPunchRequest>) => {
      state.submitTentativePunches = {
        ...initialState.submitTentativePunches,
        isLoading: true,
        data: action.payload,
      };
    },
    submitTentativePunchesError: (
      state,
      action: PayloadAction<RequestError | GenericResponseError<AddMultiPunchResponseErrorData>>,
    ) => {
      state.submitTentativePunches.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.submitTentativePunches.error = action.payload;
      } else {
        state.submitTentativePunches.response = action.payload;
        tentativePunchesUtils.getFailedTentativePunches(
          state.tentativePunches,
          action.payload.result,
        );
      }
    },
    submitTentativePunchesSuccess: (state, action: PayloadAction<ResponseSuccess<true>>) => {
      state.submitTentativePunches.isLoading = false;
      state.submitTentativePunches.response = action.payload;
      state.selectedEmployees = [];
    },
    clearSelectedPunches: (state) => {
      state.selectedPunches = {};
      state.selectedPunchesHistory = [];
    },
    selectPunch: (
      state,
      action: PayloadAction<{
        accountId: number;
        branchId: number;
        date: string;
        positionId: number;
      }>,
    ) => {
      punchUtils.selectPunch(state.selectedPunches, state.missingPunches, action.payload);
      punchUtils.addSelectedPunchesHistory(state.selectedPunchesHistory, [action.payload], 'added');
    },
    unSelectPunch: (
      state,
      action: PayloadAction<{
        accountId: number;
        branchId: number;
        date: string;
        positionId: number;
      }>,
    ) => {
      punchUtils.uselectPunch(state.selectedPunches, action.payload);
      if (Object.keys(state.selectedPunches).length === 0) state.selectedPunchesHistory = [];
      else
        punchUtils.addSelectedPunchesHistory(
          state.selectedPunchesHistory,
          [action.payload],
          'removed',
        );
    },
    selectAllEmployeePunches: (
      state,
      action: PayloadAction<{
        accountId: number;
        checked: boolean;
      }>,
    ) => {
      if (action.payload.checked) {
        const result = punchUtils.selectEmployeePunches(
          state.selectedPunches,
          state.missingPunches,
          state.tentativePunches,
          action.payload.accountId,
        );
        const { addedPunchesHistory: addedPunches, selectedPunches } = result;
        state.selectedPunches = selectedPunches;
        if (addedPunches.length) {
          punchUtils.addSelectedPunchesHistory(state.selectedPunchesHistory, addedPunches, 'added');
        }
      } else {
        punchUtils.unSelectEmployeePunches(state.selectedPunches, action.payload.accountId);
        const removedPunches = punchUtils.convertSelectedPunchesToArray(
          state.selectedPunches,
          action.payload.accountId,
        );
        if (removedPunches.length)
          punchUtils.addSelectedPunchesHistory(
            state.selectedPunchesHistory,
            removedPunches,
            'removed',
          );
      }
    },
    selectAllMissingPunches: (state, action: PayloadAction<string[]>) => {
      const { selectedPunchesHistory } = state;
      const { selectedPunches, addedPunches } = punchUtils.selectAllMissingPunches(
        state.selectedPunches,
        state.missingPunches,
        state.tentativePunches,
        action.payload,
      );
      state.selectedPunches = selectedPunches;
      punchUtils.addSelectedPunchesHistory(selectedPunchesHistory, addedPunches, 'added');
    },
    addSelectedPunches: (state) => {
      const addedTentativePunches = tentativePunchesUtils.addTentativePunches(
        state.tentativePunches,
        state.selectedPunches,
      );

      state.selectedPunches = { ...initialState.selectedPunches };

      state.selectedPunchesHistory = [
        ...state.selectedPunchesHistory,
        { add_tentative: addedTentativePunches },
      ];
    },
    undoSelectedPunches: (state) => {
      const { missingPunches, selectedPunches, selectedPunchesHistory, tentativePunches } =
        punchUtils.undoSelectedPunches(
          state.selectedPunches,
          state.selectedPunchesHistory,
          state.missingPunches,
          state.tentativePunches,
        );
      state.selectedPunches = selectedPunches;
      state.tentativePunches = tentativePunches;
      state.missingPunches = missingPunches;
      state.selectedPunchesHistory = selectedPunchesHistory;
    },
    clearTentativePunches: (state) => {
      state.tentativePunches = {};
      state.ignoredTentativePunches = {};
      state.submitTentativePunches = { ...initialState.submitTentativePunches };
    },
    ignorePunches: (state, action: PayloadAction<boolean>) => {
      state.ignoredTentativePunches = action.payload
        ? tentativePunchesUtils.ignoreTentativePunchesErrors(state.tentativePunches)
        : tentativePunchesUtils.restoreIgnoredTentativePunchesErrors(
            state.tentativePunches,
            state.ignoredTentativePunches,
          );
    },
    setSelectedEmployees: (state, action: PayloadAction<string[]>) => {
      state.selectedEmployees = action.payload;
    },

    fetchSummaryReportStarted: (state) => {
      state.fetchSummaryReport = {
        ...initialState.fetchSummaryReport,
        isLoading: true,
      };
    },
    fetchSummaryReportError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchSummaryReport.isLoading = false;
      state.fetchSummaryReport.data = undefined;
      state.fetchSummaryReport.response = undefined;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchSummaryReport.error = action.payload;
      } else {
        state.fetchSummaryReport.response = action.payload;
      }
    },
    fetchSummaryReportSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<SummaryReportResponse>;
        branch_ids: number[];
        end_date: string;
        start_date: string;
      }>,
    ) => {
      state.fetchSummaryReport.isLoading = false;
      state.fetchSummaryReport.data = attendanceReport.role.sortSummaryReport(
        action.payload.response.result,
      );
      state.fetchSummaryReport.response = { ...action.payload.response, result: undefined };
    },

    clearSummaryReportData: (state) => {
      state.fetchSummaryReport = { ...initialState.fetchSummaryReport };
    },

    clearSlices: (state) => {
      state.fetchAttendanceReport = { ...initialState.fetchAttendanceReport };
      state.fetchSummaryReport = { ...initialState.fetchSummaryReport };
      state.fetchAttendanceReportParams = { ...initialState.fetchAttendanceReportParams };
      state.fetchAccountAttendanceReport = { ...initialState.fetchAccountAttendanceReport };
      state.fetchAccountAttendanceReportParams = {
        ...initialState.fetchAccountAttendanceReportParams,
      };
      state.fetchShifts = { ...initialState.fetchShifts };
      state.submitTentativePunches = { ...initialState.submitTentativePunches };
      state.missingPunches = { ...initialState.missingPunches };
      state.selectedPunches = { ...initialState.selectedPunches };
      state.tentativePunches = { ...initialState.tentativePunches };
      state.ignoredTentativePunches = { ...initialState.ignoredTentativePunches };
      state.selectedPunchesHistory = { ...initialState.selectedPunchesHistory };
      state.selectedEmployees = [];
    },
  },
});

export default Reporting.reducer;

export const reportSliceActions = Reporting.actions;

export const selectAttendanceReportParams = (state: State<InitialState>) =>
  state.reportingSlice.fetchAttendanceReportParams;

export const selectAccountAttendanceReportParams = (state: State<InitialState>) =>
  state.reportingSlice.fetchAccountAttendanceReportParams;

export const selectMissingPunchesState = (state: State<InitialState>) =>
  state.reportingSlice.missingPunches;

export const selectSelectedEmployeesState = (state: State<InitialState>) =>
  state.reportingSlice.selectedEmployees;

export const selectSelectedPunchesHistoryState = (state: State<InitialState>) =>
  state.reportingSlice.selectedPunchesHistory;

export const selectIgnoredTentativePunchesState = (state: State<InitialState>) =>
  state.reportingSlice.ignoredTentativePunches;

export const selectTentativePunchesState = (state: State<InitialState>) =>
  state.reportingSlice.tentativePunches;

export const selectSelectedPunchesState = (state: State<InitialState>) =>
  state.reportingSlice.selectedPunches;

export const selectFetchAttendanceReportState = (state: State<InitialState>) =>
  state.reportingSlice.fetchAttendanceReport;

export const selectFetchAccountAttendanceReportState = (state: State<InitialState>) =>
  state.reportingSlice.fetchAccountAttendanceReport;

export const selectSubmitTentativePunchesState = (state: State<InitialState>) =>
  state.reportingSlice.submitTentativePunches;

export const selectReportingFetchShiftsState = (state: State<InitialState>) =>
  state.reportingSlice.fetchShifts;

export const selectFetchSummaryReport = (state: State<InitialState>) =>
  state.reportingSlice.fetchSummaryReport;

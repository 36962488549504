/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import utils from '../../shared/utils';
import {
  AddOpenShiftData,
  AddOpenShiftResponse,
  NotifyOpenShiftEmployeeData,
  OpenShifts,
  DeleteOpenShiftData,
  UpdateOpenShiftData,
  BulkApproveOpenShiftEmployeesData,
  EmployeesUnAvailability,
} from '../../types/OpenShift';

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

interface ActionType<T, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: T;
}

export interface InitialState {
  open_shifts: OpenShifts[];
  addOpenShift: ActionType<AddOpenShiftData | undefined, ResponseSuccess<AddOpenShiftResponse>>;
  updateOpenShift: ActionType<UpdateOpenShiftData | undefined, ResponseSuccess<number>>;
  deleteOpenShift: ActionType<DeleteOpenShiftData | undefined, ResponseSuccess<number>>;
  notifyEmployees: ActionType<NotifyOpenShiftEmployeeData, ResponseSuccess<string[]>>;
  approveOpenShiftEmployees: ActionType<
    BulkApproveOpenShiftEmployeesData,
    ResponseSuccess<boolean>
  >;
  employeesUnAvailability: ActionType<
    EmployeesUnAvailability,
    ResponseSuccess<EmployeesUnAvailability>
  >;
}

const initialState: InitialState = {
  open_shifts: [],
  addOpenShift: {
    ...ACTIONS_INITIAL_STATE,
    data: undefined,
  },
  updateOpenShift: {
    ...ACTIONS_INITIAL_STATE,
    data: undefined,
  },
  deleteOpenShift: {
    ...ACTIONS_INITIAL_STATE,
    data: undefined,
  },
  notifyEmployees: {
    ...ACTIONS_INITIAL_STATE,
    data: { notify_rejected: 0, open_shift_request_id: 0 },
  },
  approveOpenShiftEmployees: {
    ...ACTIONS_INITIAL_STATE,
    data: { account_id: 0, approve: [], unapprove: [], open_shift_request_id: 0, date: '' },
  },
  employeesUnAvailability: {
    ...ACTIONS_INITIAL_STATE,
    data: [],
  },
};

const addOpenShift = (state: InitialState, data: AddOpenShiftData, openShiftId: number) => {
  state.open_shifts.forEach((day) => {
    if (day.date === data.date) {
      day.open_shift_requests.push({
        accepted_employees: [],
        approved_employees: [],
        date: data.date,
        start_time: data.start_time,
        end_time: data.end_time,
        break_time: data.break_time,
        is_close: data.is_close,
        number_of_employees: data.number_of_employees,
        open_shift_request_id: openShiftId,
        rejected_employees: [],
        requested_employees:
          data.employees.length === data.role.employees.length ? [] : data.employees,
        role: {
          id: data.role.id,
          name: data.role.name,
        },
        shift: data.shift,
        note: data.note,
        ...(data.payment_type && data.wage
          ? { custom_payment: { payment_type_id: data.payment_type, wage: data.wage } }
          : {}),
      });
    }
  });
};

const updateOpenShift = (state: InitialState, data: UpdateOpenShiftData) => {
  state.open_shifts.forEach((day) => {
    if (day.date === data.date) {
      day.open_shift_requests = day.open_shift_requests.map((openShift) => {
        if (openShift.open_shift_request_id === data.open_shift_request_id) {
          return {
            ...openShift,
            ...(data.employees
              ? {
                  requested_employees:
                    data.roleEmployees.length === data.employees.length ? [] : data?.employees,
                }
              : {}),
            ...(data.number_of_employees ? { number_of_employees: data.number_of_employees } : {}),
            ...('note' in data ? { note: data.note } : {}),
          };
        }
        return openShift;
      });
    }
  });
};

const deleteOpenShift = (state: InitialState, openShiftId: number, date: string) => {
  state.open_shifts.forEach((day) => {
    if (day.date === date) {
      day.open_shift_requests = day.open_shift_requests.filter(
        (openShift) => openShift.open_shift_request_id !== openShiftId,
      );
    }
  });
};

const approveOpenShiftEmployees = (
  state: InitialState,
  data: BulkApproveOpenShiftEmployeesData,
) => {
  state.open_shifts.forEach((day) => {
    if (day.date === data.date) {
      day.open_shift_requests = day.open_shift_requests.map((openShift) => {
        if (openShift.open_shift_request_id === data.open_shift_request_id) {
          const newOpenShiftData = { ...openShift };
          const newApprovedEmployees = newOpenShiftData.accepted_employees.filter((emp) =>
            data.approve.includes(+emp.id),
          );
          newOpenShiftData.accepted_employees = newOpenShiftData.accepted_employees.filter(
            (emp) => !data.approve.includes(+emp.id),
          );
          const newAcceptedEmployees = newOpenShiftData.approved_employees.filter((emp) =>
            data.unapprove.includes(+emp.id),
          );
          newOpenShiftData.approved_employees = newOpenShiftData.approved_employees.filter(
            (emp) => !data.unapprove.includes(+emp.id),
          );
          newOpenShiftData.accepted_employees.push(...newAcceptedEmployees);
          newOpenShiftData.approved_employees.push(...newApprovedEmployees);
          return newOpenShiftData;
        }
        return openShift;
      });
    }
  });
};

export const openShifts = createSlice({
  name: 'open_shift',
  initialState,
  reducers: {
    // fetch data
    setOpenShiftsState: (state, action: PayloadAction<OpenShifts[]>) => {
      state.open_shifts = action.payload;
    },

    addOpenShiftStarted: (state, action: PayloadAction<AddOpenShiftData>) => {
      state.addOpenShift = { ...initialState.addOpenShift, isLoading: true, data: action.payload };
    },
    addOpenShiftError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.addOpenShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addOpenShift.error = action.payload;
      } else {
        state.addOpenShift.response = action.payload;
      }
    },
    addOpenShiftSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<AddOpenShiftResponse>;
        data: AddOpenShiftData;
      }>,
    ) => {
      state.addOpenShift.response = action.payload.response;
      state.addOpenShift.isLoading = false;
      addOpenShift(
        state,
        action.payload.data,
        action.payload.response.result.open_shift_request_id,
      );
    },

    updateOpenShiftStarted: (state, action: PayloadAction<UpdateOpenShiftData>) => {
      state.updateOpenShift = {
        ...initialState.updateOpenShift,
        isLoading: true,
        data: action.payload,
      };
    },
    updateOpenShiftError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.updateOpenShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateOpenShift.error = action.payload;
      } else {
        state.updateOpenShift.response = action.payload;
      }
    },
    updateOpenShiftSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<number>;
        data: UpdateOpenShiftData;
      }>,
    ) => {
      state.updateOpenShift.isLoading = false;
      state.updateOpenShift.response = action.payload.response;
      updateOpenShift(state, action.payload.data);
    },

    deleteOpenShiftStarted: (state) => {
      state.deleteOpenShift = {
        ...initialState.deleteOpenShift,
        isLoading: true,
      };
    },
    deleteOpenShiftError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.deleteOpenShift.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deleteOpenShift.error = action.payload;
      } else {
        state.deleteOpenShift.response = action.payload;
      }
    },
    deleteOpenShiftSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<number>;
        openShiftId: number;
        date: string;
      }>,
    ) => {
      state.deleteOpenShift.isLoading = false;
      state.deleteOpenShift.response = action.payload.response;
      deleteOpenShift(state, action.payload.openShiftId, action.payload.date);
    },

    notifyEmployeesStarted: (state, action: PayloadAction<NotifyOpenShiftEmployeeData>) => {
      state.notifyEmployees = {
        ...initialState.notifyEmployees,
        isLoading: true,
        data: action.payload,
      };
    },
    notifyEmployeesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.notifyEmployees.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.notifyEmployees.error = action.payload;
      } else {
        state.notifyEmployees.response = action.payload;
      }
    },
    notifyEmployeesSuccess: (state, action: PayloadAction<ResponseSuccess<string[]>>) => {
      state.notifyEmployees.isLoading = false;
      state.notifyEmployees.response = action.payload;
    },

    approveEmployeesStarted: (state, action: PayloadAction<BulkApproveOpenShiftEmployeesData>) => {
      state.approveOpenShiftEmployees = {
        ...initialState.approveOpenShiftEmployees,
        isLoading: true,
        data: action.payload,
      };
    },
    approveEmployeesError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.approveOpenShiftEmployees.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.approveOpenShiftEmployees.error = action.payload;
      } else {
        state.approveOpenShiftEmployees.response = action.payload;
      }
    },
    approveEmployeesSuccess: (
      state,
      action: PayloadAction<{
        data: BulkApproveOpenShiftEmployeesData;
        response: ResponseSuccess<boolean>;
      }>,
    ) => {
      state.approveOpenShiftEmployees.isLoading = false;
      state.approveOpenShiftEmployees.response = action.payload.response;
      approveOpenShiftEmployees(state, action.payload.data);
    },

    fetchEmployeesUnAvailabilityStarted: (state) => {
      state.employeesUnAvailability = {
        ...initialState.employeesUnAvailability,
        isLoading: true,
      };
    },
    fetchEmployeesUnAvailabilityError: (
      state,
      action: PayloadAction<ResponseError | RequestError>,
    ) => {
      state.employeesUnAvailability.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.employeesUnAvailability.error = action.payload;
      } else {
        state.employeesUnAvailability.response = action.payload;
      }
    },
    fetchEmployeesUnAvailabilitySuccess: (
      state,
      action: PayloadAction<ResponseSuccess<EmployeesUnAvailability>>,
    ) => {
      state.employeesUnAvailability.isLoading = false;
      state.employeesUnAvailability.response = action.payload;
      state.employeesUnAvailability.data = action.payload.result;
    },

    resetNotifyEmployeesState: (state) => {
      state.notifyEmployees = { ...initialState.notifyEmployees };
    },
    resetApproveOpenShiftEmployeesState: (state) => {
      state.approveOpenShiftEmployees = { ...initialState.approveOpenShiftEmployees };
    },
    resetManageOpenShiftActions: (state) => {
      state.addOpenShift = { ...initialState.addOpenShift };
      state.updateOpenShift = { ...initialState.updateOpenShift };
      state.deleteOpenShift = { ...initialState.deleteOpenShift };
    },

    clearSlices: (state) => {
      state.addOpenShift = { ...initialState.addOpenShift };
      state.approveOpenShiftEmployees = { ...initialState.approveOpenShiftEmployees };
      state.deleteOpenShift = { ...initialState.deleteOpenShift };
      state.employeesUnAvailability = { ...initialState.employeesUnAvailability };
      state.notifyEmployees = { ...initialState.notifyEmployees };
      state.open_shifts = [];
      state.updateOpenShift = { ...initialState.updateOpenShift };
    },
  },
});

export default openShifts.reducer;

export const { actions } = openShifts;

export const selectOpenShifts = (state: State<InitialState>) => state.openShiftsSlice.open_shifts;

export const selectAddOpenShiftsState = (state: State<InitialState>) =>
  state.openShiftsSlice.addOpenShift;

export const selectUpdateOpenShiftsState = (state: State<InitialState>) =>
  state.openShiftsSlice.updateOpenShift;

export const selectDeleteOpenShiftsState = (state: State<InitialState>) =>
  state.openShiftsSlice.deleteOpenShift;

export const selectNotifyOpenShiftEmployeesState = (state: State<InitialState>) =>
  state.openShiftsSlice.notifyEmployees;

export const selectApproveOpenShiftEmployeesState = (state: State<InitialState>) =>
  state.openShiftsSlice.approveOpenShiftEmployees;

export const selectEmployeesUnAvailabilitiesState = (state: State<InitialState>) =>
  state.openShiftsSlice.employeesUnAvailability;

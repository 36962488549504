import { useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';

type AppMainProps = {
  children: React.ReactNode;
};

function AppMain(props: AppMainProps) {
  const { children } = props;
  const scrollbarRef = useRef<PerfectScrollbar>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    scrollbarRef.current?.updateScroll();
  }, [pathname]);

  return (
    <PerfectScrollbar
      component="main"
      ref={scrollbarRef}
      style={{ flexGrow: 1, padding: '24px 24px 8px 24px', minHeight: 'calc(100vh - 60px)' }}
    >
      {children}
    </PerfectScrollbar>
  );
}
export default AppMain;

import { useEffect, useCallback } from 'react';
import storage from '../storage';

const useReloadOnLoggedInUserChanged = () => {
  const focusEventListener = useCallback(() => {
    const userData = storage.getUserData();
    const sessionUserId = storage.getUserId();
    if ((sessionUserId || userData?.id) && +sessionUserId !== +(userData?.id || 0)) {
      if (userData?.id && !Number.isNaN(userData.id)) storage.setUserId(Number(userData.id));
      else storage.removeUserId();
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('focus', focusEventListener);
    return () => {
      window.removeEventListener('focus', focusEventListener);
    };
  }, [focusEventListener]);
};

export default useReloadOnLoggedInUserChanged;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AddPositionRequest,
  AddPositionResponse,
  ManagePositionRequest,
  Position,
  AddPositionSuccessPayload,
  UpdatePositionSuccessPayload,
  DeletePositionSuccessPayload,
  UpdatePositionIndexSuccessPayload,
} from '../../types/Positions';
import { ResponseError, RequestError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import utils from '../../shared/utils';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data?: Req | undefined;
}

export type InitialState = {
  fetchPositions: ActionType<Position[], ResponseSuccess<Position[]>>;
  createPosition: ActionType<AddPositionRequest, ResponseSuccess<AddPositionResponse>>;
  updatePosition: ActionType<ManagePositionRequest, ResponseSuccess<{ role_id: number }>>;
  deletePosition: ActionType<ManagePositionRequest, ResponseSuccess<boolean>>;
  updatePositionIndex: ActionType<AddPositionRequest, ResponseSuccess<boolean>>;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

const initialState: InitialState = {
  fetchPositions: { ...ACTIONS_INITIAL_STATE, data: [] },
  createPosition: { ...ACTIONS_INITIAL_STATE },
  updatePosition: { ...ACTIONS_INITIAL_STATE, error: undefined, response: undefined },
  deletePosition: { ...ACTIONS_INITIAL_STATE },
  updatePositionIndex: { ...ACTIONS_INITIAL_STATE },
};

const position = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    fetchPositionsStarted: (state) => {
      state.fetchPositions = { ...initialState.fetchPositions, isLoading: true };
    },
    fetchPositionsError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchPositions.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchPositions.error = action.payload;
      } else {
        state.fetchPositions.response = action.payload;
      }
      state.fetchPositions.data = [];
    },
    fetchPositionsSuccess: (state, action: PayloadAction<ResponseSuccess<Position[]>>) => {
      state.fetchPositions.isLoading = false;
      state.fetchPositions.data = utils.position.sortPositions(
        action.payload.result.map((pos) => ({ ...pos, index: Number(pos.index) })),
      );
    },

    createPositionStarted: (state) => {
      state.createPosition = { ...initialState.createPosition, isLoading: true };
    },
    createPositionError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.createPosition.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.createPosition.error = action.payload;
      } else {
        state.createPosition.response = action.payload;
      }
    },
    createPositionSuccess: (state, action: PayloadAction<AddPositionSuccessPayload>) => {
      state.createPosition.isLoading = false;
      state.createPosition.response = action.payload.response;
      state.fetchPositions.data = utils.position.sortPositions([
        ...(state.fetchPositions.data || []),
        {
          employees_count: 0,
          id: action.payload.response.result.role_id,
          name: action.payload.role.role_name,
          index: 0,
        },
      ]);
    },

    updatePositionStarted: (state) => {
      state.updatePosition = { ...initialState.updatePosition, isLoading: true };
    },
    updatePositionError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updatePosition.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updatePosition.error = action.payload;
      } else {
        state.updatePosition.response = action.payload;
      }
    },
    updatePositionSuccess: (state, action: PayloadAction<UpdatePositionSuccessPayload>) => {
      state.updatePosition.isLoading = false;
      state.updatePosition.response = action.payload.response;
      state.fetchPositions.data = utils.position.sortPositions(
        state.fetchPositions.data?.map((item) =>
          item.id === action.payload.role.id
            ? {
                ...item,
                name: action.payload.role.role_name,
                id: action.payload.response.result.role_id,
              }
            : item,
        ) || [],
      );
    },

    deletePositionStarted: (state) => {
      state.deletePosition = { ...initialState.deletePosition, isLoading: true };
    },
    deletePositionError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.deletePosition.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deletePosition.error = action.payload;
      } else {
        state.deletePosition.response = action.payload;
      }
    },
    deletePositionSuccess: (state, action: PayloadAction<DeletePositionSuccessPayload>) => {
      state.deletePosition.isLoading = false;
      state.deletePosition.response = action.payload.response;
      state.fetchPositions.data = state.fetchPositions.data
        ?.filter((role) => role.id !== action.payload.data.role.id)
        .map((item) =>
          item.id === action.payload.data.alternate_role
            ? {
                ...item,
                employees_count: item.employees_count + action.payload.data.role.employees_count,
              }
            : item,
        );
    },

    updatePositionIndexStarted: (state) => {
      state.updatePositionIndex = { ...initialState.updatePositionIndex, isLoading: true };
    },
    updatePositionIndexError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updatePositionIndex.isLoading = false;
      state.fetchPositions.data = state.fetchPositions.data?.slice();
      if (utils.general.isRequestError(action.payload)) {
        state.updatePositionIndex.error = action.payload;
      } else {
        state.updatePositionIndex.response = action.payload;
      }
    },
    updatePositionIndexSuccess: (
      state,
      action: PayloadAction<UpdatePositionIndexSuccessPayload>,
    ) => {
      state.fetchPositions.data = utils.position.sortPositions(action.payload.items, ['index']);
      state.updatePositionIndex.isLoading = false;
      state.updatePositionIndex.response = action.payload.response;
    },

    resetFormErrors: (state) => {
      state.updatePosition.error = initialState.updatePosition.error;
      state.updatePosition.response = initialState.updatePosition.response;

      state.deletePosition.error = initialState.deletePosition.error;
      state.deletePosition.response = initialState.deletePosition.response;

      state.createPosition.error = initialState.createPosition.error;
      state.createPosition.response = initialState.createPosition.response;
    },

    clearSlices: (state) => {
      state.createPosition = { ...initialState.createPosition };
      state.deletePosition = { ...initialState.deletePosition };
      state.fetchPositions = { ...initialState.fetchPositions };
      state.updatePosition = { ...initialState.updatePosition };
      state.updatePositionIndex = { ...initialState.updatePositionIndex };
    },
  },
});

export default position.reducer;

export const {
  resetFormErrors,

  updatePositionIndexStarted,
  updatePositionIndexSuccess,
  updatePositionIndexError,

  fetchPositionsStarted,
  fetchPositionsError,
  fetchPositionsSuccess,

  createPositionStarted,
  createPositionError,
  createPositionSuccess,

  updatePositionStarted,
  updatePositionError,
  updatePositionSuccess,

  deletePositionStarted,
  deletePositionError,
  deletePositionSuccess,

  clearSlices,
} = position.actions;

export const selectAddPositionState = (state: State<InitialState>) =>
  state.positionSlice.createPosition;

export const selectUpdatePositionState = (state: State<InitialState>) =>
  state.positionSlice.updatePosition;

export const selectUpdatePositionIndexState = (state: State<InitialState>) =>
  state.positionSlice.updatePositionIndex;

export const selectDeletePositionState = (state: State<InitialState>) =>
  state.positionSlice.deletePosition;

export const selectFetchPositionsState = (state: State<InitialState>) =>
  state.positionSlice.fetchPositions;

/* eslint-disable react/destructuring-assignment */
import React, { Component, createRef } from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IconButton from '@mui/material/IconButton';
import { Constants } from '../../constants';
import MeridiemMenu from './MeridiemMenu';

const VK_LEFT_ARROW = 37;
const VK_RIGHT_ARROW = 39;
const VK_REMOVE = [8, 46];
const VK_TAB = 9;
const VK_NUMBERS = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
const VK_NUMPAD = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
const VK_KEY_CODE = [
  VK_TAB,
  ...VK_REMOVE,
  VK_LEFT_ARROW,
  VK_RIGHT_ARROW,
  ...VK_NUMBERS,
  ...VK_NUMPAD,
];

const autoFillValue = (value: string, mask: string, maskReg: RegExp) => {
  const DEFAULT_VALUE = '0';
  let newVal = '';
  const numberPattern = /\d+/g;
  const numbers = value.match(numberPattern);
  const maskNumCount = mask.match(/X/g) || [];

  if (numbers) {
    const numberArr = numbers.join('').split('');
    if (numberArr.length === 1) {
      numberArr.unshift('0');
    }

    const count = maskNumCount.length - numberArr.length;

    for (let i = 0; i < count; i += 1) {
      numberArr.push(DEFAULT_VALUE);
    }
    newVal = numberArr.join('');
  }
  const formatedValue = `${newVal?.substring(0, 2)}:${newVal?.substring(2)}`;
  if (formatedValue?.match(maskReg)) return newVal;
  return value;
};

const removeUselessPart = (value: string) => {
  const phoneCode = '+7 ( ';
  const maskNumber = value;
  let index;
  for (index = maskNumber.length - 1; index >= 0; index -= 1) {
    if (Number.isNaN(+maskNumber[index]) || maskNumber[index] === ' ') {
      if (maskNumber === phoneCode || !maskNumber) {
        return '';
      }
    } else {
      break;
    }
  }
  return maskNumber.substr(0, index + 1);
};

const timeConverter = (value: string, hours12: boolean) => {
  const UPPER_THEN = hours12 ? 1 : 2;
  const LOWER_THEN = 10;
  return `${value}`.length < 2 && +value > UPPER_THEN && +value < LOWER_THEN ? `0${value}` : null;
};

type TimeInputTextFieldProps = {
  hours12: boolean;
  date?: string;
  setShowTimePicker: React.Dispatch<React.SetStateAction<boolean>>;
  onMeridianChange: (value: 'AM' | 'PM') => void;
  meridian?: 'AM' | 'PM';
} & TextFieldProps;

type TimeInputTextFieldState = {
  placeholderMask: string;
  currCursorPos: number;
  mask: string;
  maskReg: RegExp;
};

class TimeInputTextField extends Component<TimeInputTextFieldProps, TimeInputTextFieldState> {
  private deleteNum: string | boolean = '';

  private prevValue?: string = undefined;

  private input;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps: {
    meridian?: string;
    date?: string;
  };

  constructor(props: TimeInputTextFieldProps) {
    super(props);
    this.deleteNum = '';
    this.state = {
      placeholderMask: '',
      currCursorPos: 0,
      mask: Constants.MASK.TIME,
      maskReg: props.hours12 ? Constants.REGEXP.MASK_TIME_12 : Constants.REGEXP.MASK_TIME_24,
    };

    this.normalize = this.normalize.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.input = createRef<HTMLInputElement | null>();
  }

  componentDidUpdate(prevProps: TimeInputTextFieldProps, prevState: TimeInputTextFieldState) {
    this.prevValue = this.addSpaces(prevProps.value as string);
    let { value } = this.props;

    if (prevProps.hours12 !== this.props.hours12) {
      this.setState({
        maskReg: this.props.hours12 ? Constants.REGEXP.MASK_TIME_12 : Constants.REGEXP.MASK_TIME_24,
      });
    }

    if (prevProps.value !== value) {
      value = this.addSpaces(value as string);
      const currCursorPos = +this.state.currCursorPos;
      const index = this.deleteNum
        ? this.delPhoneCursor(value as string, currCursorPos)
        : this.addPhoneCursor(value as string, currCursorPos);

      if (this.input?.current) {
        this.input.current?.setSelectionRange?.(index, index);
      }
      this.setState({ currCursorPos: index });
    }

    if (
      this.state.currCursorPos !== prevState.currCursorPos &&
      this.state.currCursorPos > this.state.mask.length
    ) {
      this.setState({ currCursorPos: ((this.props.value || '') as string)?.length || 0 });
    }
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = this.normalize(e.target.value);
    if (value && this.state.maskReg && !value.replace(/\s/g, '').match(this.state.maskReg)) {
      const valueWithLeadingZero = value
        ?.replace(/\s/g, '')
        ?.replace(':', '')
        ?.padStart(value.length + 1, '0');
      const formatedValueWithLeadingZero = `${valueWithLeadingZero.substr(
        0,
        2,
      )}:${valueWithLeadingZero.substr(2)}`;
      if (
        value.length < this.state.mask?.length &&
        formatedValueWithLeadingZero.match(this.state.maskReg)
      ) {
        this.props.onChange?.({
          ...e,
          target: { ...e.target, value: formatedValueWithLeadingZero },
        });
        this.setState({ currCursorPos: formatedValueWithLeadingZero.length });
        return;
      }
      if (e.preventDefault) {
        e.preventDefault();
      }
      return;
    }
    this.props.onChange?.({
      ...e,
      target: { ...e.target, value },
    });
  }

  handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    this.setState({ placeholderMask: this.getMaskPlaceholder() });
    e.target.select?.();
    this.props.onFocus?.(e);
  }

  handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    let value = this.normalize(e.target.value);
    this.setState({ placeholderMask: '' });
    const { mask } = this.state;

    if (value.length < mask.replace(/\s/g, '').length) {
      value = autoFillValue(value, mask, this.state.maskReg);
    }

    this.props.onBlur?.({
      ...e,
      target: { ...e.target, value: this.formatToMask(value, mask).replace(/\s/g, '') },
    });

    this.props.onChange?.({
      ...e,
      target: { ...e.target, value: this.formatToMask(value, mask).replace(/\s/g, '') },
    });
  }

  handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const value = this.input.current?.value || '';
    const { mask } = this.state;

    if (e.keyCode === VK_TAB && value.length < mask.length) {
      this.props.onChange?.({
        ...(e as unknown as React.ChangeEvent<HTMLInputElement>),
        target: {
          ...(e as unknown as React.ChangeEvent<HTMLInputElement>).target,
          value: this.formatToMask(autoFillValue(value, mask, this.state.maskReg), mask).replace(
            /\s/g,
            '',
          ),
        },
      });
    }

    if (value.length >= mask.length && VK_KEY_CODE.indexOf(e.keyCode) === -1) {
      e.preventDefault();
      return;
    }

    this.deleteNum = VK_REMOVE.indexOf(e.keyCode) !== -1;
    this.setState({ currCursorPos: this.input.current?.selectionStart || 0 });
  }

  handleClick() {
    if (!this.props.disabled && this.input?.current) {
      this.setState({ currCursorPos: this.input.current?.selectionStart || 0 });
    }
  }

  getMaskPlaceholder() {
    let { mask } = this.state;
    mask = mask ? mask.replace(/S/g, ' ') : '';
    return mask ? mask.replace(/X/g, '_') : '';
  }

  getMaskValue(value: string, mask: string, deleteNum: string | boolean) {
    let maskNumber;
    if (!deleteNum) {
      maskNumber = this.formatToMask(value, mask);
      return removeUselessPart(maskNumber);
    }

    const currCursorState = +this.state.currCursorPos;
    const deletedItem = this.prevValue?.[currCursorState - 1];

    if (deletedItem && (Number.isNaN(+deletedItem) || deletedItem === ' ')) {
      let firstP = value.substr(0, currCursorState - 1);
      const lastP = value.substr(currCursorState - 1);
      let index;
      for (index = firstP.length - 1; index >= 0; index -= 1) {
        if (!Number.isNaN(+firstP[index]) && firstP[index] !== ' ') {
          break;
        }
      }

      firstP = firstP.substr(0, index);
      maskNumber = firstP + lastP;
    }

    return maskNumber || value;
  }

  normalize(value: string) {
    const newVal = timeConverter(value, this.props.hours12);

    let result = value;

    if (newVal) {
      result = newVal;
      this.setState({ currCursorPos: result.length });
    }
    result = this.getMaskValue(result, this.state.mask, this.deleteNum);
    result = result ? result.replace(/ +?/g, '') : '';
    result = result ? result.replace(/S/g, ' ') : '';
    return result;
  }

  addSpaces(value?: string) {
    const res = value ? this.getMaskValue(value, this.state.mask, false) : value;
    return res ? res.replace(/S/g, ' ') : res;
  }

  delPhoneCursor(value: string, index: number) {
    let i = index;
    const deletedItem = this.prevValue?.[i - 1];
    if (deletedItem && (deletedItem === ' ' || Number.isNaN(+deletedItem))) {
      const firstP = value.substr(0, i - 1);
      for (; i >= 0; i -= 1) {
        if (!Number.isNaN(+firstP[i]) && firstP[i] !== ' ') {
          break;
        }
      }
    } else {
      i -= 1;
    }
    return i;
  }

  addPhoneCursor(value: string, index: number) {
    const phoneCode = '+7 ( '.length;
    const isPhoneInput = this.state.mask.indexOf('+7 (') > -1;
    let currentIndex = isPhoneInput && !index ? phoneCode : index;

    for (; currentIndex < value.length; currentIndex += 1) {
      if (!Number.isNaN(+value[currentIndex]) && value[currentIndex] !== ' ') {
        break;
      }
    }

    return currentIndex + 1;
  }

  formatToMask(value: string, resMask: string) {
    let mask = resMask;
    const isPhoneInput = this.state.mask.indexOf('+7 (') > -1;
    const numberPattern = /\d+/g;
    const numbers = value.match(numberPattern);

    if (!numbers) {
      return '';
    }

    const numbersStr = numbers.join('');
    // let index = isPhoneInput ? (value.length > 1 ? 1 : 0) : 0;
    let index;
    if (isPhoneInput) {
      index = value.length > 1 ? 1 : 0;
    } else {
      index = 0;
    }

    for (; index < numbersStr.length; index += 1) {
      mask = mask.replace('X', numbersStr[index]);
    }

    const startCut = mask.indexOf('X');
    return startCut !== -1 ? mask.substr(0, startCut) : mask;
  }

  render() {
    const { hours12, date, onMeridianChange, setShowTimePicker, meridian, ...props } = this.props;
    return (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        sx={{
          ...props.sx,
          marginLeft: '0 !important',
          minWidth: '152px !important',
        }}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        onClick={this.handleClick}
        placeholder={this.state.placeholderMask || '__ : __'}
        value={this.addSpaces(this.props.value as string)}
        className={`time-input ${this.props.className || ''}`}
        inputRef={(inputRef: unknown) => {
          if (this.props.inputRef) {
            this.props.inputRef(inputRef);
          }
          this.input.current = inputRef;
        }}
        InputProps={{
          ...props.InputProps,
          startAdornment: (
            <div className="start-adornment-cont">
              <IconButton
                disabled={props.disabled}
                onClick={() => this.props.setShowTimePicker(true)}
              >
                <AccessTimeIcon color="action" />
              </IconButton>
            </div>
          ),

          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '2px',
                minWidth:
                  // eslint-disable-next-line no-nested-ternary
                  this.props.name === 'end_time' || this.props.name === 'end_hour'
                    ? hours12
                      ? '67px'
                      : '40px'
                    : '40px',
              }}
              className="end-adornment"
            >
              {hours12 && (
                <MeridiemMenu
                  disabled={!!props.disabled}
                  onChange={this.props.onMeridianChange}
                  value={this.props.meridian}
                />
              )}
              {props?.InputProps?.endAdornment}
            </Box>
          ),
        }}
      />
    );
  }
}

TimeInputTextField.defaultProps = {
  date: undefined,
  meridian: undefined,
};

export default TimeInputTextField;

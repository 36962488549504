export const ADD_OPEN_SHIFT = 'open_shift/add';
export const UPDATE_OPEN_SHIFT = 'open_shift/update';
export const DELETE_OPEN_SHIFT = 'open_shift/delete';
export const NOTIFY_EMPLOYEES_ON_OPEN_SHIFT = 'open_shift/notify_employees';
export const BULK_APPROVE_EMPLOYEES_ON_OPEN_SHIFT = 'open_shift/approve/multi';
export const GET_EMPLOYEES_UNAVAILABILITIES = 'employees_unavailabilities/get';

export default {
  ADD_OPEN_SHIFT,
  UPDATE_OPEN_SHIFT,
  DELETE_OPEN_SHIFT,
  NOTIFY_EMPLOYEES_ON_OPEN_SHIFT,
  BULK_APPROVE_EMPLOYEES_ON_OPEN_SHIFT,
  GET_EMPLOYEES_UNAVAILABILITIES,
};

import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import {
  GET_SALES_INPUT_TYPES,
  UPDATE_SALES_INPUT_TYPE,
  ADD_SALES_INPUT_TYPE,
  UPDATE_PROJECTED_SALES_COMPUTATION_METHOD,
  UPDATE_SALES_PERCENTAGE_CAP,
  UPDATE_CURRENCY,
  UPDATE_HOUR_FORMAT,
} from '../actionTypes/settings';
import { Response } from '../../types/Response';
import { actions } from '../slices/settingsSlice';
import utils from '../../shared/utils';
import {
  AddSalesTypeRequest,
  SalesInputType,
  UpdateCurrencyRequest,
  UpdateHourFormatRequest,
  UpdateProjectedSalesRequest,
  UpdateSalesPercentageCapRequest,
  UpdateSalesTypeRequest,
} from '../../types/Settings';

function* fetchSalesInputTypes({ payload }: PayloadAction<{ branchId: number }>) {
  try {
    yield put(actions.fetchSalesInputTypesStarted());
    const response: Response<SalesInputType[]> = yield call(() =>
      api.sales.getSalesInputTypes(payload.branchId),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(actions.fetchSalesInputTypesSuccess(response.data.result));
    } else {
      yield put(actions.fetchSalesInputTypesError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchSalesInputTypesError(utils.general.convertAxiosError(error)));
  }
}

function* updateSalesInputType({
  payload,
}: PayloadAction<{ branchId: number; sale: UpdateSalesTypeRequest }>) {
  try {
    yield put(actions.updateSalesInputTypeStarted());
    const response: Response<SalesInputType> = yield call(() =>
      api.sales.putSalesInputType(payload.branchId, payload.sale.id, {
        is_active: payload.sale.id,
        date: payload.sale.date,
      }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.updateSalesInputTypeSuccess({ id: payload.sale.id, response: response.data }),
      );
    } else {
      yield put(actions.updateSalesInputTyesError(response.data));
    }
  } catch (error) {
    yield put(actions.updateSalesInputTyesError(utils.general.convertAxiosError(error)));
  }
}

function* addSalesInputType({
  payload,
}: PayloadAction<{ branchId: number; sale: AddSalesTypeRequest }>) {
  try {
    yield put(actions.addSalesInputTypeStarted());
    const response: Response<{ id: number }> = yield call(() =>
      api.sales.postSalesInputType(payload.branchId, payload.sale),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.addSalesInputTypeSuccess({
          ...response.data,
          result: {
            id: response.data.result.id,
            start_date: payload.sale.start_date,
            is_active: 1,
            name: payload.sale.name,
            end_date: '9999-12-31',
          },
        }),
      );
    } else {
      yield put(actions.addSalesInputTypeError(response.data));
    }
  } catch (error) {
    yield put(actions.addSalesInputTypeError(utils.general.convertAxiosError(error)));
  }
}

function* updateProjectedSales({
  payload,
}: PayloadAction<{ branchId: number; data: UpdateProjectedSalesRequest }>) {
  try {
    yield put(actions.updateProjectedSalesStarted());
    const response: Response<string> = yield call(() =>
      api.sales.postSalesProjectionComputation(payload.branchId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.updateProjectedSalesSuccess({
          ...response.data,
          result: payload.data,
        }),
      );
    } else {
      yield put(actions.updateProjectedSalesError(response.data));
    }
  } catch (error) {
    yield put(actions.updateProjectedSalesError(utils.general.convertAxiosError(error)));
  }
}

function* updateSalesPercentageCap({
  payload,
}: PayloadAction<{
  branchId: number;
  data: UpdateSalesPercentageCapRequest;
}>) {
  try {
    yield put(actions.updateSalesPercentageCapStarted());
    const response: Response<string> = yield call(() =>
      api.sales.postSalesPercentageCap(payload.branchId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.updateSalesPercentageCapSuccess({
          ...response.data,
          result: payload.data,
        }),
      );
    } else {
      yield put(actions.updateSalesPercentageCapError(response.data));
    }
  } catch (error) {
    yield put(actions.updateSalesPercentageCapError(utils.general.convertAxiosError(error)));
  }
}

function* udpateCurrency({
  payload,
}: PayloadAction<{
  branchId: number;
  data: UpdateCurrencyRequest;
  currencySymbol: string;
}>) {
  try {
    yield put(actions.updateCurrencyStarted());
    const response: Response<string> = yield call(() =>
      api.settings.updateCurrency(payload.branchId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.updateCurrencySuccess({
          ...response.data,
          result: { id: payload.data.currency_id, currencySymbol: payload.currencySymbol },
        }),
      );
    } else {
      yield put(actions.updateCurrencyError(response.data));
    }
  } catch (error) {
    yield put(actions.updateCurrencyError(utils.general.convertAxiosError(error)));
  }
}

function* udpateHourFormat({
  payload,
}: PayloadAction<{ branchId: number; data: UpdateHourFormatRequest }>) {
  try {
    yield put(actions.updateHourFormatStarted());
    const response: Response<string> = yield call(() =>
      api.settings.updateHourFormat(payload.branchId, payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.updateHourFormatSuccess({
          ...response.data,
          result: payload.data,
        }),
      );
    } else {
      yield put(actions.updateHourFormatError(response.data));
    }
  } catch (error) {
    yield put(actions.updateHourFormatError(utils.general.convertAxiosError(error)));
  }
}

function* watchSettings() {
  yield all([
    takeLatest(GET_SALES_INPUT_TYPES, fetchSalesInputTypes),
    takeLatest(UPDATE_SALES_INPUT_TYPE, updateSalesInputType),
    takeLatest(ADD_SALES_INPUT_TYPE, addSalesInputType),
    takeLatest(UPDATE_PROJECTED_SALES_COMPUTATION_METHOD, updateProjectedSales),
    takeLatest(UPDATE_SALES_PERCENTAGE_CAP, updateSalesPercentageCap),
    takeLatest(UPDATE_CURRENCY, udpateCurrency),
    takeLatest(UPDATE_HOUR_FORMAT, udpateHourFormat),
  ]);
}

export default watchSettings;

import { Fragment, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import LensIcon from '@mui/icons-material/Lens';
import NavLinkItem from './NavLinkItem';
import LINKS from './NavLinksConfig';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';

type NavLinksProps = {
  open: boolean;
};

function NavLinks(props: NavLinksProps) {
  const { open } = props;
  const location = useLocation();
  const { branch_id: selectedBranchId } = useSettings();
  const {
    logout,
    auth: {
      userData: { roles },
    },
  } = useAuth();

  const currentRole = useMemo(
    () => roles.find((_role) => _role.branch_id === selectedBranchId),
    [selectedBranchId, roles],
  );

  const navLinks = useMemo(
    () =>
      LINKS.filter((link) => {
        if (link.requiredFeatures?.length) {
          return link.requiredFeatures.every(
            (featureId) => currentRole?.features?.includes(featureId),
          );
        }
        return true;
      }),
    [currentRole],
  );

  const logoutLink = useMemo(
    () => ({
      id: 'LOGOUT',
      label: 'Logout',
      icon: <LogoutIcon style={{ width: '21px', height: '21px' }} htmlColor="#A7A9AC" />,
      activeIcon: <LogoutIcon style={{ width: '21px', height: '21px' }} color="primary" />,
      to: '/',
      requiredFeatures: [],
    }),
    [],
  );

  return (
    <List component="nav">
      {navLinks.map((link) => (
        <Fragment key={link.id}>
          <Tooltip
            key={`tooltip-${link.id}`}
            placement="right"
            classes={{
              tooltip: `nav-link-tooltip ${link.navItems?.length ? 'sub-nav-tooltip' : ''}`,
            }}
            disableHoverListener={open}
            disableFocusListener
            title={
              link.navItems?.map((item) => {
                const active = item.to === location.pathname;
                return (
                  <div key={item.to} className={`active-${active} link-container`}>
                    <div className="line-cont">
                      <div className="line" />
                      <LensIcon />
                    </div>
                    <NavLinkItem open={open} link={item} />
                  </div>
                );
              }) || link.label
            }
          >
            <span>
              <NavLinkItem
                isActive={
                  location.pathname === link.to ||
                  link.navItems?.some((navItem) => navItem.to === location.pathname)
                }
                key={link.id}
                link={link}
                open={open}
              />
            </span>
          </Tooltip>

          {link.navItems?.map((navItem) => (
            <NavLinkItem
              isActive={location.pathname === navItem.to}
              key={navItem.id}
              link={navItem}
              open={open}
              className="sub-link"
            />
          ))}
        </Fragment>
      ))}
      <NavLinkItem
        isActive={location.pathname === logoutLink.to}
        className="logout"
        open={open}
        link={logoutLink}
        key={logoutLink.id}
        onClick={logout}
      />
    </List>
  );
}

export default NavLinks;

import { FC, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

interface MeridiemMenuProps {
  disabled?: boolean;
  onChange: (value: 'AM' | 'PM') => void;
  value?: 'AM' | 'PM';
}

const MeridiemMenuButton: FC<MeridiemMenuProps> = function MeridiemMenuButton(props) {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { disabled, onChange, value } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (newValue: 'AM' | 'PM') => {
    onChange(newValue);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="meridiem-menu-button"
        disabled={disabled}
        color="inherit"
        size="small"
        aria-controls={open ? 'meridiem-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ fontSize: 'inherit', borderRadius: '4px' }}
        onKeyDown={(e) => {
          if (e.code === 'KeyA') handleMenuItemClick('AM');
          else if (e.code === 'KeyP') handleMenuItemClick('PM');
        }}
      >
        {value || <span style={{ fontSize: '11px', color: 'grey' }}>am/pm</span>}
      </IconButton>
      <Menu
        id="meridiem-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'meridiem-menu-button',
        }}
      >
        <MenuItem selected={value === 'AM'} onClick={() => handleMenuItemClick('AM')}>
          AM
        </MenuItem>
        <MenuItem selected={value === 'PM'} onClick={() => handleMenuItemClick('PM')}>
          PM
        </MenuItem>
      </Menu>
    </>
  );
};

MeridiemMenuButton.defaultProps = {
  disabled: false,
  value: undefined,
};

export default MeridiemMenuButton;

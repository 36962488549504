import {
  UpdateShiftRequest,
  UpdateMultiShiftRequest,
  DeleteMultiShiftRequest,
  AddShiftRequest,
  AddShiftResponse,
  WeekShifts,
} from '../types/Shift';
import api from './axios';
import { Response } from '../types/Response';

const getShifts = (branchId: number, date?: string) =>
  api.get(`branch/${branchId}/shifts${date ? `/week?start_date=${date}` : ''}`) as Promise<
    Response<WeekShifts[]>
  >;

const putShift = (branchId: number, shiftId: number, data: UpdateShiftRequest) =>
  api.put(`branch/${branchId}/shift/${shiftId}`, data) as Promise<Response<{ shift_id: number }>>;

const updateMultiShift = (branchId: number, data: UpdateMultiShiftRequest) =>
  api.post(`branch/${branchId}/shifts/multiple/edit`, data) as Promise<Response<number[]>>;

const postShift = (branchId: number, data: AddShiftRequest) =>
  api.post(`branch/${branchId}/shifts`, data) as Promise<Response<AddShiftResponse>>;

const deleteShift = (branchId: number, shiftId: number) =>
  api.delete(`branch/${branchId}/shift/${shiftId}`) as Promise<Response<boolean>>;

const deleteMultiShift = (branchId: number, data: DeleteMultiShiftRequest) =>
  api.post(`branch/${branchId}/shifts/multiple/delete`, data) as Promise<Response<boolean>>;

export default {
  getShifts,
  updateMultiShift,
  postShift,
  putShift,
  deleteMultiShift,
  deleteShift,
};

import { configureStore, combineReducers, AnyAction, CombinedState } from '@reduxjs/toolkit';
import sagaMiddleWare, { runSagas } from './sagas';
import manageScheduleSlice, {
  InitialState as ManageScheduleSliceState,
} from './slices/manageScheduleSlice';
import positionSlice, { InitialState as PositionSliceState } from './slices/positionsSlice';
import dialogSlice, { InitialState as DialogSliceState } from './slices/dialogSlice';
import shiftSlice, { InitialState as ShiftSliceState } from './slices/shiftSlice';
import notificationsSlice, {
  InitialState as NotificationsSliceState,
} from './slices/notificationsSlice';
import dailyScheduleSlice, {
  InitialState as DailyScheduleSliceState,
} from './slices/dailySchedule';
import settingsSlice, { InitialState as SettingsSliceState } from './slices/settingsSlice';
import employeesSlice, { InitialState as EmployeesSliceState } from './slices/employeesSlice';
import requestsSlice, { InitialState as RequestsSliceState } from './slices/requestsSlice';
import requestsDaySlice, { InitialState as RequestsDaySliceState } from './slices/requestsDaySlice';
import weeklyScheduleSlice from './slices/weeklyScheduleSlice';
import { WeeklyScheduleInitialState } from '../types/WeeklySchedule';
import openShiftsSlice, { InitialState as OpenShiftsSliceState } from './slices/openShifts';
import reportingSlice, { InitialState as ReportingSliceState } from './slices/reporting';
import punchSlice, { InitialState as PunchSliceState } from './slices/punchSlice';
import { Constants } from '../constants';

const combinedReducer = combineReducers({
  manageScheduleSlice,
  dialogSlice,
  shiftSlice,
  positionSlice,
  notificationsSlice,
  dailyScheduleSlice,
  settingsSlice,
  employeesSlice,
  requestsDaySlice,
  requestsSlice,
  weeklyScheduleSlice,
  openShiftsSlice,
  reportingSlice,
  punchSlice,
});

const rootReducer = (
  state:
    | CombinedState<{
        manageScheduleSlice: ManageScheduleSliceState;
        dialogSlice: DialogSliceState<object>;
        shiftSlice: ShiftSliceState;
        positionSlice: PositionSliceState;
        notificationsSlice: NotificationsSliceState;
        dailyScheduleSlice: DailyScheduleSliceState;
        settingsSlice: SettingsSliceState;
        employeesSlice: EmployeesSliceState;
        requestsDaySlice: RequestsDaySliceState;
        requestsSlice: RequestsSliceState;
        weeklyScheduleSlice: WeeklyScheduleInitialState;
        openShiftsSlice: OpenShiftsSliceState;
        reportingSlice: ReportingSliceState;
        punchSlice: PunchSliceState;
      }>
    | undefined,
  action: AnyAction,
) => {
  if (action.type === Constants.LOGOUT_ACTION.type) {
    // set store state to undefined when logout actions is dispatched
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleWare) => getDefaultMiddleWare().concat(sagaMiddleWare),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

runSagas();

export default store;

import { AddPositionRequest, AddPositionResponse, Position } from '../types/Positions';
import api from './axios';
import { Response } from '../types/Response';

const getRoles = (branchId: number, date?: string) =>
  api.get(`branch/${branchId}/roles${date ? `?start_date=${date}` : ''}`) as Promise<
    Response<Position[]>
  >;

const updateRoleIndex = (
  branchId: number,
  data: {
    newItems: {
      role_id: number;
      role_index: number;
    }[];
  },
) => api.put(`/branch/${branchId}/updateRoleIndexes`, data) as Promise<Response<boolean>>;
const postRole = (branchId: number, data: AddPositionRequest) =>
  api.post(`/branch/${branchId}/role`, data) as Promise<Response<AddPositionResponse>>;

const putRole = (branchId: number, roleId: number, data: { role_name: string }) =>
  api.put(`/branch/${branchId}/role/${roleId}`, data) as Promise<Response<boolean>>;

const deleteRole = (
  branchId: number,
  roleId: number,
  data: {
    alternate_role_id?: number;
  },
) =>
  api.delete(
    `/branch/${branchId}/role/${roleId}?alternate_role_id=${data.alternate_role_id}`,
  ) as Promise<Response<boolean>>;

export default {
  getRoles,
  updateRoleIndex,
  putRole,
  deleteRole,
  postRole,
};

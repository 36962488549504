export const FETCH_SHIFTS = 'manage/shifts/get';
export const ADD_SHIFT = 'manage/shifts/add';
export const UPDATE_SHIFT = 'manage/shifts/update';
export const DELETE_SHIFT = 'manage/shifts/delete';

export const UPDATE_MULTIPLE_SHIFT = 'manage/shifts/multiple/update';
export const DELETE_MULTIPLE_SHIFT = 'manage/shifts/multiple/delete';

export default {
  ADD_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  UPDATE_MULTIPLE_SHIFT,
  DELETE_MULTIPLE_SHIFT,
  FETCH_SHIFTS,
};

import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { analytics } from './resources/index';
import theme from './styles/muiTheme';
import AppRoutes from './Root/Routes/AppRoutes';
import { AuthProvider } from './context/AuthContext/AuthContext';
import { SettingsProvider } from './context/SettingsContext/SettingsContext';
import NetworkDetectorProvider from './context/NetworkStatusContext';
import { AlertProvider } from './context/AlertContext';
import { Constants } from './constants';
import store from './store/store';
import { ErrorBoundary, ErrorView } from './bugsnag';
import useServiceWorker from './hooks/useServiceWorker';

import './styles/global.scss';

if (import.meta.env.MODE === Constants.NODE_ENV.PRODUCTION) {
  hotjar.initialize(1174830, 6);
}

function App() {
  useServiceWorker();
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <NetworkDetectorProvider>
              <AlertProvider>
                <AuthProvider>
                  <SettingsProvider>
                    <AppRoutes />
                  </SettingsProvider>
                </AuthProvider>
              </AlertProvider>
            </NetworkDetectorProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </ErrorBoundary>
  );
}

analytics.init();

export default App;

import React from 'react';
import storage from '../../storage';
import { SalesProjectionMethod } from '../../enums';
import { RequestError } from '../../types/Response';
import { BranchSettings, RoleData, UserData } from '../../types/Authentications';

const BranchSetting: BranchSettings = {
  allow_punch_outside_branch: 0,
  use_wifi_punch: false,
  theme_id: 0,
  report_display_minutes_after_dot: 0,
  report_add_annual_leave_column: 0,
  report_show_overtime_column: 0,
  report_show_holiday_column: 0,
  report_show_overtime_and_holiday_for_scheduled: 0,
  hide_unpublished_shifts: 0,
  block_changing_preferences_on_days: [],
  open_shift_requires_approval: 0,
  break_time_supported: 0,
  custom_payment_supported: 0,
  report_round_to_quarter: 0,
  exported_report_round_to_quarter: 0,
  has_branch_employee_code: 0,
  report_export_to_payroll_system: 0,
  report_show_geofence: 0,
  report_display_decimal_time: 0,
};

export const roleData: RoleData = {
  branch_id: 0,
  branch_name: '',
  branch_settings: BranchSetting,
  branch_timezone: '',
  company_id: 0,
  country_id: 0,
  country_isd: 0,
  country_iso: 'LB',
  currency_id: 0,
  currency_symbol: '',
  employee_code: null,
  features: [],
  first_weekday: 0,
  is_12hr: 0,
  is_temporary_position: 0,
  permissions: [],
  role_id: 0,
  sales_percentage_cap: 0,
  sales_projection_computation_method_id: SalesProjectionMethod.COPY_FROM_LAST_WEEK,
};

const User: UserData = {
  id: '',
  name: '',
  roles: [],
  company_settings: {},
};

export interface Authentication {
  userData: UserData;
  email: string;
  isFetched: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  resMessage: string;
  error: RequestError | null;
}

export const initialState: Authentication = {
  userData: User,
  email: '',
  isFetched: false,
  isLoading: false,
  isAuthenticated: false,
  resMessage: '',
  error: null,
};

export interface ContextType {
  auth: Authentication;
  setAuth: React.Dispatch<React.SetStateAction<Authentication>>;
  logout: () => void;
  login: (email: string, password: string) => Promise<string>;
}

export const contextTypes: ContextType = {
  login(): Promise<string> {
    throw new Error('Function not implemented.');
  },
  auth: initialState,
  setAuth(): void {
    throw new Error('Function not implemented.');
  },
  logout(): void {
    throw new Error('Function not implemented.');
  },
};

export const getProviderInitialState = (): Authentication => {
  const userData: UserData = storage.getUserData() || initialState.userData;
  const email: string = storage.getEmail();
  return {
    ...initialState,
    userData,
    email,
  };
};

export default initialState;

import { FC } from 'react';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface MuiDialogTitleProps extends DialogTitleProps {
  onClose: () => void;
}

const MuiDialogTitle: FC<MuiDialogTitleProps> = function MuiDialogTitle(
  props: MuiDialogTitleProps,
) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        pl: 0,
        pb: 0,
        pr: 0,
        pt: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default MuiDialogTitle;

import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { analytics, texts } from '../../resources';
import useAuth from '../../hooks/useAuth';
import MuiSelect from '../../components/Controls/SelectField/MuiSelect';
import NotificationPopover from './Notification/NotificationList/NotificationPopover';
import SchedexLogo from '../../assets/icons/schedex_logo.svg';
import useSettings from '../../hooks/useSettings';
import { Config } from '../../config';

type PersistentDrawerLeftProps = {
  open: boolean;
  handleDrawerOpen: () => void;
};

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: Config.DRAWER_WIDTH,
    width: `calc(100% - ${Config.DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const MenuButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean;
}>(({ open }) => ({
  width: Config.DRAWER_WIDTH,
  minWidth: '50px',
  padding: '0',
  height: '30px',
  borderRadius: '0',
  ...(open && {
    display: 'none',
  }),
  ...(!open && {
    width: '50px',
  }),
}));

function PersistentDrawerLeft(props: PersistentDrawerLeftProps) {
  const { auth } = useAuth();
  const { changeBranch, branch_id: selectedBranchId } = useSettings();
  const { open, handleDrawerOpen } = props;
  const userName = auth.userData.name;
  const { roles } = auth.userData;
  const branchList: { value: string; label: string }[] = useMemo(
    () =>
      roles.map((role) => ({
        value: String(role.branch_id),
        label: role.branch_name,
      })),
    [roles],
  );

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar style={{ paddingLeft: 0, minHeight: '30px' }}>
        <MenuButton open={open} aria-label="open drawer" onClick={handleDrawerOpen}>
          <MenuIcon />
        </MenuButton>

        <Box
          display="flex"
          sx={{ flexGrow: 1, ml: '27px', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <img style={{ width: '85px' }} className="logo" alt={texts.TITLE} src={SchedexLogo} />
          <div className="center-side">
            {roles.length > 1 && (
              <MuiSelect
                disableUnderline
                variant="standard"
                onChange={(event) => {
                  const newBranchId = event.target?.value;
                  if (newBranchId && !Number.isNaN(newBranchId)) {
                    changeBranch(Number(newBranchId));
                    analytics.sendAnalyticEvent(analytics.categories.CHANGE_BRANCH);
                  }
                }}
                menuItems={branchList}
                value={selectedBranchId}
              />
            )}
            {roles.length === 1 && <div className="branch-name">{roles[0].branch_name}</div>}
          </div>
          {!!userName && (
            <div className="right-side">
              <NotificationPopover />
              <p>{`Welcome, ${userName}`}</p>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default PersistentDrawerLeft;

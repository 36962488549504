import dayjs, { Dayjs } from 'dayjs';
import { Weekday } from '../../enums';
import { DateFormats } from '../../types/Date';

export type WeekDay = {
  weekday: Weekday;
  value: string;
};

const defaultDayFormat = 'ddd';
type DayFormat = 'dd' | 'ddd' | 'dddd';

export const getWeekDays = function getWeekDays(
  firstWeekday: Weekday,
  format: DayFormat = defaultDayFormat,
): WeekDay[] {
  const weekDays: Weekday[] = [
    Weekday.SUNDAY,
    Weekday.MONDAY,
    Weekday.TUESDAY,
    Weekday.WEDNESDAY,
    Weekday.THURSDAY,
    Weekday.FRIDAY,
    Weekday.SATURDAY,
  ];

  if (firstWeekday !== undefined) {
    let weekday = weekDays[0];
    while (weekday !== firstWeekday) {
      const day = weekDays.shift() as Weekday;
      weekDays.push(day);
      // eslint-disable-next-line prefer-destructuring
      weekday = weekDays[0];
    }
  }

  return weekDays.map((day) => ({
    weekday: day,
    value: dayjs()
      .day(day)
      .format(format || 'ddd'),
  }));
};

const isEndNextDay = (startTime: string, endTime: string) =>
  !endTime || !startTime ? false : endTime < startTime;

export const formatWeekday = (weekday: Weekday, format: DayFormat = defaultDayFormat) =>
  dayjs().day(weekday).format(format);

export const isPast = (date: string, useTimeZone?: boolean | false) =>
  useTimeZone ? dayjs.tz(date).isBefore(dayjs.tz(), 'date') : dayjs(date).isBefore(dayjs(), 'date');

export function formatDate(
  date: string,
  format: DateFormats = 'YYYY-MM-DD',
  useTimeZone: boolean = false,
) {
  return useTimeZone ? dayjs.tz(date).format(format) : dayjs(date).format(format);
}

export function fromNow(date: string, useTimeZone: boolean = false) {
  return useTimeZone ? dayjs.tz(date).fromNow() : dayjs.utc(date).fromNow();
}

export function setDate(
  date: string,
  { addDays = 0, subtractDays = 0 }: { addDays?: number; subtractDays?: number },
  format: DateFormats = 'YYYY-MM-DD',
) {
  return dayjs(date).add(addDays, 'days').subtract(subtractDays, 'days').format(format);
}

export function now(useTimeZone: boolean = false, format: DateFormats = 'YYYY-MM-DD') {
  return useTimeZone ? dayjs.tz().format(format) : dayjs().format(format);
}

export const getTimeDuration = (startTime: string, endTime: string) => {
  // return total number of hours
  const HOUR_FORMAT = [
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD hh:mm:ss',
    'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD HH',
    'YYYY-MM-DD hh',
  ];
  const MIN_IN_HOUR = 60;
  const date = dayjs().format('YYYY-MM-DD');
  const start = dayjs(`${date} ${startTime.trim()}`, HOUR_FORMAT);
  let end = dayjs(`${date} ${endTime.trim()}`, HOUR_FORMAT);

  if (isEndNextDay(startTime, endTime)) {
    end = end.add(1, 'day');
  }

  const duration = dayjs.duration(end.diff(start, 'date'));
  const minute = Math.abs(duration.minutes());
  return Math.abs(duration.days() * 24) + Math.abs(duration.hours()) + minute / MIN_IN_HOUR;
};

export const getDateDuration = (startDate: string, endDate: string) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return dayjs.duration(end.diff(start));
};

export const getDate: (value: string, format?: DateFormats) => Dayjs = (
  value,
  format = 'YYYY-MM-DD',
) => dayjs(value, format);

export const convertDateToDayjs: (value: Date) => Dayjs = (value) => dayjs(value);

export const getLastWeekday: (firstWeekday?: Weekday) => Weekday = (firstWeekday = 0) =>
  firstWeekday !== 0 ? firstWeekday - 1 : 6;

export type MonthDay = {
  day: number;
  current: boolean;
  past: boolean;
  future: boolean;
  date: string;
};
export type MonthDays = MonthDay[];

export const getMonthDays: (args: {
  firstWeekday?: Weekday;
  startDate?: string;
  step?: number;
}) => { startDate: string; monthDays: MonthDays } = ({ firstWeekday = 0, step = 0, startDate }) => {
  const month: MonthDays = [];
  const DATE_FORMAT = 'YYYY-MM-DD';
  const monthDate = dayjs
    .utc(startDate)
    .add(step || 0, 'months')
    .startOf('month');

  const today = now(true, DATE_FORMAT);
  const lastWeekday = getLastWeekday(firstWeekday);

  for (let day = 1; day <= monthDate.daysInMonth(); day += 1) {
    const date = dayjs(monthDate).date(day).format(DATE_FORMAT);
    month.push({
      day,
      current: today === date,
      date,
      past: false,
      future: false,
    });
  }

  const firstDateInMonth = dayjs(month[0].date);
  const lastDateInMonth = dayjs(month[month.length - 1].date);

  const firstDayWeekDay = firstDateInMonth.get('day');
  const lastDayWeekDay = lastDateInMonth.get('day');

  if (firstDayWeekDay !== firstWeekday) {
    const diff = Math.abs(
      firstDayWeekDay === 0 ? 7 - firstWeekday : firstDayWeekDay - firstWeekday,
    );
    let leftDay = firstDateInMonth.add(-1, 'day');
    for (let leftDays = 0; leftDays < diff; leftDays += 1) {
      const leftDayDate = leftDay.format(DATE_FORMAT);
      month.unshift({
        day: leftDay.date(),
        past: true,
        date: leftDayDate,
        future: false,
        current: false,
      });
      leftDay = leftDay.add(-1, 'day');
    }
  }

  if (lastDayWeekDay !== lastWeekday) {
    const diff = Math.abs(lastWeekday === 0 ? 7 - lastDayWeekDay : lastWeekday - lastDayWeekDay);
    let rightDay = lastDateInMonth.add(1, 'day');
    for (let rightDays = 0; rightDays < diff; rightDays += 1) {
      const rightDayDate = rightDay.format(DATE_FORMAT);
      month.push({
        day: rightDay.date(),
        past: false,
        date: rightDayDate,
        future: true,
        current: false,
      });
      rightDay = rightDay.add(1, 'day');
    }
  }

  return {
    startDate: monthDate.format(DATE_FORMAT),
    monthDays: month,
  };
};

export const getWeekDay: (date: string, format?: DateFormats) => Weekday = (
  date,
  format = 'YYYY-MM-DD',
) => dayjs(date, format).get('day');

export const getWeek = (startDate: string) => {
  const weekdays = [];
  const WEEK_DAY_COUNT = 7;
  for (let index = 0; index < WEEK_DAY_COUNT; index += 1) {
    weekdays.push(dayjs(startDate).add(index, 'days'));
  }
  return weekdays;
};

export const getWeekdayName: (weekday: Weekday, format?: 'dddd' | 'dd' | 'ddd') => string = (
  weekday: Weekday,
  format = 'dddd',
) => dayjs.tz().set('day', weekday).format(format);

export const getTime: (
  time: string,
  hours12: boolean,
  shorten?: boolean,
) => { time: string | null; meridiem: string | null } = (
  time: string,
  hours12: boolean,
  shorten?: boolean,
) => {
  const TIME_FORMAT = [`YYYY-MM-DD HH:mm:ss`, `YYYY-MM-DD HH:mm`];
  const today = now(false, 'YYYY-MM-DD');

  if (!time) return { time: null, meridiem: null };
  let timeResult = dayjs(`${today} ${time}`, TIME_FORMAT).format(hours12 ? 'hh:mm' : 'HH:mm');
  if (shorten && timeResult.charAt(0) === '0') {
    timeResult = timeResult.substring(1);
  }
  return {
    time: timeResult,
    meridiem: hours12 ? dayjs(`${today} ${time}`, TIME_FORMAT).format('A') : null,
  };
};

export type ReportTimeFormat = {
  displayMinutesAfterDot?: boolean;
  roundToQuarter?: boolean;
  displayDecimalTime?: boolean;
};

const getTimeFormat = (
  milliseconds: number,
  options: ReportTimeFormat = {
    displayMinutesAfterDot: false,
    roundToQuarter: false,
    displayDecimalTime: false,
  },
) => {
  const time = [];
  const hrs = Math.floor(Math.abs(milliseconds) / 3600);
  const min = Math.floor((Math.abs(milliseconds) % 3600) / 60);
  if (options.roundToQuarter) {
    const minutesPerHour = Math.floor((min / 60) * 100) / 100;
    const roundedToLeastQuarter = Math.floor(minutesPerHour / 0.25) * 0.25;
    return (hrs + roundedToLeastQuarter).toFixed(2);
  }

  if (options.displayDecimalTime) {
    const minutesPerHour = Math.floor((min / 60) * 100) / 100;
    return (hrs + minutesPerHour).toFixed(2);
  }

  if (options.displayMinutesAfterDot) return `${hrs}.${`${min}`.padStart(2, '0')}`;
  if (hrs) time.push(`${hrs}h`);
  if (min) time.push(`${min}m`);
  if (!hrs && !min) return '0h';
  return time.join(' ');
};

const getDateTimeRangeOverlap = (
  time1: { start: string; end: string },
  time2: { start: string; end: string },
) => {
  const { start: start1, end: end1 } = time1;
  const { start: start2, end: end2 } = time2;
  const startIntersect = start2 <= start1 ? start1 : start2;
  const endInterSect = end2 > end1 ? end1 : end2;
  const isIntersect = startIntersect < endInterSect;
  if (isIntersect) {
    return dayjs(endInterSect).toDate().getTime() - dayjs(startIntersect).toDate().getTime();
  }
  return 0;
};

const getFirstWeekdayDate = () => dayjs.tz().startOf('week');

export default {
  getWeekDays,
  formatWeekday,
  isPast,
  fromNow,
  setDate,
  now,
  formatDate,
  getTimeDuration,
  getDate,
  getDateDuration,
  getMonthDays,
  getWeekDay,
  getWeek,
  convertDateToDayjs,
  getTime,
  getDateTimeRangeOverlap,
  getFirstWeekdayDate,
  isEndNextDay,
  getWeekdayName,
  getTimeFormat,
};

import { useMemo } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Links from './Links';
import useSettings from '../../hooks/useSettings';

function PrivateRoute({ requiredFeatures }: { requiredFeatures?: number[] }) {
  const { auth } = useAuth();
  const { branch_id: selectedBranchId } = useSettings();
  const location = useLocation();
  const isAuthenticated = !!auth.isAuthenticated;

  const allowedFeatures = useMemo(
    () => auth.userData?.roles?.find((role) => role.branch_id === selectedBranchId)?.features || [],
    [selectedBranchId, auth.userData?.roles],
  );

  const allRequiredFeaturesAllowed = useMemo(
    () => !!requiredFeatures?.every((feature) => allowedFeatures.includes(feature)),
    [allowedFeatures, requiredFeatures],
  );

  if (!isAuthenticated || !allRequiredFeaturesAllowed)
    return (
      <Navigate
        to={Links.LOGIN_LINK}
        state={{ from: allRequiredFeaturesAllowed ? location : undefined }}
        replace
      />
    );

  return <Outlet />;
}

PrivateRoute.defaultProps = {
  requiredFeatures: [],
};

export default PrivateRoute;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import {
  AddScheduleMethod,
  AddScheduleRequestData,
  AddScheduleResponse,
  AddShcheduleSuccessPayload,
  DeleteShcheduleSuccessPayload,
  UpdateScheduleRequestData,
} from '../../types/Schedule';
import utils from '../../shared/utils';

interface ActionType<T, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data?: T;
}

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
};

type AddScheduleData = {
  schedule: AddScheduleRequestData;
  roleId: number;
  method: AddScheduleMethod;
  branchId: number;
};

type UpdateScheduleData = {
  schedule: UpdateScheduleRequestData;
  roleId: number;
  branchId: number;
};

export interface InitialState {
  addSchedule: ActionType<AddScheduleData, ResponseSuccess<AddScheduleResponse>>;
  deleteSchedule: ActionType<DeleteShcheduleSuccessPayload, ResponseSuccess<boolean>>;
  updateSchedule: ActionType<UpdateScheduleData, ResponseSuccess<boolean>>;
}

const initialState: InitialState = {
  addSchedule: {
    ...ACTIONS_INITIAL_STATE,
  },
  deleteSchedule: {
    ...ACTIONS_INITIAL_STATE,
  },
  updateSchedule: {
    ...ACTIONS_INITIAL_STATE,
  },
};

export const manageSchedule = createSlice({
  name: 'manage/schedule',
  initialState,
  reducers: {
    addScheduleStarted: (state, action: PayloadAction<AddScheduleData>) => {
      state.addSchedule = {
        ...initialState.addSchedule,
        isLoading: true,
        data: action.payload,
      };
    },
    addScheduleSuccess: (state, action: PayloadAction<AddShcheduleSuccessPayload>) => {
      state.addSchedule.isLoading = false;
      state.addSchedule.response = action.payload.response;
    },
    addScheduleFail: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.addSchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addSchedule.error = action.payload;
      } else {
        state.addSchedule.response = action.payload;
      }
    },

    updateScheduleStarted: (state) => {
      state.updateSchedule = { ...initialState.updateSchedule, isLoading: true };
    },
    updateScheduleSuccess: (
      state,
      action: PayloadAction<{ data: UpdateScheduleData; response: ResponseSuccess<boolean> }>,
    ) => {
      state.updateSchedule.isLoading = false;
      state.updateSchedule.data = action.payload.data;
      state.updateSchedule.response = action.payload.response;
    },
    updateScheduleFail: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateSchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateSchedule.error = action.payload;
      } else {
        state.updateSchedule.response = action.payload;
      }
    },

    deleteScheduleStarted: (state) => {
      state.deleteSchedule = { ...initialState.deleteSchedule, isLoading: true };
    },
    deleteScheduleSuccess: (
      state,
      action: PayloadAction<{
        data: DeleteShcheduleSuccessPayload;
        response: ResponseSuccess<boolean>;
      }>,
    ) => {
      state.deleteSchedule.isLoading = false;
      state.deleteSchedule.data = action.payload.data;
      state.deleteSchedule.response = action.payload.response;
    },
    deleteScheduleFail: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.deleteSchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.deleteSchedule.error = action.payload;
      } else {
        state.deleteSchedule.response = action.payload;
      }
    },

    resetSlices: (state) => {
      state.addSchedule = { ...initialState.addSchedule };
      state.updateSchedule = { ...initialState.updateSchedule };
      state.deleteSchedule = { ...initialState.deleteSchedule };
    },
  },
});

export default manageSchedule.reducer;

export const {
  addScheduleFail,
  addScheduleStarted,
  addScheduleSuccess,

  updateScheduleFail,
  updateScheduleStarted,
  updateScheduleSuccess,

  deleteScheduleFail,
  deleteScheduleStarted,
  deleteScheduleSuccess,

  resetSlices,
} = manageSchedule.actions;

export const selectAddScheduleState = (state: State<InitialState>) =>
  state.manageScheduleSlice.addSchedule;

export const selectUpdateScheduleState = (state: State<InitialState>) =>
  state.manageScheduleSlice.updateSchedule;

export const selectDeleteScheduleState = (state: State<InitialState>) =>
  state.manageScheduleSlice.deleteSchedule;

import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_MULTI,
} from '../actionTypes/notifications';
import {
  updateNotifiationStarted,
  updateNotificationError,
  updateNotificationSuccess,
  fetchNotificationsError,
  fetchNotificationsStarted,
  fetchNotificationsSuccess,
  updateNotifiationMultiStarted,
  updateNotificationMultiError,
  updateNotificationMultiSuccess,
} from '../slices/notificationsSlice';
import { Response, ResponseSuccess } from '../../types/Response';
import {
  Notification,
  UpdateNotificationRequest,
  UpdateNotificationMultiRequest,
} from '../../types/Notifications';
import utils from '../../shared/utils';

function* updateNotifiation({ payload }: PayloadAction<UpdateNotificationRequest>) {
  try {
    yield put(updateNotifiationStarted());
    const res: Response<boolean> = yield call(() =>
      api.notifications.markNotifAs(payload.readStatus, payload.id),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(updateNotificationSuccess({ id: payload.id, response: res.data }));
    } else {
      yield put(updateNotificationError(res.data));
    }
  } catch (error) {
    yield put(updateNotificationError(utils.general.convertAxiosError(error)));
  }
}

function* updateNotificationMulti({ payload }: PayloadAction<UpdateNotificationMultiRequest>) {
  try {
    yield put(updateNotifiationMultiStarted());
    const { ids, readStatus } = payload;
    const requests = ids.map((id, index) => api.notifications.markNotifAs(readStatus[index], id));
    const res: Response<boolean>[] = yield all(requests);
    const successResponse = { response: [], ids: [], status: [] } as {
      response: ResponseSuccess<boolean>[];
      ids: number[];
      status: number[];
    };
    res.forEach((e, index) => {
      if (utils.general.isResponseSuccess(e.data)) {
        successResponse.ids.push(ids[index]);
        successResponse.response.push(e.data);
        successResponse.status.push(readStatus[index]);
      }
    });
    yield put(updateNotificationMultiSuccess(successResponse));
  } catch (error) {
    yield put(updateNotificationMultiError(utils.general.convertAxiosError(error)));
  }
}

function* getNofications({
  payload,
}: PayloadAction<{
  accountId: string;
  read: unknown;
  index: number;
  limit: number;
}>) {
  try {
    yield put(fetchNotificationsStarted());
    const res: Response<Notification[]> = yield call(() =>
      api.notifications.getNotificationList(payload.accountId, {
        read: payload.read,
        index: payload.index,
        limit: payload.limit,
      }),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(fetchNotificationsSuccess(res.data));
    } else {
      yield put(fetchNotificationsError(res.data));
    }
  } catch (error) {
    yield put(fetchNotificationsError(utils.general.convertAxiosError(error)));
  }
}

function* watchManageNotifications() {
  yield all([
    takeLatest(GET_NOTIFICATIONS, getNofications),
    takeLatest(UPDATE_NOTIFICATION, updateNotifiation),
    takeLatest(UPDATE_NOTIFICATION_MULTI, updateNotificationMulti),
  ]);
}

export default watchManageNotifications;

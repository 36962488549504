import './AppLoader.scss';

export default function AppLoader() {
  return (
    <div className="ipl-progress-indicator available">
      <div className="ipl-progress-indicator-head">
        <div className="first-indicator" />
        <div className="second-indicator" />
      </div>
      <div className="insp-logo-frame">
        <svg
          version="1.1"
          width="300"
          className="insp-logo-frame-img"
          height="300"
          id="Layer_1"
          xmlns="https://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 200 200"
          xmlSpace="preserve"
        >
          <ellipse
            transform="matrix(1 -1.368420e-03 1.368420e-03 1 -0.1367 0.1369)"
            className="st0"
            cx="100"
            cy="100"
            rx="94.2"
            ry="94.2"
          />
          <g id="XMLID_2_">
            <path
              id="XMLID_6_"
              className="st1"
              d="M163.2,99.3c0-2.7-2.2-4.9-5-4.9l-6.3,0l-34.5,0c-3.9,0-0.1-4.4,2.1-7.7l6.6-10
            c2.2-3.3,0.5-7.7-3.5-7.7h0c-1.7,0-3.2,0.8-4.1,2.2l-16.9,25.8c-1.1,1.7-1.1,3.8,0,5.4l18.2,27.7c0.9,1.4,2.5,2.2,4.2,2.2l0,0
            c3.9,0,4.9-4.4,2.8-7.7l-7.7-11.7c-2.2-3.3-5.3-7.7-1.4-7.7l28.5,0c3.2,0,5.6,3,4.8,6.1c-5.2,22.5-25.4,39.3-49.5,39.3
            c-12.4,0-23.8-4.4-32.6-11.8c-2-1.6-4.9-1.5-6.7,0.3l-0.8,0.8c-2.1,2.1-1.9,5.4,0.3,7.3c10.8,9,24.6,14.4,39.7,14.4
            c32.2,0,58.8-24.9,61.5-56.4c0.2-1.8,0.2-3.6,0.2-5.5C163.2,99.7,163.2,99.5,163.2,99.3z"
            />
            <path
              id="XMLID_8_"
              className="st2"
              d="M36.8,100.7c0,2.7,2.2,4.9,5,4.9l6.3,0l34.5,0c3.9,0,0.1,4.4-2.1,7.7l-6.6,10
            c-2.2,3.3-0.5,7.7,3.5,7.7h0c1.7,0,3.2-0.8,4.1-2.2l16.9-25.8c1.1-1.7,1.1-3.8,0-5.4L80.2,69.7c-0.9-1.4-2.5-2.2-4.2-2.2l0,0
            c-3.9,0-4.9,4.4-2.8,7.7L81,86.9c2.2,3.3,5.3,7.7,1.4,7.7l-28.5,0c-3.2,0-5.6-3-4.8-6.1C54.3,66,74.5,49.2,98.5,49.2
            c12.4,0,23.8,4.4,32.6,11.8c2,1.6,4.9,1.5,6.7-0.3l0.8-0.8c2.1-2.1,1.9-5.4-0.3-7.3c-10.8-9-24.6-14.4-39.7-14.4
            c-32.2,0-58.8,24.9-61.5,56.4c-0.2,1.8-0.2,3.6-0.2,5.5C36.8,100.3,36.8,100.5,36.8,100.7z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}

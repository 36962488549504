export const onlyNumbers = (
  evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  numOfDecimalDigits?: number,
) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  const { value } = evt.currentTarget;
  if (!numOfDecimalDigits && charCode === 46) evt.preventDefault();
  const containsDot = value.indexOf('.') !== -1;
  if (containsDot && charCode === 46) evt.preventDefault();
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) evt.preventDefault();
  if (numOfDecimalDigits !== undefined && containsDot) {
    const dotIndex = value.indexOf('.');
    const digistAfterDot = value.substr(dotIndex + 1).length;
    if (digistAfterDot >= numOfDecimalDigits) evt.preventDefault();
  }
};

export const normalizeEmail = (value?: string) =>
  value
    ?.replace(/\s+/g, ' ')
    .trim()
    .replace(/\w+/g, (c: string) => c.toLowerCase());

export const capitalizeFirstLetter = (value?: string) =>
  value?.length === 1 ? value?.replace(/^\w/, (c: string) => c.toUpperCase()) : value;

export default {
  onlyNumbers,
  normalizeEmail,
  capitalizeFirstLetter,
};

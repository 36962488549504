import { Config } from '../../config';
import { SalesProjectionMethod } from '../../enums';
import { RoleData, UserData } from '../../types/Authentications';
import { roleData } from '../AuthContext/utility';

export interface UserRoleSettings extends Omit<RoleData, 'features'> {
  features: {
    allowOpenShift: boolean;
    allowExchangeRequest: boolean;
    allowOffDay: boolean;
    allowDailySchedule: boolean;
    allowExportExcel: boolean;
    allowReporting: boolean;
    allowPunching: boolean;
    allowSales: boolean;
    allowTemporaryEmployee: boolean;
  };
}

export const Settings: UserRoleSettings = {
  ...roleData,
  features: {
    allowOpenShift: false,
    allowExchangeRequest: false,
    allowOffDay: false,
    allowDailySchedule: false,
    allowExportExcel: false,
    allowReporting: false,
    allowPunching: false,
    allowSales: false,
    allowTemporaryEmployee: false,
  },
};

export const getSettings = (userData: UserData, branchId: number): UserRoleSettings => {
  const role = userData.roles.find((item) => item.branch_id === branchId);
  return role
    ? {
        ...Settings,
        branch_id: branchId,
        branch_name: role.branch_name,
        branch_settings: { ...role?.branch_settings },
        branch_timezone: role.branch_timezone,
        company_id: role.company_id,
        country_id: role.country_id,
        country_isd: role.country_isd,
        country_iso: role.country_iso,
        currency_id: role.currency_id,
        currency_symbol: role.currency_symbol,
        employee_code: role.employee_code,
        features: {
          allowOpenShift: !!role.features.find((item) => item === Config.FEATURES_ID.OPEN_SHIFT),
          allowExchangeRequest: !!role.features.find(
            (item) => item === Config.FEATURES_ID.EXCHANGE_REQUEST,
          ),
          allowOffDay: !!role.features.find((item) => item === Config.FEATURES_ID.OFF_DAY),
          allowDailySchedule: !!role.features.find(
            (item) => item === Config.FEATURES_ID.DAILY_SCHEDULE,
          ),
          allowExportExcel: !!role.features.find(
            (item) => item === Config.FEATURES_ID.EXPORT_EXCEL,
          ),
          allowReporting: !!role.features.find((item) => item === Config.FEATURES_ID.REPORTING),
          allowPunching: !!role.features.find((item) => item === Config.FEATURES_ID.PUNCHING),
          allowSales: !!role.features.find((item) => item === Config.FEATURES_ID.SALES),
          allowTemporaryEmployee: !!role.features.find(
            (item) => item === Config.FEATURES_ID.TEMPORARY_EMPLOYEE,
          ),
        },
        first_weekday: role.first_weekday,
        is_12hr: role.is_12hr,
        is_temporary_position: role.is_temporary_position,
        permissions: [...role.permissions],
        role_id: role.role_id,
        sales_percentage_cap: role.sales_percentage_cap,
        sales_projection_computation_method_id: role.sales_projection_computation_method_id,
      }
    : Settings;
};

export interface ContextType extends UserRoleSettings {
  changeBranch: (branchId: number) => void;
  changeCurrency: (currencyId: number, currencySymbol: string) => void;
  changeHourFormat: (hourFormat: number) => void;
  changeProjectedSalesMethod: (projection_computation_method_id: SalesProjectionMethod) => void;
  changeSalesPercentageCap: (percentage_cap: number) => void;
}

export const contextTypes: ContextType = {
  ...Settings,
  changeBranch() {
    throw new Error('Function not implemented.');
  },
  changeCurrency(): string {
    throw new Error('Function not implemented.');
  },
  changeHourFormat(): string {
    throw new Error('Function not implemented.');
  },
  changeProjectedSalesMethod(): string {
    throw new Error('Function not implemented.');
  },
  changeSalesPercentageCap(): string {
    throw new Error('Function not implemented.');
  },
};

export default Settings;

const TIPS = {
  SHOW_REPORT_EXCEL_TIP: 'SHOW_REPORT_EXCEL_TIP',
  SHOW_COPY_FROM_TIP: 'SHOW_COPY_FROM_TIP',
  SHOW_REMOVE_MULTI_SHIFTS_TIP: 'SHOW_REMOVE_MULTI_SHIFTS_TIP',
  SHOW_EDIT_MULTI_SHIFTS_TIME_TIP: 'SHOW_EDIT_MULTI_SHIFTS_TIME_TIP',
  SHOW_ADD_MULTI_PUNCH_TIP: 'SHOW_ADD_MULTI_PUNCH_TIP',
};

const SHOW_HIDE_SETTINGS = {
  SHOW_SHIFT_REQUIREMENTS: 'SHOW_SHIFT_REQUIREMENTS',
};

const USER_DATA = {
  SCHEDEX_USER_DATA: 'SCHEDEX_USER_DATA',
  SCHEDEX_USER_EMAIL: 'SCHEDEX_USER_EMAIL',
  SCHEDEX_USER_ID: 'SCHEDEX_USER_ID',
  SETTINGS: 'SETTINGS',
};

const SELECTED_BRANCH = {
  getUserSelectedBranch: (userId: number) => `SCHEDEX_SELECTED_BRANCH_ID_USER_${userId}`,
  SCHEDEX_SELECTED_BRANCH_ID: 'SCHEDEX_SELECTED_BRANCH_ID',
};

const ATTENDANCE_REPORT = {
  REPORT_DATE_RANGE: 'REPORT_DATE_RANGE',
  REPORT_SELECTED_BRANCHES: 'REPORT_SELECTED_BRANCHES',
  REPORT_PUNCH_FILTER: 'REPORT_PUNCH_FILTER',
  REPORT_SELECTED_COLUMNS: 'REPORT_SELECTED_COLUMNS',
};

const APP_REFRESHED = 'refreshed';

export default {
  TIPS,
  USER_DATA,
  SELECTED_BRANCH,
  SHOW_HIDE_SETTINGS,
  ATTENDANCE_REPORT,
  APP_REFRESHED,
};

import account from './account';
import components from './components';
import data from './data';
import punches from './punches';
import role from './roles';
import tentativePunches from './tentativePunches';

export default {
  account,
  components,
  data,
  punches,
  role,
  tentativePunches,
};

import _orderBy from 'lodash/orderBy';

type SortPositionType = {
  index: number;
  name: string;
};

type SortPositionsIterates = ['index'] | ['name'] | ['index', 'name'];

const sortPositions: <T extends SortPositionType>(
  positions: T[],
  iterates?: SortPositionsIterates,
) => T[] = (positions, iterates = ['index', 'name']) => {
  if (!positions?.length) return positions;

  return _orderBy(
    positions,
    iterates.filter((key) => key in positions[0]),
  );
};

type SortEmployeeype = {
  first_name: string;
  last_name?: string;
};

type SortEmployeesIterates = ['first_name'] | ['last_name'] | ['first_name', 'last_name'];

const sortEmployees: <T extends SortEmployeeype>(
  employees: T[],
  iterates?: SortEmployeesIterates,
) => T[] = (employees, iterates = ['first_name', 'last_name']) => {
  if (!employees?.length) return employees;

  return _orderBy(
    employees,
    iterates.filter((key) => key in employees[0]),
  );
};

export default {
  sortPositions,
  sortEmployees,
};

import { useState, useCallback, forwardRef } from 'react';
import MUISelect, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { usePrevious } from '../../../hooks';
import { SelectOption } from '../../../types/Shared';
import './SelectField.scss';

export interface MuiSelectProps extends Omit<SelectProps, 'onChange'> {
  menuItems: SelectOption[];
  showErrorText?: boolean;
  onChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  openOnFocus?: boolean;
  onItemClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent> | undefined) => void;
  errorMessage?: string;
}

const MuiSelect = forwardRef(function MuiSelect(props: MuiSelectProps, ref) {
  const [open, setOpen] = useState(false);
  const prevOpenState = usePrevious(open);

  const {
    onChange,
    error,
    label,
    MenuProps,
    showErrorText,
    menuItems,
    variant,
    fullWidth,
    openOnFocus,
    onItemClick,
    errorMessage,
    ...rest
  } = props;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
      onChange(event, child);
      setOpen(false);
    },
    [onChange],
  );

  return (
    <FormControl className="select-field-root" error={!!error} fullWidth={fullWidth}>
      {label && !props.value && (
        <InputLabel shrink={false} id="select-field-label">
          {label}
        </InputLabel>
      )}

      <MUISelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        defaultValue=""
        labelId="select-field-label"
        displayEmpty
        MenuProps={MenuProps}
        variant={variant}
        onChange={handleChange}
        size="small"
        IconComponent={ExpandMoreIcon}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        inputRef={ref}
        onFocus={(e) => {
          rest.onFocus?.(e);
          if (openOnFocus && !open) {
            setOpen(!prevOpenState);
          }
        }}
        onBlur={(e) => {
          rest.onBlur?.(e);
          if (openOnFocus) handleClose();
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            data-value={item.value}
            onClick={onItemClick}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </MUISelect>
      {error ? <FormHelperText>{errorMessage}</FormHelperText> : ''}
    </FormControl>
  );
});

MuiSelect.defaultProps = {
  showErrorText: false,
  openOnFocus: false,
  onItemClick: () => {},
  errorMessage: '',
};

export default MuiSelect;

import { put, takeLatest, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { actions } from '../slices/openShifts';
import actionTypes from '../actionTypes/openShifts';
import utils from '../../shared/utils';
import { Response } from '../../types/Response';
import {
  AddOpenShiftData,
  AddOpenShiftResponse,
  BulkApproveOpenShiftEmployeesData,
  DeleteOpenShiftData,
  EmployeesUnAvailability,
  NotifyOpenShiftEmployeeData,
  UpdateOpenShiftData,
} from '../../types/OpenShift';

function* addOpenShift({ payload }: PayloadAction<{ branchId: number; data: AddOpenShiftData }>) {
  try {
    yield put(actions.addOpenShiftStarted(payload.data));
    const response: Response<AddOpenShiftResponse> = yield api.openShift.postOpenShift(
      payload.branchId,
      utils.openShift.getAddOpenShiftRequestData(payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(actions.addOpenShiftSuccess({ response: response.data, data: payload.data }));
    } else {
      yield put(actions.addOpenShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.addOpenShiftError(utils.general.convertAxiosError(error)));
  }
}

function* updateOpenShift({
  payload,
}: PayloadAction<{ branchId: number; data: UpdateOpenShiftData }>) {
  try {
    yield put(actions.updateOpenShiftStarted(payload.data));
    const response: Response<number> = yield api.openShift.putOpenShift(
      payload.branchId,
      payload.data.open_shift_request_id,
      utils.openShift.getUpdateOpenShiftRequestData(payload.data),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(actions.updateOpenShiftSuccess({ response: response.data, data: payload.data }));
    } else {
      yield put(actions.updateOpenShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.updateOpenShiftError(utils.general.convertAxiosError(error)));
  }
}

function* deleteOpenShift({
  payload,
}: PayloadAction<{ branchId: number; data: DeleteOpenShiftData }>) {
  try {
    yield put(actions.deleteOpenShiftStarted());
    const response: Response<number> = yield api.openShift.deleteOpenShift(
      payload.branchId,
      payload.data.open_shift_request_id,
      payload.data.keep_accepted,
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        actions.deleteOpenShiftSuccess({
          response: response.data,
          openShiftId: payload.data.open_shift_request_id,
          date: payload.data.date,
        }),
      );
    } else {
      yield put(actions.deleteOpenShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.deleteOpenShiftError(utils.general.convertAxiosError(error)));
  }
}

function* notifyOpenShiftEmployees({
  payload,
}: PayloadAction<{
  branchId: number;
  data: NotifyOpenShiftEmployeeData;
}>) {
  try {
    yield put(actions.notifyEmployeesStarted(payload.data));
    const response: Response<string[]> = yield api.openShift.notifyOpenShiftEmployees(
      payload.branchId,
      payload.data.open_shift_request_id,
      payload.data.notify_rejected,
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(actions.notifyEmployeesSuccess(response.data));
    } else {
      yield put(actions.notifyEmployeesError(response.data));
    }
  } catch (error) {
    yield put(actions.notifyEmployeesError(utils.general.convertAxiosError(error)));
  }
}

function* getEmployeesUnavailability({
  payload,
}: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(actions.fetchEmployeesUnAvailabilityStarted());
    const response: Response<EmployeesUnAvailability> =
      yield api.employee.getEmployeeUnavailabilities(payload.branchId, payload.date);
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(actions.fetchEmployeesUnAvailabilitySuccess(response.data));
    } else {
      yield put(actions.fetchEmployeesUnAvailabilityError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchEmployeesUnAvailabilityError(utils.general.convertAxiosError(error)));
  }
}

function* approveOpenShiftEmployees({
  payload,
}: PayloadAction<{ data: BulkApproveOpenShiftEmployeesData }>) {
  try {
    yield put(actions.approveEmployeesStarted(payload.data));
    const response: Response<boolean> = yield api.openShift.bulkApproveOpenShiftEmployees(
      payload.data.account_id,
      payload.data.open_shift_request_id,
      {
        approve: payload.data.approve,
        unapprove: payload.data.unapprove,
      },
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(actions.approveEmployeesSuccess({ data: payload.data, response: response.data }));
    } else {
      yield put(actions.approveEmployeesError(response.data));
    }
  } catch (error) {
    yield put(actions.approveEmployeesError(utils.general.convertAxiosError(error)));
  }
}

function* watchOpenShifts() {
  yield all([
    takeLatest(actionTypes.ADD_OPEN_SHIFT, addOpenShift),
    takeLatest(actionTypes.UPDATE_OPEN_SHIFT, updateOpenShift),
    takeLatest(actionTypes.DELETE_OPEN_SHIFT, deleteOpenShift),
    takeLatest(actionTypes.NOTIFY_EMPLOYEES_ON_OPEN_SHIFT, notifyOpenShiftEmployees),
    takeLatest(actionTypes.GET_EMPLOYEES_UNAVAILABILITIES, getEmployeesUnavailability),
    takeLatest(actionTypes.BULK_APPROVE_EMPLOYEES_ON_OPEN_SHIFT, approveOpenShiftEmployees),
  ]);
}

export default watchOpenShifts;

import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { reportSliceActions } from '../slices/reporting';
import ActionTypes from '../actionTypes/reporting';
import { GenericResponse, Response } from '../../types/Response';
import utils from '../../shared/utils';
import { WeekShifts } from '../../types/Shift';
import {
  AttendanceReportResponse,
  AccountAttendanceReportRequest,
  AttendanceReportRequest,
  SummaryReportResponse,
} from '../../api/reporting';
import { AddMultiPunchRequest, AddMultiPunchResponseErrorData } from '../../api/punch';

function* getAttendanceReport({
  payload,
}: PayloadAction<{
  managerId: number;
  data: AttendanceReportRequest;
}>) {
  try {
    yield put(reportSliceActions.fetchAttendanceReportStarted());
    const res: Response<AttendanceReportResponse> = yield call(() =>
      api.reporting.getAttendanceReport(payload.managerId, payload.data),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        reportSliceActions.fetchAttendanceReportSuccess({
          response: {
            ...res.data,
            result: { ...res.data.result, employees: res.data.result.employees || [] },
          },
          start_date: payload.data.start_date,
          end_date: payload.data.end_date,
          branch_ids: payload.data.branch_ids,
        }),
      );
    } else {
      yield put(reportSliceActions.fetchAttendanceReportError(res.data));
    }
  } catch (error) {
    yield put(
      reportSliceActions.fetchAttendanceReportError(utils.general.convertAxiosError(error)),
    );
  }
}

function* getAccountAttendanceReport({
  payload,
}: PayloadAction<{
  managerId: number;
  data: AccountAttendanceReportRequest;
}>) {
  try {
    yield put(reportSliceActions.fetchAccountAttendanceReportStarted(payload.data));
    const res: Response<AttendanceReportResponse> = yield call(() =>
      api.reporting.getAttendanceReport(payload.managerId, payload.data),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        reportSliceActions.fetchAccountAttendanceReportSuccess({
          response: {
            ...res.data,
            result: { ...res.data.result, employees: res.data.result.employees || [] },
          },
          data: payload.data,
        }),
      );
    } else {
      yield put(reportSliceActions.fetchAccountAttendanceReportError(res.data));
    }
  } catch (error) {
    yield put(
      reportSliceActions.fetchAccountAttendanceReportError(utils.general.convertAxiosError(error)),
    );
  }
}

function* getShifts({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(
      reportSliceActions.fetchShiftsStarted({ branchId: payload.branchId, date: payload.date }),
    );
    const res: Response<WeekShifts[]> = yield call(() =>
      api.shifts.getShifts(payload.branchId, payload.date),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        reportSliceActions.fetchShiftsSuccess({
          response: res.data,
          branchId: payload.branchId,
          date: payload.date,
        }),
      );
    } else {
      yield put(reportSliceActions.fetchShiftsError(res.data));
    }
  } catch (error) {
    yield put(reportSliceActions.fetchShiftsError(utils.general.convertAxiosError(error)));
  }
}

function* submitTentativePunches({
  payload,
}: PayloadAction<{
  branchId: number;
  managerId: number;
  data: AddMultiPunchRequest;
}>) {
  try {
    yield put(reportSliceActions.submitTentativePunchesStarted(payload.data));
    const res: GenericResponse<true, AddMultiPunchResponseErrorData> = yield call(() =>
      api.punch.addMultiPunch(payload.branchId, payload.managerId, payload.data),
    );
    if (utils.general.isGenericResponseSuccess(res.data)) {
      yield put(reportSliceActions.submitTentativePunchesSuccess(res.data));
    } else {
      yield put(reportSliceActions.submitTentativePunchesError(res.data));
    }
  } catch (error) {
    yield put(
      reportSliceActions.submitTentativePunchesError(utils.general.convertAxiosError(error)),
    );
  }
}

function* getSummaryReport({
  payload,
}: PayloadAction<{
  managerId: number;
  data: AttendanceReportRequest;
}>) {
  try {
    yield put(reportSliceActions.fetchSummaryReportStarted());
    const res: Response<SummaryReportResponse> = yield call(() =>
      api.reporting.getSummaryReport(payload.managerId, payload.data),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        reportSliceActions.fetchSummaryReportSuccess({
          response: res.data,
          start_date: payload.data.start_date,
          end_date: payload.data.end_date,
          branch_ids: payload.data.branch_ids,
        }),
      );
    } else {
      yield put(reportSliceActions.fetchSummaryReportError(res.data));
    }
  } catch (error) {
    yield put(reportSliceActions.fetchSummaryReportError(utils.general.convertAxiosError(error)));
  }
}

function* watchManageAttendaceReport() {
  yield all([takeLatest(ActionTypes.GET_ATTENDACE_REPORT, getAttendanceReport)]);
  yield all([takeLatest(ActionTypes.GET_ACCOUNT_ATTENDACE_REPORT, getAccountAttendanceReport)]);
  yield all([takeLatest(ActionTypes.GET_SHIFT, getShifts)]);
  yield all([takeLatest(ActionTypes.SUBMIT_TENTATIVE_PUNCHES, submitTentativePunches)]);
  yield all([takeLatest(ActionTypes.GET_SUMMARY_REPORT, getSummaryReport)]);
}

export default watchManageAttendaceReport;

import { FC } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import './BasicTimeLine.scss';

interface BasicTimeLineEntriesProps {
  data: { element: JSX.Element; id: number }[];
  className?: string;
}

const BasicTimeline: FC<BasicTimeLineEntriesProps> = function BasicTimeline(props) {
  const { className, data } = props;

  return (
    <Timeline className={`Mui-Timeline ${className}`} position="left">
      {data.map((item, index) => (
        <TimelineItem key={item.id}>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            {index < data.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>{item.element}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

BasicTimeline.defaultProps = {
  className: '',
};

export default BasicTimeline;

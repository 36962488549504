import api from './axios';
import { Response } from '../types/Response';

const getNotificationList = (
  accountId: string,
  data: {
    read: unknown;
    index: number;
    limit: number;
  },
) =>
  api.post(`notification/account/${accountId}/notification_history`, data) as Promise<
    Response<Notification[]>
  >;
const markNotifAs = (readStatus: number, id: number) =>
  api.put(`notification/${id}/mark/${readStatus}`) as Promise<Response<boolean>>;

export default {
  getNotificationList,
  markNotifAs,
};

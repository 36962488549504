/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import _omit from 'lodash/omit';
import findLastIndex from 'lodash/findLastIndex';
import { EditPunchPayload, PunchHistory } from '../../../types/Reporting';
import dateUtils from '../date';
import shiftUtils from '../shift';
import {
  AttendanceReportResponse,
  Employee,
  AttendanceReportShift,
  Punch,
} from '../../../api/reporting';
import { WeekShifts } from '../../../types/Shift';
import general from '../general';

export type ShiftError = {
  noShow: string | null;
  punchWithNoShift: string | null;
  earlyPunchIn: string | null;
  latePunchIn: string | null;
  earlyPunchOut: string | null;
  latePunchOut: string | null;
};

export type PunchesFromShifts = {
  out?: { value: string; in_value?: string };
  in?: { value: string };
  shift: {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
  };
};

type RowPositionShifts = {
  id: number;
  start_time: string;
  end_time: string;
  break_time: string | number | null;
  note: string | null;
  error: ShiftError;
  name: string;
  custom_payment?: {
    payment_type_id: number;
  };
};

export type MissingPunchPair = {
  out?: {
    value: string;
    overlap?: boolean;
    message?: string;
    note?: string | null;
    isNoPunchOut?: boolean;
    id?: number;
  };
  in?: {
    value: string;
    overlap?: boolean;
    message?: string;
    note: null | string;
    isNoPunchOut?: boolean;
    id?: number;
  };
  shift: {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
  };
  type?: string;
  isNoPunchOut?: boolean;
  punchInValue?: string | undefined;
  shiftTime?: { start_time: string | undefined; end_time: string | undefined };
};

export type AccountMissingPunches = {
  [date: string]: {
    [branchId: string]: {
      [positionId: string]: MissingPunchPair[];
    };
  };
};

export type MissingPunches = {
  [key: string]: AccountMissingPunches;
};

type NoPunchOut = {
  isNoPunchOut: boolean;
  value: string;
  punchInValue: string | null;
  id: number;
  shift: {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
  };
} | null;

interface MissingPunchShift extends AttendanceReportShift {
  shiftInterval: {
    startTime: string;
    endTime: string;
  };
}

const undoSelectedPunches = (
  selected: MissingPunches,
  history: PunchHistory,
  missing: MissingPunches,
  tentative: MissingPunches,
) => {
  const tentativePunches = general.cloneData(tentative);
  const selectedPunches = general.cloneData(selected);
  let selectedPunchesHistory = general.cloneData(history);
  const missingPunches = general.cloneData(missing);

  const lastAction = selectedPunchesHistory.pop();
  if (lastAction?.added) {
    const data = lastAction.added;
    data.forEach((punch) => {
      const { accountId, date, positionId, branchId } = punch;
      if (selectedPunches[accountId]) {
        if (selectedPunches?.[accountId]?.[date]?.[branchId]?.[positionId]) {
          delete selectedPunches?.[accountId]?.[date]?.[branchId]?.[positionId];
          if (Object.keys(selectedPunches?.[accountId]?.[date]?.[branchId] || {}).length === 0) {
            delete selectedPunches?.[accountId]?.[date]?.[branchId];
          }

          if (Object.keys(selectedPunches?.[accountId]?.[date] || {}).length === 0) {
            delete selectedPunches?.[accountId]?.[date];
          }

          if (Object.keys(selectedPunches?.[accountId] || {}).length === 0) {
            delete selectedPunches?.[accountId];
          }
        }
      }
    });
  } else if (lastAction?.removed) {
    const data = lastAction.removed;
    data.forEach((punch) => {
      const { accountId, date, positionId, branchId } = punch;
      const removedMissingPunch = missingPunches?.[accountId]?.[date]?.[branchId]?.[positionId];
      if (removedMissingPunch?.length) {
        selectedPunches[accountId] = { ...selectedPunches[accountId] };
        selectedPunches[accountId][date] = { ...selectedPunches[accountId]?.[date] };
        selectedPunches[accountId][date][branchId] = {
          ...selectedPunches[accountId]?.[date]?.[branchId],
        };
        selectedPunches[accountId][date][branchId][positionId] = removedMissingPunch;
      }
    });
  } else if (lastAction?.add_tentative) {
    const data = lastAction.add_tentative;
    data.forEach((punch) => {
      const { accountId, date, branchId, positionId } = punch;
      if (
        tentativePunches?.[accountId]?.[date]?.[branchId]?.[positionId] &&
        missingPunches?.[accountId]?.[date]?.[branchId]?.[positionId]
      ) {
        selectedPunches[accountId] = { ...(selectedPunches[accountId] || {}) };
        selectedPunches[accountId][date] = { ...(selectedPunches[accountId]?.[date] || {}) };
        selectedPunches[accountId][date][branchId] = {
          ...(selectedPunches[accountId]?.[date]?.[branchId] || {}),
        };
        selectedPunches[accountId][date][branchId][positionId] = [
          ...(selectedPunches[accountId][date][branchId][positionId] || []),
        ];
        const punches = general.cloneData(missingPunches[accountId][date][branchId][positionId]);
        selectedPunches[accountId][date][branchId][positionId].push(...punches);

        delete tentativePunches[accountId][date][branchId][positionId];
        if (Object.keys(tentativePunches[accountId][date][branchId]).length === 0)
          delete tentativePunches[accountId][date][branchId];
        if (Object.keys(tentativePunches[accountId][date]).length === 0)
          delete tentativePunches[accountId][date];
        if (Object.keys(tentativePunches[accountId]).length === 0)
          delete tentativePunches[accountId];
      }
    });
  }
  if (selectedPunchesHistory.length === 1) selectedPunchesHistory = [];

  return {
    tentativePunches,
    selectedPunches,
    selectedPunchesHistory,
    missingPunches,
  };
};

const selectAllMissingPunches = (
  selectedPunches: MissingPunches,
  missingPunches: MissingPunches,
  tentativePunches: MissingPunches,
  employeeList: string[],
) => {
  const result: MissingPunches = {};
  const addedPunchesHistory: {
    accountId: number;
    branchId: number;
    date: string;
    positionId: number;
  }[] = [];
  employeeList.forEach((accountId) => {
    const punches = missingPunches[accountId];
    const missingPunchesDates = Object.keys(punches);

    if (missingPunchesDates.length) {
      missingPunchesDates.forEach((date) => {
        Object.keys(punches[date] || {}).forEach((branchId) => {
          Object.keys(punches[date][branchId] || {}).forEach((positionId) => {
            const positionMissingPunches = punches[date][branchId][positionId];
            if (
              positionMissingPunches?.length &&
              !tentativePunches?.[accountId]?.[date]?.[branchId]?.[positionId]
            ) {
              if (!selectedPunches?.[accountId]?.[date]?.[branchId]?.[positionId]?.length) {
                addedPunchesHistory.push({
                  date,
                  positionId: Number(positionId),
                  branchId: Number(branchId),
                  accountId: +accountId,
                });
              }
              result[accountId] = result[accountId] ?? {};
              result[accountId][date] = { ...result[accountId]?.[date] };
              result[accountId][date][branchId] = { ...result[accountId]?.[date]?.[branchId] };
              result[accountId][date][branchId][positionId] = positionMissingPunches;
            }
          });
        });
      });
    }
  });
  return { selectedPunches: result, addedPunches: addedPunchesHistory };
};

const unSelectEmployeePunches = (selectedPunches: MissingPunches, accountId: number) => {
  if (selectedPunches[accountId]) delete selectedPunches[accountId];
};

const convertSelectedPunchesToArray = (punches: MissingPunches, accountId: number) => {
  const result: { date: string; branchId: number; positionId: number; accountId: number }[] = [];
  Object.keys(punches?.[accountId] || {}).forEach((date) => {
    Object.keys(punches[accountId][date] || {}).forEach((branchId) => {
      Object.keys(punches[accountId][date][branchId] || {}).forEach((positionId) => {
        result.push({
          accountId,
          date,
          branchId: Number(branchId),
          positionId: Number(positionId),
        });
      });
    });
  });
  return result;
};

const selectEmployeePunches = (
  selectedPunches: MissingPunches,
  missingPunches: MissingPunches,
  tentativePunches: MissingPunches,
  accountId: number,
) => {
  const addedPunchesHistory: {
    accountId: number;
    branchId: number;
    date: string;
    positionId: number;
  }[] = [];
  const clonedData = general.cloneData(selectedPunches);
  if (clonedData[accountId]) delete clonedData[accountId];
  Object.keys(missingPunches?.[accountId] || {}).forEach((date) => {
    Object.keys(missingPunches[accountId][date] || {}).forEach((branchId) => {
      Object.keys(missingPunches[accountId][date][branchId] || {}).forEach((positionId) => {
        const punches = missingPunches?.[accountId]?.[date]?.[branchId]?.[positionId];
        if (!tentativePunches?.[accountId]?.[date]?.[branchId]?.[positionId] && punches?.length) {
          addedPunchesHistory.push({
            accountId,
            branchId: Number(branchId),
            date,
            positionId: Number(positionId),
          });
          clonedData[accountId] = { ...clonedData[accountId] };
          clonedData[accountId][date] = { ...clonedData[accountId][date] };
          clonedData[accountId][date][branchId] = {
            ...clonedData[accountId][date][branchId],
          };
          clonedData[accountId][date][branchId][positionId] = punches;
        }
      });
    });
  });
  return { selectedPunches: clonedData, addedPunchesHistory };
};

export type PunchHistoryAction = 'added' | 'removed' | 'add_tentative';

const addSelectedPunchesHistory = (
  data: PunchHistory,
  punches: {
    accountId: number;
    branchId: number;
    date: string;
    positionId: number;
  }[],
  action: PunchHistoryAction,
) => {
  data.push({
    [action]: punches.map((punch) => ({
      date: punch.date,
      positionId: punch.positionId,
      branchId: punch.branchId,
      accountId: punch.accountId,
    })),
  });
};

const uselectPunch = (
  selectedPunches: MissingPunches,
  punch: {
    accountId: number;
    branchId: number;
    date: string;
    positionId: number;
  },
) => {
  const { accountId, positionId, branchId, date } = punch;
  if (selectedPunches?.[accountId]?.[date]?.[branchId]?.[positionId]) {
    selectedPunches[accountId] = { ...selectedPunches[accountId] };
    delete selectedPunches?.[accountId]?.[date]?.[branchId]?.[positionId];
  }
  if (!Object.keys(selectedPunches?.[accountId]?.[date]?.[branchId] || {}).length)
    delete selectedPunches?.[accountId]?.[date]?.[branchId];
  if (!Object.keys(selectedPunches?.[accountId]?.[date] || {}).length)
    delete selectedPunches?.[accountId]?.[date];
  if (!Object.keys(selectedPunches?.[accountId] || {}).length) delete selectedPunches?.[accountId];
};

const selectPunch = (
  selectedPunches: MissingPunches,
  missingPunches: MissingPunches,
  payload: {
    accountId: number;
    branchId: number;
    date: string;
    positionId: number;
  },
) => {
  const { accountId, positionId, branchId, date } = payload;
  const selectedPunchData = missingPunches?.[accountId]?.[date]?.[branchId]?.[positionId];
  if (selectedPunchData?.length) {
    if (!selectedPunches[accountId]) selectedPunches[accountId] = {};
    if (!selectedPunches[accountId][date]) selectedPunches[accountId][date] = {};
    if (!selectedPunches[accountId][date][branchId])
      selectedPunches[accountId][date][branchId] = {};
    if (!selectedPunches[accountId][date][branchId][positionId])
      selectedPunches[accountId][date][branchId][positionId] = selectedPunchData;
  }
};

const getNoPunchOutFromShifts: (shifts: MissingPunchShift[], punches: Punch[]) => NoPunchOut = (
  shifts,
  punches,
) => {
  const punch = punches.find((_punch) => !!_punch.out && _punch.out.date === null);
  if (punch?.out) {
    const shift = shifts.find((_shift) => _shift.shiftInterval?.endTime > punch.in.date);
    if (shift)
      return {
        isNoPunchOut: true,
        value: shift.shiftInterval.endTime,
        punchInValue: punch.in.date,
        id: punch.out.id,
        shift: {
          id: shift.id,
          name: shift.name,
          start_time: shift.start_time,
          end_time: shift.end_time,
        },
      };
  }
  return null;
};

const getSuggestedNoPunchOutTime = (
  shifts: RowPositionShifts[],
  date: string,
  punchPair?: Punch,
) => {
  if (!punchPair) return date;

  const regularShifts = shifts
    .map((shift) => {
      const shiftInterval = shiftUtils.getShiftIntervalDateTime(
        date,
        shift.start_time,
        shift.end_time,
      );
      return { ...shift, ...shiftInterval };
    })
    .filter((shift) => shift.start_time !== shift.end_time && punchPair.in?.date < shift.endTime)
    .sort((a, b) => a.start_time.localeCompare(b.startTime));
  return regularShifts?.[0]?.endTime || date;
};

const getMissingPunchesFromShifts = (
  addedShifts: AttendanceReportShift[],
  addedPunches: Punch[],
  date: string,
) => {
  if (
    !addedShifts.length ||
    addedShifts.some((shift) => shift.start_time === '-' || shift.end_time === '-')
  )
    return [];

  const now = dateUtils.now(true, 'YYYY-MM-DD HH:mm:ss');
  const shifts: MissingPunchShift[] = addedShifts
    .map((shift) => ({
      ...shift,
      shiftInterval: shiftUtils.getShiftIntervalDateTime(date, shift.start_time, shift.end_time),
    }))
    .filter((shift) => shift.start_time !== shift.end_time && shift.shiftInterval.startTime <= now)
    .sort((a, b) => a.start_time.localeCompare(b.start_time));

  const noPunchOut: NoPunchOut = getNoPunchOutFromShifts(shifts, addedPunches);
  if (noPunchOut)
    return [{ out: { ..._omit(noPunchOut, 'shift') }, shift: { ...noPunchOut.shift } }];
  if (addedPunches.some((punch) => !!punch.out && punch.out.date === null)) return [];

  const punchInCount = addedPunches.filter((punch) => !!punch.in).length;
  const punchOutCount = addedPunches.filter((punch) => !!punch.out).length;

  const newPunches: MissingPunchPair[] = [];
  // contains the values of missing punch out from shifts end time if the punch pair overlap with any shift
  // to prevent adding punch out on every shift -> only the first shift overlap
  const addedPunchOut: { [key: string]: boolean } = {};

  // Iterate through each shift
  for (let shiftIndex = 0; shiftIndex < shifts.length; shiftIndex += 1) {
    const shift = shifts[shiftIndex];
    const shiftStart = shift.shiftInterval.startTime;
    const shiftEnd = shift.shiftInterval.endTime;
    const shiftEndInFuture = shiftEnd > now;

    let overlap = false;

    // Check for overlap with existing punches
    for (let punchIndex = 0; punchIndex < addedPunches.length; punchIndex += 1) {
      const punch = addedPunches[punchIndex];
      const punchStart = punch.in?.date;
      const punchEnd = punch.out?.date;

      if (punch.out === null && punchStart && punchStart < shiftEnd && !shiftEndInFuture) {
        if (addedPunchOut[punchIndex]) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const overlapTime = dateUtils.getDateTimeRangeOverlap(
          { start: shiftStart, end: shiftEnd },
          { start: punchStart, end: shiftEnd },
        );

        if (overlapTime > 0) {
          overlap = true;
          addedPunchOut[punchIndex] = true;
          newPunches.push({
            out: { value: shiftEnd },
            shift: {
              id: shift.id,
              name: shift.name,
              start_time: shift.start_time,
              end_time: shift.end_time,
            },
          });
          break;
        }
      }

      const overlapTime = dateUtils.getDateTimeRangeOverlap(
        { start: shiftStart, end: shiftEnd },
        { start: punchStart ?? '', end: punchEnd ?? '' },
      );

      if (overlapTime > 0) {
        overlap = true;
        break;
      }
    }

    // If no overlap, generate punches for the shift
    if (!overlap) {
      const punchPair: MissingPunchPair = {
        shift: {
          id: shift.id,
          name: shift.name,
          start_time: shift.start_time,
          end_time: shift.end_time,
        },
      };
      if (!shiftEndInFuture)
        punchPair.out = {
          value: shiftEnd,
        };
      if (punchInCount === punchOutCount)
        punchPair.in = {
          value: shiftStart,
          note: '',
        };
      if (!!punchPair.in || !!punchPair.out) {
        newPunches.push(punchPair);
      }
    }
  }
  return newPunches;
};

const getAccountMissingPunches = (account: Employee) => {
  const missingPunches: {
    [date: string]: {
      [branchId: string]: {
        [positionId: string]: MissingPunchPair[];
      };
    };
  } = {};
  const todayDate = dateUtils.now(true, 'YYYY-MM-DD');
  const dates = Object.keys(account.dates).filter((date) => date <= todayDate);
  dates.forEach((date) => {
    const branchIds = Object.keys(account.dates[date] ?? {});
    missingPunches[date] = {};
    branchIds.forEach((branchId) => {
      const positionIds = Object.keys(account.dates?.[date]?.[branchId]?.positions ?? {});
      missingPunches[date][branchId] = {};
      positionIds.forEach((positionId) => {
        const { shifts = [], punches = [] } =
          account.dates?.[date]?.[branchId]?.positions?.[positionId] || {};

        const positionMissingPunches = getMissingPunchesFromShifts(shifts, punches, date);
        if (positionMissingPunches.length) {
          missingPunches[date][branchId][positionId] = positionMissingPunches;
        }
      });

      // remove empty missing punches branch
      if (Object.keys(missingPunches[date][branchId]).length === 0) {
        delete missingPunches[date][branchId];
      }
    });

    // remove empty missing punches date
    if (Object.keys(missingPunches[date]).length === 0) {
      delete missingPunches[date];
    }
  });
  return missingPunches;
};

const getMissingPunches = (data: AttendanceReportResponse) => {
  const result: {
    [accountId: string]: {
      [date: string]: {
        [branchId: string]: {
          [positionId: string]: MissingPunchPair[];
        };
      };
    };
  } = {};
  data?.employees?.forEach((account) => {
    const missingPunches = getAccountMissingPunches(account);
    if (Object.keys(missingPunches)?.length) result[account.account_id] = missingPunches;
  });
  return result;
};

//  Not used currently we fetch account data when response success (to update totals)
// TODO  kept for future in case we want to use it
const updatePunch = (data: AttendanceReportResponse, payload: EditPunchPayload) => {
  const account = data.employees?.find((acc) => acc.account_id === payload.accountId);
  const date = account?.dates[payload.date];
  const branch = date?.[`${payload.branchId}`];
  const position = branch?.positions?.[payload.positionId];
  const editedPunch = position?.punches.find((punch) =>
    payload.type === 'IN'
      ? punch.in && punch.in.id === payload.punchId
      : punch.out && punch.out.id === payload.punchId,
  );
  if (editedPunch) {
    if (payload.type === 'IN') {
      editedPunch.in.date = payload.date_time;
      editedPunch.in.is_original = false;
      editedPunch.in.id = payload.response.result;
      editedPunch.in.manager_id = payload.managerId;
      editedPunch.in.manager_name = payload.managerName;
      editedPunch.in.manager_note = payload.note;
    } else if (payload.type === 'OUT' && editedPunch.out) {
      editedPunch.out.date = payload.date_time;
      editedPunch.out.is_original = false;
      editedPunch.out.id = payload.response.result;
      editedPunch.out.manager_id = payload.managerId;
      editedPunch.out.manager_name = payload.managerName;
      editedPunch.out.manager_note = payload.note;
    }
  }
};

// const sortPunches = (punches: Punch[]) =>
//   punches.sort((a, b) => a.in.date.localeCompare(b.in.date));

//  Not used currently we fetch account data when response success (to update totals)
// TODO  kept for future in case we want to use it
// const addPunch = (data: AttendanceReportResponse, payload: AddPunchPayload) => {
//   const { punch } = payload;
//   const account = data.employees?.find((acc) => acc.account_id === payload.accountId);
//   const date = account?.dates[payload.date];
//   const branch = date?.[`${payload.branchId}`];
//   const position = branch?.positions?.[`${payload.positionId}`];
//   const newPunchOut: PunchOut =
//     'out' in punch && 'out_id' in payload.response.result
//       ? {
//           account_id: payload.accountId,
//           branch_id: payload.branchId,
//           date: punch?.out?.date_time ?? '',
//           note: null,
//           is_original: true,
//           id: payload.response.result.out_id,
//           is_valid: 1,
//           manager_name: payload.managerName,
//           manager_id: payload.managerId,
//           manager_note: null,
//           distance: 0,
//         }
//       : null;

//   const lastPunchPair: Punch | undefined = position?.punches[position.punches.length - 1];

//   const newPunches: Punch = {
//     // in added to pass typescript check, changes for in added below
//     in: {
//       account_id: payload.accountId,
//       branch_id: payload.branchId,
//       date: '',
//       note: null,
//       is_original: true,
//       id: 0,
//       is_valid: 1,
//       manager_name: payload.managerName,
//       manager_id: payload.managerId,
//       manager_note: null,
//       distance: 0,
//     },
//     out: newPunchOut,
//   };

//   if ('in' in punch && 'in_id' in payload.response.result) {
//     newPunches.in = {
//       account_id: payload.accountId,
//       branch_id: payload.branchId,
//       date: punch.in?.date_time ?? '',
//       note: null,
//       is_original: true,
//       id: payload.response.result.in_id,
//       is_valid: 1,
//       manager_name: payload.managerName,
//       manager_id: payload.managerId,
//       manager_note: null,
//       distance: 0,
//     };
//   }

//   if (lastPunchPair?.in && !lastPunchPair.out && 'out' in punch && !('in' in punch)) {
//     lastPunchPair.out = newPunchOut;
//   } else if (position?.punches) {
//     position.punches.push(newPunches);
//     position.punches = sortPunches(position.punches);
//   }
// };

export const getPunchesFromShifts = (
  addedShifts: RowPositionShifts[],
  addedPunches: Punch[],
  date: string,
  addPunchOutInFuture: boolean = false,
) => {
  if (
    !addedShifts.length ||
    addedShifts.some((shift) => shift.start_time === '-' || shift.end_time === '-') ||
    addedPunches.some((punch) => !!punch.out && punch.out.date === null)
  ) {
    return [];
  }

  const now = dateUtils.now(true, 'YYYY-MM-DD HH:mm:ss');
  const shifts = addedShifts
    .map((shift) => ({
      ...shift,
      shiftInterval: shiftUtils.getShiftIntervalDateTime(date, shift.start_time, shift.end_time),
    }))
    .filter((shift) => shift.start_time !== shift.end_time)
    .sort((a, b) => a.start_time.localeCompare(b.start_time));

  const punchInCount = addedPunches.filter((punch) => !!punch.in).length;
  let punchOutCount = addedPunches.filter((punch) => !!punch.out).length;

  const newPunches: PunchesFromShifts[] = [];
  // contains the values of missing punch out from shifts end time if the punch pair overlap with any shift
  // to prevent adding punch out on every shift -> only the first shift overlap
  const addedPunchOut: { [key: string]: boolean } = {};

  // Iterate through each shift
  for (let shiftIndex = 0; shiftIndex < shifts.length; shiftIndex += 1) {
    const shift = shifts[shiftIndex];
    const shiftStart = shift.shiftInterval.startTime;
    const shiftEnd = shift.shiftInterval.endTime;
    const shiftEndInFuture = shiftEnd > now;

    let overlap = false;

    // Check for overlap with existing punches
    for (let punchIndex = 0; punchIndex < addedPunches.length; punchIndex += 1) {
      const punch = addedPunches[punchIndex];
      const punchStart = punch.in?.date;
      const punchEnd = punch.out?.date;

      if (
        punch.out === null &&
        punchStart < shiftEnd &&
        (!shiftEndInFuture || addPunchOutInFuture)
      ) {
        if (addedPunchOut[punchIndex]) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const overlapTime = dateUtils.getDateTimeRangeOverlap(
          { start: shiftStart, end: shiftEnd },
          { start: punchStart, end: shiftEnd },
        );

        if (overlapTime > 0) {
          overlap = true;
          addedPunchOut[punchIndex] = true;
          newPunches.push({
            out: { value: shiftEnd, in_value: punchStart },
            shift: {
              id: shift.id,
              name: shift.name,
              start_time: shift.start_time,
              end_time: shift.end_time,
            },
          });
          punchOutCount += 1;
          break;
        }
      }

      const overlapTime = dateUtils.getDateTimeRangeOverlap(
        { start: shiftStart, end: shiftEnd },
        { start: punchStart, end: String(punchEnd) },
      );

      if (overlapTime > 0) {
        overlap = true;
        break;
      }
    }

    // If no overlap, generate punches for the shift
    if (!overlap) {
      const punchPair: PunchesFromShifts = {
        shift: {
          id: shift.id,
          name: shift.name,
          start_time: shift.start_time,
          end_time: shift.end_time,
        },
      };

      if (!shiftEndInFuture || addPunchOutInFuture)
        punchPair.out = {
          value: shiftEnd,
        };

      if (punchInCount === punchOutCount)
        punchPair.in = {
          value: shiftStart,
        };
      if (!!punchPair.in || !!punchPair.out) {
        newPunches.push(punchPair);
      }
    }
  }
  return newPunches;
};

export const getMaxPunchDifferenceTime = (punches: Punch[]) => {
  let result = 0;
  for (let punchIndex = 1; punchIndex < punches.length; punchIndex += 1) {
    const currentPunchIn = punches[punchIndex].in.date;
    const prevPunchOut = punches[punchIndex - 1]?.out?.date || currentPunchIn;

    const difference =
      dateUtils.getDate(currentPunchIn).toDate().getTime() -
      dateUtils.getDate(prevPunchOut).toDate().getTime();
    if (difference > result) result = difference;
  }
  return result;
};

export const getTimeRangeDifferences = (
  range1: { start: string; end: string },
  range2: { start: string; end: string },
) =>
  Math.abs(dayjs(range1.start).toDate().getTime() - dayjs(range2.start).toDate().getTime()) +
  Math.abs(dayjs(range1.end).toDate().getTime() - dayjs(range2.end).toDate().getTime());

export const getMissingShiftFromPunches = (
  punches: Punch[],
  weekShifts: WeekShifts[],
  date: string,
) => {
  const weekday = dateUtils.getWeekDay(date);
  const defaultShifts = weekShifts.find((day) => day.weekday === weekday)?.shifts || [];

  // remove off shift
  const shifts = defaultShifts.filter((shift) => shift.start_hour !== shift.end_hour);
  if (!shifts.length) return '';
  if (!punches.length) return shifts[0].name;

  const punch = { in: punches[0].in, out: punches[0].out };
  if (!punch?.out?.date) {
    return '';
  }

  const MAX_DIFFERENCE_TIME = 600000;
  if (punches.length > 1 && getMaxPunchDifferenceTime(punches) > MAX_DIFFERENCE_TIME) return '';

  const result = {
    overlapping: 1,
    timeRangeDifferences: 1,
    shift: '',
  };

  if (punches.length > 1) {
    const lastIndexOfOutPunch = findLastIndex(punches, (_punch) => !!_punch?.out?.date);
    punch.out = punches[lastIndexOfOutPunch].out;
  }

  for (let shiftIndex = 0; shiftIndex < shifts.length; shiftIndex += 1) {
    const shift = shifts[shiftIndex];
    const { startTime, endTime } = shiftUtils.getShiftIntervalDateTime(
      date,
      shift.start_hour,
      shift.end_hour,
    );
    let shiftOverlapping = 0;
    let shiftRangeDifferences = 0;

    if (punch.in.date === startTime && punch?.out?.date === endTime) {
      return shift.name;
    }

    const overlap = dateUtils.getDateTimeRangeOverlap(
      { start: startTime, end: endTime },
      { start: punch.in.date, end: punch.out?.date || punch.in.date || '' },
    );

    if (overlap > 0) {
      shiftOverlapping += overlap;
      shiftRangeDifferences += getTimeRangeDifferences(
        {
          start: startTime,
          end: endTime,
        },
        {
          start: punch.in.date,
          end: punch.out?.date || '',
        },
      );
    }

    if (
      (!result.shift && shiftOverlapping > 0) ||
      (shiftOverlapping > 0 &&
        result.overlapping - result.timeRangeDifferences < shiftOverlapping - shiftRangeDifferences)
    ) {
      result.overlapping = shiftOverlapping;
      result.timeRangeDifferences = shiftRangeDifferences;
      result.shift = shift.name;
    }
  }
  return result.shift;
};

export const getPunchesCount = (tentativePunches: MissingPunches) =>
  Object.values(tentativePunches).reduce((prev, accountObj) => {
    const accountPunchesCount = Object.values(accountObj).reduce((prevDateCount, dateObj) => {
      const datePunchesCount = Object.values(dateObj).reduce((prevBranchCount, branchObj) => {
        const branchPunchesCount = Object.values(branchObj).reduce(
          (prevPositionCount, positionObj) => {
            const punchesCount = positionObj.reduce(
              (prevPunchCount, punch) => prevPunchCount + Number(!!punch.in) + Number(!!punch.out),
              0,
            );
            return punchesCount + prevPositionCount;
          },
          0,
        );
        return branchPunchesCount + prevBranchCount;
      }, 0);
      return prevDateCount + datePunchesCount;
    }, 0);
    return prev + accountPunchesCount;
  }, 0);

export default {
  getMissingPunches,
  getAccountMissingPunches,
  selectPunch,
  uselectPunch,
  addSelectedPunchesHistory,
  selectEmployeePunches,
  convertSelectedPunchesToArray,
  unSelectEmployeePunches,
  selectAllMissingPunches,
  undoSelectedPunches,
  updatePunch,
  // addPunch,
  getPunchesFromShifts,
  getNoPunchOutFromShifts,
  getSuggestedNoPunchOutTime,
  getPunchesCount,
};

import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { dayRequestsActions } from '../slices/requestsDaySlice';
import ActionTypes from '../actionTypes/requestsDay';
import { Response } from '../../types/Response';
import utils from '../../shared/utils';
import {
  ExchangeRequestEmployee,
  FetchExchangeEmployeesResponse,
  DailyRequestsResponse,
} from '../../types/Requests';

function* updateOffRequest({
  payload,
}: PayloadAction<{
  branchId: number;
  isApproved: 0 | 1;
  requestId: number;
  data: { note: string | null; managerId: number };
}>) {
  try {
    yield put(
      dayRequestsActions.updateOffRequestStarted({
        requestId: payload.requestId,
        isApprove: payload.isApproved,
      }),
    );
    const res: Response<boolean> = yield call(() =>
      payload.isApproved
        ? api.requests.putOffApprove(payload.branchId, payload.requestId, {
            manager_id: payload.data.managerId,
          })
        : api.requests.putOffReject(payload.branchId, payload.requestId, {
            manager_id: payload.data.managerId,
            note: payload.data.note,
          }),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        dayRequestsActions.updateOffRequestSuccess({
          requestId: payload.requestId,
          isApproved: payload.isApproved,
          response: res.data,
        }),
      );
    } else {
      yield put(dayRequestsActions.updateOffRequestError(res.data));
    }
  } catch (error) {
    yield put(dayRequestsActions.updateOffRequestError(utils.general.convertAxiosError(error)));
  }
}

function* updateExchangeRequest({
  payload,
}: PayloadAction<{
  branchId: number;
  isApproved: 0 | 1;
  requestId: number;
  data: { note: string; managerId: number };
}>) {
  try {
    yield put(
      dayRequestsActions.updateExchangeRequestStarted({
        requestId: payload.requestId,
        isApproved: payload.isApproved,
      }),
    );
    const res: Response<boolean> = yield call(() =>
      payload.isApproved
        ? api.requests.putExchangeApprove(payload.branchId, payload.requestId, {
            manager_id: payload.data.managerId,
          })
        : api.requests.putExchangeReject(payload.branchId, payload.requestId, {
            manager_id: payload.data.managerId,
            note: payload.data.note,
          }),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        dayRequestsActions.updateExchangeRequestSuccess({
          requestId: payload.requestId,
          isApproved: payload.isApproved,
          response: res.data,
        }),
      );
    } else {
      yield put(dayRequestsActions.updateExchangeRequestError(res.data));
    }
  } catch (error) {
    yield put(
      dayRequestsActions.updateExchangeRequestError(utils.general.convertAxiosError(error)),
    );
  }
}

function* getDayRequests({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(dayRequestsActions.fetchRequestsDayStarted());
    const res: Response<DailyRequestsResponse> = yield call(() =>
      api.requests.getRequestsDay(payload.branchId, payload.date),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(dayRequestsActions.fetchRequestsDaySuccess(res.data));
    } else {
      yield put(dayRequestsActions.fetchRequestsDayError(res.data));
    }
  } catch (error) {
    yield put(dayRequestsActions.fetchRequestsDayError(utils.general.convertAxiosError(error)));
  }
}

function* getExchangeEmployees({
  payload,
}: PayloadAction<{ branchId: number; requestId: number }>) {
  try {
    yield put(dayRequestsActions.fetchExchangeEmployeesStarted());
    const res: Response<FetchExchangeEmployeesResponse> = yield call(() =>
      api.requests.getExchangeEmployee(payload.branchId, payload.requestId),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        dayRequestsActions.fetchExchangeEmployeesSuccess({
          response: res.data,
          requestId: payload.requestId,
        }),
      );
    } else {
      yield put(
        dayRequestsActions.fetchExchangeEmployeesError({
          error: res.data,
          requestId: payload.requestId,
        }),
      );
    }
  } catch (error) {
    yield put(
      dayRequestsActions.fetchExchangeEmployeesError({
        error: utils.general.convertAxiosError(error),
        requestId: payload.requestId,
      }),
    );
  }
}

function* updateExchangeEmployee({
  payload,
}: PayloadAction<{
  branchId: number;
  requestId: number;
  data: { responder_id: number; manager_id: number };
  employee: ExchangeRequestEmployee;
}>) {
  try {
    yield put(dayRequestsActions.updateExchangeEmployeeStarted({ requestId: payload.requestId }));
    const res: Response<boolean> = yield call(() =>
      api.requests.putExchangeEmployee(payload.branchId, payload.requestId, payload.data),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        dayRequestsActions.updateExchangeEmployeeSuccess({
          response: res.data,
          requestId: payload.requestId,
          data: payload.data,
          employee: payload.employee,
        }),
      );
    } else {
      yield put(
        dayRequestsActions.updateExchangeEmployeeError({
          error: res.data,
          requestId: payload.requestId,
        }),
      );
    }
  } catch (error) {
    yield put(
      dayRequestsActions.updateExchangeEmployeeError({
        error: utils.general.convertAxiosError(error),
        requestId: payload.requestId,
      }),
    );
  }
}

function* watchManagerequestsDay() {
  yield all([
    takeLatest(ActionTypes.GET_DAY_REQUESTS, getDayRequests),
    takeLatest(ActionTypes.GET_EXCHANGE_EMPLOYEE, getExchangeEmployees),
    takeLatest(ActionTypes.PUT_EXCHANGE_REQUEST, updateExchangeRequest),
    takeLatest(ActionTypes.PUT_OFF_REQUEST, updateOffRequest),
    takeLatest(ActionTypes.PUT_EXCHANGE_EMPLOYEE, updateExchangeEmployee),
  ]);
}

export default watchManagerequestsDay;

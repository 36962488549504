import { FC, memo } from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

interface LoaderProps extends CircularProgressProps {}

const Loader: FC<LoaderProps> = memo(function Loader(props: LoaderProps) {
  return (
    <CircularProgress
      size="1.5em"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1',
        ...props.sx,
      }}
    />
  );
});

export default Loader;

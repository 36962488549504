/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from '../../types/Store';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import utils from '../../shared/utils';
import {
  RequestsCount,
  ExchangeRequestEmployee,
  ExchangeRequest,
  FetchExchangeEmployeesResponse,
  DailyRequestsResponse,
  OffRequest,
} from '../../types/Requests';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: Req;
}

export type InitialState = {
  fetchRequestsDay: ActionType<DailyRequestsResponse, ResponseSuccess<DailyRequestsResponse>>;
  fetchExchangeEmployees: ActionType<
    ExchangeRequestEmployee[],
    ResponseSuccess<FetchExchangeEmployeesResponse>
  >;
  updateOffRequest: ActionType<{ requestId: number; isApprove: 1 | 0 }, ResponseSuccess<boolean>>;
  updateExchangeRequest: ActionType<
    { requestId: number; isApprove: 1 | 0 },
    ResponseSuccess<boolean>
  >;
  updateExchangeEmployee: ActionType<{ requestId: number }, ResponseSuccess<boolean>>;
  count: RequestsCount;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
};

const initialState: InitialState = {
  fetchRequestsDay: {
    ...ACTIONS_INITIAL_STATE,
    data: {
      exchange_requests: [],
      off_requests: [],
    },
  },
  fetchExchangeEmployees: { ...ACTIONS_INITIAL_STATE, data: [] },
  updateOffRequest: {
    ...ACTIONS_INITIAL_STATE,
    data: { requestId: 0, isApprove: 0 },
  },
  updateExchangeRequest: {
    ...ACTIONS_INITIAL_STATE,
    data: { requestId: 0, isApprove: 0 },
  },
  updateExchangeEmployee: { ...ACTIONS_INITIAL_STATE, data: { requestId: 0 } },
  count: {
    exchange_approved: 0,
    exchange_pending: 0,
    off_approved: 0,
    off_pending: 0,
  },
};

const updateRequests = (
  requests: OffRequest[] | ExchangeRequest[],
  isApproved: 0 | 1,
  requestId: number,
) => {
  requests.forEach((item) => {
    if (item.request_id === requestId) {
      item.is_approved = isApproved;
    }
  });
};

const countOffRequests = (requests: OffRequest[]) => ({
  off_pending: requests.filter((item) => item.is_approved === null).length || 0,
  off_approved: requests.filter((item) => item.is_approved === 1).length || 0,
});

const countExchRequests = (requests: ExchangeRequest[]) => ({
  exchange_pending: requests.filter((item) => item.is_approved === null).length || 0,
  exchange_approved: requests.filter((item) => item.is_approved === 1).length || 0,
});

const updateEmployee = (
  data: ExchangeRequest[],
  requestId: number,
  responder: ExchangeRequestEmployee,
) => {
  data.forEach((request) => {
    if (request.request_id === requestId) {
      request.responder = {
        id: responder.id,
        first_name: responder.first_name,
        last_name: responder.last_name,
      };
      request.is_approved = 1;
    }
  });
};

const RequestsDaySlice = createSlice({
  name: 'requestsDay',
  initialState,
  reducers: {
    fetchRequestsDayStarted: (state) => {
      state.fetchRequestsDay = {
        ...initialState.fetchRequestsDay,
        isLoading: true,
      };
      state.count = { ...initialState.count };
      state.fetchExchangeEmployees.data = [];
    },
    fetchRequestsDayError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchRequestsDay.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchRequestsDay.error = action.payload;
      } else {
        state.fetchRequestsDay.response = action.payload;
      }
    },
    fetchRequestsDaySuccess: (
      state,
      action: PayloadAction<ResponseSuccess<DailyRequestsResponse>>,
    ) => {
      state.fetchRequestsDay.isLoading = false;
      state.fetchRequestsDay.data = action.payload.result;
      state.fetchRequestsDay.response = action.payload;
      state.count = {
        ...state.count,
        ...countOffRequests(action.payload.result.off_requests ?? []),
        ...countExchRequests(action.payload.result.exchange_requests ?? []),
      };
    },

    fetchExchangeEmployeesStarted: (state) => {
      state.fetchExchangeEmployees = {
        ...initialState.fetchExchangeEmployees,
        isLoading: true,
      };
    },
    fetchExchangeEmployeesError: (
      state,
      action: PayloadAction<{ error: RequestError | ResponseError; requestId: number }>,
    ) => {
      state.fetchExchangeEmployees.isLoading = false;
      if (utils.general.isRequestError(action.payload.error)) {
        state.fetchExchangeEmployees.error = action.payload.error;
      } else {
        state.fetchExchangeEmployees.response = action.payload.error;
      }
    },
    fetchExchangeEmployeesSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<FetchExchangeEmployeesResponse>;
        requestId: number;
      }>,
    ) => {
      state.fetchExchangeEmployees.isLoading = false;
      state.fetchExchangeEmployees.response = action.payload.response;
      state.fetchExchangeEmployees.data = action.payload.response.result;
    },

    updateOffRequestStarted: (
      state,
      action: PayloadAction<{ requestId: number; isApprove: 1 | 0 }>,
    ) => {
      state.updateOffRequest = {
        ...initialState.updateOffRequest,
        isLoading: true,
        data: {
          requestId: action.payload.requestId,
          isApprove: action.payload.isApprove,
        },
      };
    },
    updateOffRequestError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateOffRequest.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateOffRequest.error = action.payload;
      } else {
        state.updateOffRequest.response = action.payload;
      }
    },
    updateOffRequestSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>;
        isApproved: 0 | 1;
        requestId: number;
      }>,
    ) => {
      updateRequests(
        state.fetchRequestsDay.data.off_requests ?? [],
        action.payload.isApproved,
        action.payload.requestId,
      );
      state.updateOffRequest.isLoading = false;
      state.updateOffRequest.response = action.payload.response;
      state.count = {
        ...state.count,
        ...countOffRequests(state.fetchRequestsDay.data.off_requests ?? []),
      };
    },

    updateExchangeRequestStarted: (
      state,
      action: PayloadAction<{ isApproved: 0 | 1; requestId: number }>,
    ) => {
      state.updateExchangeRequest = {
        ...initialState.updateExchangeRequest,
        isLoading: true,
        data: { requestId: action.payload.requestId, isApprove: action.payload.isApproved },
      };
      state.updateExchangeEmployee = { ...initialState.updateExchangeEmployee };
    },
    updateExchangeRequestError: (state, action: PayloadAction<ResponseError | RequestError>) => {
      state.updateExchangeRequest.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateExchangeRequest.error = action.payload;
      } else {
        state.updateExchangeRequest.response = action.payload;
      }
    },
    updateExchangeRequestSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>;
        isApproved: 0 | 1;
        requestId: number;
      }>,
    ) => {
      updateRequests(
        state.fetchRequestsDay.data.exchange_requests ?? [],
        action.payload.isApproved,
        action.payload.requestId,
      );
      state.updateExchangeRequest.isLoading = false;
      state.updateExchangeRequest.response = action.payload.response;
      state.count = {
        ...state.count,
        ...countExchRequests(state.fetchRequestsDay.data.exchange_requests ?? []),
      };
    },

    updateExchangeEmployeeStarted: (state, action: PayloadAction<{ requestId: number }>) => {
      state.updateExchangeEmployee = {
        ...initialState.updateExchangeEmployee,
        isLoading: true,
        data: { requestId: action.payload.requestId },
      };
      state.updateExchangeRequest = { ...initialState.updateExchangeRequest };
    },
    updateExchangeEmployeeError: (
      state,
      action: PayloadAction<{
        requestId: number;
        error: RequestError | ResponseError;
      }>,
    ) => {
      state.updateExchangeEmployee.isLoading = false;
      if (utils.general.isRequestError(action.payload.error)) {
        state.updateExchangeEmployee.error = action.payload.error;
      } else {
        state.updateExchangeEmployee.response = action.payload.error;
      }
    },
    updateExchangeEmployeeSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<boolean>;
        requestId: number;
        data: { responder_id: number; manager_id: number };
        employee: ExchangeRequestEmployee;
      }>,
    ) => {
      updateEmployee(
        state.fetchRequestsDay.data.exchange_requests ?? [],
        action.payload.requestId,
        action.payload.employee,
      );
      state.updateExchangeEmployee.isLoading = false;
      state.updateExchangeEmployee.response = action.payload.response;
      state.count = {
        ...state.count,
        ...countExchRequests(state.fetchRequestsDay.data.exchange_requests ?? []),
      };
    },

    resetUpdateRequestsSlices: (state) => {
      state.updateExchangeEmployee = { ...initialState.updateExchangeEmployee };
      state.updateExchangeRequest = { ...initialState.updateExchangeRequest };
      state.updateOffRequest = { ...initialState.updateOffRequest };
    },

    clearSlices: (state) => {
      state.updateExchangeEmployee = { ...initialState.updateExchangeEmployee };
      state.updateExchangeRequest = { ...initialState.updateExchangeRequest };
      state.updateOffRequest = { ...initialState.updateOffRequest };
      state.count = { ...initialState.count };
      state.fetchExchangeEmployees = { ...initialState.fetchExchangeEmployees };
      state.fetchRequestsDay = { ...initialState.fetchRequestsDay };
    },
  },
});

export default RequestsDaySlice.reducer;

export const dayRequestsActions = RequestsDaySlice.actions;

export const selectGetDayRequestsState = (state: State<InitialState>) =>
  state.requestsDaySlice.fetchRequestsDay;

export const selectGetExchangeEmployeesState = (state: State<InitialState>) =>
  state.requestsDaySlice.fetchExchangeEmployees;

export const selectUpdateOffRequestState = (state: State<InitialState>) =>
  state.requestsDaySlice.updateOffRequest;

export const selectUpdateExchangeRequestState = (state: State<InitialState>) =>
  state.requestsDaySlice.updateExchangeRequest;

export const selectUpdateExchangeEmployeeState = (state: State<InitialState>) =>
  state.requestsDaySlice.updateExchangeEmployee;

export const selectDayRequestsCount = (state: State<InitialState>) => state.requestsDaySlice.count;

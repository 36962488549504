import { Fragment, FC } from 'react';
import Lens from '@mui/icons-material/Lens';
import Button from '@mui/material/Button';

import './PrefCont.scss';

interface PrefContProps {
  shifts: string[] | null;
  title?: string;
  onItemClick?: (name: string) => void;
  isLoading?: boolean;
  addedShift?: string;
  excludedShifts?: string[];
  onHover?: (name: string) => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
  isPrefered?: boolean;
}

const PrefCont: FC<PrefContProps> = function PrefCont(props: PrefContProps) {
  const { shifts, title, addedShift, disabled, excludedShifts } = props;
  const { isLoading, onHover, onItemClick, onMouseLeave, isPrefered } = props;

  return (
    <div
      className={`pref-cont ${isPrefered ? 'prefered-shift' : 'unavailable-shift'} ${
        !shifts?.length ? 'empty' : ''
      }`}
    >
      {shifts?.length && (
        <div>
          {title && <span className="title">{title}</span>}
          <div className="shift-names">
            {shifts?.map((shiftName, index) => {
              const isShiftExcluded = excludedShifts?.includes(shiftName);
              const enableClick =
                !disabled &&
                !isShiftExcluded &&
                onItemClick &&
                !(isLoading && addedShift === shiftName);
              return (
                <Fragment key={shiftName}>
                  <Button
                    variant="outlined"
                    sx={{
                      padding: 0,
                      border: 'none',
                      minWidth: 'auto',
                      color: 'inherit',
                      display: 'flex',
                    }}
                    startIcon={<Lens style={{ fontSize: '0.7em' }} />}
                    disabled={!enableClick}
                    onClick={() => {
                      if (enableClick) onItemClick?.(shiftName);
                    }}
                    onFocus={() => {}}
                    onMouseOver={() => {
                      if (enableClick) {
                        onHover?.(shiftName);
                      }
                    }}
                    onMouseLeave={() => {
                      if (enableClick) {
                        onMouseLeave?.();
                      }
                    }}
                    className={`shift-name ${enableClick ? 'clickable' : ''}`}
                  >
                    {shiftName}
                  </Button>
                  {index === (shifts?.length || 0) - 1 ? '' : <span className="seperator">,</span>}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

PrefCont.defaultProps = {
  title: '',
  excludedShifts: [],
  addedShift: '',
  disabled: false,
  isLoading: false,
  isPrefered: false,
  onHover: () => {},
  onItemClick: () => {},
  onMouseLeave: () => {},
};

export default PrefCont;

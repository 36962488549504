import { AddMultiPunchResponseErrorData } from '../../../api/punch';
import { MissingPunchPair } from './punches';

/* eslint-disable no-param-reassign */
export type MissingPunches = {
  [key: string]: {
    [date: string]: {
      [branchId: string]: {
        [positionId: string]: MissingPunchPair[];
      };
    };
  };
};

const getFailedTentativePunches = (
  tentativePunches: MissingPunches,
  failedTentativePunches: AddMultiPunchResponseErrorData,
) => {
  failedTentativePunches.forEach((item) => {
    const { account_id: accountId, branch_id: branchId, punch: punchPair, message } = item;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { index, position_id, date } = punchPair;
    const punch = tentativePunches?.[accountId]?.[date]?.[branchId]?.[position_id]?.[index];
    if (punch?.in) {
      punch.in.overlap = true;
      punch.in.message = message;
    }
    if (punch?.out) {
      punch.out.overlap = true;
      punch.out.message = message;
    }
  });
  return failedTentativePunches;
};

const restoreIgnoredTentativePunchesErrors = (
  tentativePunches: MissingPunches,
  ignoredPunches: MissingPunches,
) => {
  Object.keys(ignoredPunches || {}).forEach((accountId) => {
    Object.keys(ignoredPunches[accountId] || {}).forEach((date) => {
      Object.keys(ignoredPunches[accountId][date] || {}).forEach((branchId) => {
        Object.keys(ignoredPunches[accountId][date][branchId] || {}).forEach((positionId) => {
          tentativePunches[accountId] = { ...(tentativePunches?.[accountId] || {}) };
          tentativePunches[accountId][date] = { ...(tentativePunches[accountId]?.[date] || {}) };
          tentativePunches[accountId][date][branchId] = {
            ...(tentativePunches[accountId]?.[date]?.[branchId] || {}),
          };
          tentativePunches[accountId][date][branchId][positionId] = [
            ...(tentativePunches[accountId]?.[date]?.[branchId][positionId] || []),
          ];

          tentativePunches[accountId][date][branchId][positionId] =
            ignoredPunches[accountId][date][branchId][positionId];
        });
      });
    });
  });
  return {};
};

const ignoreTentativePunchesErrors = (tentativePunches: MissingPunches) => {
  const ignoredPunches: MissingPunches = {};
  Object.keys(tentativePunches || {}).forEach((accountId) => {
    Object.keys(tentativePunches[accountId] || {}).forEach((date) => {
      Object.keys(tentativePunches[accountId][date] || {}).forEach((branchId) => {
        Object.keys(tentativePunches[accountId][date][branchId] || {}).forEach((positionId) => {
          let punches = [...(tentativePunches[accountId][date][branchId][positionId] || [])];
          const punchesWithErrors = punches?.filter(
            (punchPair) => punchPair?.in?.overlap || punchPair?.out?.overlap,
          );
          if (punchesWithErrors.length) {
            ignoredPunches[accountId] = { ...(ignoredPunches[accountId] || {}) };
            ignoredPunches[accountId][date] = { ...(ignoredPunches[accountId]?.[date] || {}) };
            ignoredPunches[accountId][date][branchId] = {
              ...(ignoredPunches[accountId]?.[date][branchId] || {}),
            };
            ignoredPunches[accountId][date][branchId][positionId] =
              tentativePunches[accountId][date][branchId][positionId];

            // remove ignored punches
            punches = punches.filter(
              (punchPair) => !(punchPair?.in?.overlap || punchPair?.out?.overlap),
            );
            tentativePunches[accountId][date][branchId][positionId] = punches;
            if (punches.length === 0) {
              delete tentativePunches[accountId][date][branchId][positionId];
              if (Object.keys(tentativePunches[accountId][date][branchId]).length === 0)
                delete tentativePunches[accountId][date][branchId];
              if (Object.keys(tentativePunches[accountId][date]).length === 0)
                delete tentativePunches[accountId][date];
              if (Object.keys(tentativePunches[accountId]).length === 0)
                delete tentativePunches[accountId];
            }
          }
        });
      });
    });
  });
  return ignoredPunches;
};

const addTentativePunches = (tentativePunches: MissingPunches, selectedPunches: MissingPunches) => {
  const addedTentativePunches: {
    accountId: number;
    branchId: number;
    date: string;
    positionId: number;
  }[] = [];
  Object.keys(selectedPunches).forEach((accountId) => {
    Object.keys(selectedPunches[accountId] || {}).forEach((date) => {
      Object.keys(selectedPunches[accountId][date] || {}).forEach((branchId) => {
        Object.keys(selectedPunches[accountId][date][branchId]).forEach((positionId) => {
          const punches = selectedPunches[accountId][date][branchId][positionId];
          if (punches?.length && !tentativePunches?.[accountId]?.[date]?.[branchId]?.[positionId]) {
            addedTentativePunches.push({
              accountId: Number(accountId),
              positionId: Number(positionId),
              branchId: Number(branchId),
              date,
            });
            tentativePunches[accountId] = { ...tentativePunches[accountId] };
            tentativePunches[accountId][date] = { ...tentativePunches[accountId][date] };
            tentativePunches[accountId][date][branchId] = {
              ...tentativePunches[accountId][date][branchId],
            };
            tentativePunches[accountId][date][branchId][positionId] = punches.map((punchPair) => ({
              ...punchPair,
              ...(punchPair?.out ? { out: { ...punchPair.out } } : {}),
            }));
          }
        });
      });
    });
  });
  return addedTentativePunches;
};

export default {
  addTentativePunches,
  restoreIgnoredTentativePunchesErrors,
  ignoreTentativePunchesErrors,
  getFailedTentativePunches,
};

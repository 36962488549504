import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { GET_DAILY_SCHEDULE } from '../actionTypes/dailyschedule';
import { Response } from '../../types/Response';
import {
  fetchDailyScheduleError,
  fetchDailyScheduleStarted,
  fetchDailyScheduleSuccess,
} from '../slices/dailySchedule';
import utils from '../../shared/utils';
import { DailyScheduleResponseData } from '../../types/DailySchedule';

function* fetchDailySchedule({ payload }: PayloadAction<{ branchId: number; date: string }>) {
  try {
    yield put(fetchDailyScheduleStarted({ branchId: payload.branchId, date: payload.date }));
    const response: Response<DailyScheduleResponseData> = yield call(() =>
      api.schedule.getDailySchedule(payload.branchId, payload.date),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(
        fetchDailyScheduleSuccess({
          date: payload.date,
          branchId: payload.branchId,
          response: response.data,
        }),
      );
    } else {
      yield put(fetchDailyScheduleError(response.data));
    }
  } catch (error) {
    yield put(fetchDailyScheduleError(utils.general.convertAxiosError(error)));
  }
}

function* watchManageDailySchedule() {
  yield all([takeLatest(GET_DAILY_SCHEDULE, fetchDailySchedule)]);
}

export default watchManageDailySchedule;

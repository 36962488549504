import React from 'react';
import { AxiosError } from 'axios';
import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import {
  GenericResponseError,
  RequestError,
  ResponseError,
  ResponseSuccess,
} from '../../types/Response';
import { RequestErrorCode, ResponseStatus } from '../../enums';

export const convertAxiosError: (error: AxiosError | unknown) => RequestError = (
  error: AxiosError | unknown,
) => {
  const err = error as AxiosError;
  return err
    ? {
        ...err,
        message: err.message,
        stack: err.stack,
        config: undefined,
        request: undefined,
        code: err.code as RequestErrorCode,
        response: undefined,
      }
    : {
        message: '',
        stack: undefined,
        code: undefined,
        config: undefined,
        request: undefined,
        isAxiosError: false,
        name: '',
        toJSON: () => ({}),
        cause: undefined,
        response: undefined,
        status: undefined,
      };
};

export const isRequestError = (
  payload: RequestError | ResponseError | GenericResponseError<unknown>,
): payload is RequestError => 'stack' in payload;

export const isResponseSuccess = <T>(
  data: ResponseSuccess<T> | ResponseError,
): data is ResponseSuccess<T> => 'status' in data && data.status === ResponseStatus.Success;

export const isGenericResponseSuccess = <T, E>(
  data: ResponseSuccess<T> | GenericResponseError<E>,
): data is ResponseSuccess<T> => 'status' in data && data.status === ResponseStatus.Success;

type FetchWithTimeoutOptions = {
  timeout?: number;
  mode?: RequestMode;
};

export const fetchWithTimeout = async (api: string, options: FetchWithTimeoutOptions) => {
  const { timeout = 8000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(api, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

export const getItemDraggableStyle: (
  _isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => React.CSSProperties | undefined = (_, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reOrderDraggableItem: <T>(list: T[], startIndex: number, endIndex: number) => T[] = (
  list,
  startIndex,
  endIndex,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getDataForSelect: (
  data: { id: number; name: string }[],
) => { value: string; label: string }[] = (data: { id: number; name: string }[]) => {
  const selectData: { value: string; label: string }[] = [];
  data?.forEach((item) => {
    selectData.push({ value: String(item.id), label: item.name });
  });
  return selectData;
};

export const formatCurrencyNumber = (amount: number, currency: string) => {
  if (Number.isNaN(amount)) {
    return '';
  }
  const currencyNumber = new Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(amount || 0);
  return `${currency}${currencyNumber}`;
};

export const formatPhoneNumber = (phoneNumber?: string | null, countryIsd?: string | null) => {
  if (!phoneNumber || Number.isNaN(phoneNumber)) {
    return '';
  }
  if (!countryIsd) return `${phoneNumber}`;
  return `(+${countryIsd})  ${phoneNumber}`;
};

export const mergeArraysByProperty = <A, B>(a: A[], b: B[], key: keyof B & keyof A) =>
  a.map((item) => ({
    ...item,
    ...(b.find((item2) => item2[key] === (item[key] as unknown)) || {}),
  }));

export const cloneData: <T>(data: T) => T = (data) => JSON.parse(JSON.stringify(data));
const toFixedDecimals = (n: number, d: number) => (n % 1 !== 0 ? +n.toFixed(d) : n);

const getEmptySuccessResponse: <T>(result: T) => ResponseSuccess<T> = (result) => ({
  message: '',
  status: ResponseStatus.Success,
  user_message: '',
  result,
});

export const getNumberOfEmployeesNotifiedMessage = (numberOfEmployeesNotified: number = 0) =>
  `(${numberOfEmployeesNotified}) Employee${numberOfEmployeesNotified !== 1 ? 's' : ''} ${
    numberOfEmployeesNotified !== 1 ? 'were' : 'was'
  } notified`;

export const formatDecimalNumber = (
  num: number | string | undefined,
  numOfDecimalDigits: number,
) => {
  if (!num) return '0';
  const fixed = num.toString(); // Convert to string to avoid rounding
  const parts = fixed.split('.');
  const integerPart = parts[0];
  let decimalPart = parts[1] || ''; // Handle cases without decimals
  decimalPart = decimalPart.slice(0, numOfDecimalDigits); // Limit decimal places
  return integerPart.concat(decimalPart ? '.'.concat(decimalPart) : '');
};

export default {
  convertAxiosError,
  fetchWithTimeout,
  isRequestError,
  isResponseSuccess,
  isGenericResponseSuccess,
  getItemDraggableStyle,
  reOrderDraggableItem,
  getDataForSelect,
  formatCurrencyNumber,
  formatPhoneNumber,
  mergeArraysByProperty,
  cloneData,
  toFixedDecimals,
  getEmptySuccessResponse,
  getNumberOfEmployeesNotifiedMessage,
  formatDecimalNumber,
};

/* eslint-disable no-param-reassign */
import _orderBy from 'lodash/orderBy';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { State } from '../../types/Store';
import { ResponseError, RequestError, ResponseSuccess } from '../../types/Response';
import utils from '../../shared/utils';
import {
  SalesInputType,
  UpdateHourFormatRequest,
  UpdateProjectedSalesRequest,
  UpdateSalesPercentageCapRequest,
} from '../../types/Settings';
import { CurrencyId, SalesProjectionMethod } from '../../enums';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: Req;
}

export type DateState = { [id: number]: string };

export type InitialState = {
  fetchSalesInputTypes: ActionType<SalesInputType[], ResponseSuccess<SalesInputType[]>>;
  updateSalesInputType: ActionType<
    { id: number; new: SalesInputType },
    ResponseSuccess<SalesInputType>
  >;
  addSalesInputType: ActionType<SalesInputType, ResponseSuccess<SalesInputType>>;
  updateProjectedSales: ActionType<
    UpdateProjectedSalesRequest,
    ResponseSuccess<UpdateProjectedSalesRequest>
  >;
  updateSalesPercentageCap: ActionType<
    UpdateSalesPercentageCapRequest,
    ResponseSuccess<UpdateSalesPercentageCapRequest>
  >;
  updateCurrency: ActionType<
    { currencySymbol: string; id: CurrencyId },
    ResponseSuccess<{ id: CurrencyId; currencySymbol: string }>
  >;
  updateHourFormat: ActionType<UpdateHourFormatRequest, ResponseSuccess<UpdateHourFormatRequest>>;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

const initialState: InitialState = {
  fetchSalesInputTypes: {
    ...ACTIONS_INITIAL_STATE,
    data: [],
  },
  updateSalesInputType: {
    ...ACTIONS_INITIAL_STATE,
    data: { id: 0, new: { end_date: '', id: 0, is_active: 0, name: '', start_date: '' } },
  },
  addSalesInputType: {
    ...ACTIONS_INITIAL_STATE,
    data: { end_date: '', id: 0, is_active: 0, name: '', start_date: '' },
  },
  updateProjectedSales: {
    ...ACTIONS_INITIAL_STATE,
    data: {
      projection_computation_method_id: SalesProjectionMethod.COPY_FROM_LAST_WEEK,
      start_date: '',
    },
  },
  updateSalesPercentageCap: {
    ...ACTIONS_INITIAL_STATE,
    data: {
      percentage_cap: 0,
      start_date: '',
    },
  },
  updateCurrency: {
    ...ACTIONS_INITIAL_STATE,
    data: { id: CurrencyId.USD_DOLLAR, currencySymbol: '' },
  },
  updateHourFormat: {
    ...ACTIONS_INITIAL_STATE,
    data: { is12hr: 1 },
  },
};

const sortSales = (data: SalesInputType[]) =>
  _orderBy(data, ['is_active', 'start_date', 'end_date'], ['desc', 'desc', 'desc']);

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    fetchSalesInputTypesStarted: (state) => {
      state.fetchSalesInputTypes = { ...initialState.fetchSalesInputTypes, isLoading: true };
    },
    fetchSalesInputTypesError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchSalesInputTypes.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchSalesInputTypes.error = action.payload;
      } else {
        state.fetchSalesInputTypes.response = action.payload;
      }
    },
    fetchSalesInputTypesSuccess: (state, action: PayloadAction<SalesInputType[]>) => {
      state.fetchSalesInputTypes.isLoading = false;
      state.fetchSalesInputTypes.data = sortSales(action.payload);
    },

    updateSalesInputTypeStarted: (state) => {
      state.updateSalesInputType = { ...initialState.updateSalesInputType, isLoading: true };
    },
    updateSalesInputTyesError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateSalesInputType.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateSalesInputType.error = action.payload;
      } else {
        state.updateSalesInputType.response = action.payload;
      }
    },
    updateSalesInputTypeSuccess: (
      state,
      action: PayloadAction<{ id: number; response: ResponseSuccess<SalesInputType> }>,
    ) => {
      state.updateSalesInputType.isLoading = false;
      state.updateSalesInputType.data.id = action.payload.id;
      state.updateSalesInputType.data.new = action.payload.response.result;
      state.updateSalesInputType.response = action.payload.response;
      state.fetchSalesInputTypes.data = sortSales(
        state.fetchSalesInputTypes.data.map((sale) =>
          sale.id === action.payload.id ? { ...action.payload.response.result } : sale,
        ),
      );
    },

    addSalesInputTypeStarted: (state) => {
      state.addSalesInputType = { ...initialState.addSalesInputType, isLoading: true };
    },
    addSalesInputTypeError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.addSalesInputType.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.addSalesInputType.error = action.payload;
      } else {
        state.addSalesInputType.response = action.payload;
      }
    },
    addSalesInputTypeSuccess: (state, action: PayloadAction<ResponseSuccess<SalesInputType>>) => {
      state.addSalesInputType.isLoading = false;
      state.addSalesInputType.data = action.payload.result;
      state.addSalesInputType.response = action.payload;
      state.fetchSalesInputTypes.data.push(action.payload.result);
      state.fetchSalesInputTypes.data = sortSales(state.fetchSalesInputTypes.data);
    },

    updateProjectedSalesStarted: (state) => {
      state.updateProjectedSales = { ...initialState.updateProjectedSales, isLoading: true };
    },
    updateProjectedSalesError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateProjectedSales.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateProjectedSales.error = action.payload;
      } else {
        state.updateProjectedSales.response = action.payload;
      }
    },
    updateProjectedSalesSuccess: (
      state,
      action: PayloadAction<ResponseSuccess<UpdateProjectedSalesRequest>>,
    ) => {
      state.updateProjectedSales.isLoading = false;
      state.updateProjectedSales.data = action.payload.result;
      state.updateProjectedSales.response = action.payload;
    },

    updateSalesPercentageCapStarted: (state) => {
      state.updateSalesPercentageCap = {
        ...initialState.updateSalesPercentageCap,
        isLoading: true,
      };
    },
    updateSalesPercentageCapError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateSalesPercentageCap.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateSalesPercentageCap.error = action.payload;
      } else {
        state.updateSalesPercentageCap.response = action.payload;
      }
    },
    updateSalesPercentageCapSuccess: (
      state,
      action: PayloadAction<ResponseSuccess<UpdateSalesPercentageCapRequest>>,
    ) => {
      state.updateSalesPercentageCap.isLoading = false;
      state.updateSalesPercentageCap.data = action.payload.result;
      state.updateSalesPercentageCap.response = action.payload;
    },

    updateCurrencyStarted: (state) => {
      state.updateCurrency = {
        ...initialState.updateCurrency,
        isLoading: true,
      };
    },
    updateCurrencyError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateCurrency.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateCurrency.error = action.payload;
      } else {
        state.updateCurrency.response = action.payload;
      }
    },
    updateCurrencySuccess: (
      state,
      action: PayloadAction<ResponseSuccess<{ id: CurrencyId; currencySymbol: string }>>,
    ) => {
      state.updateCurrency.isLoading = false;
      state.updateCurrency.data = action.payload.result;
      state.updateCurrency.response = action.payload;
    },

    updateHourFormatStarted: (state) => {
      state.updateHourFormat = {
        ...initialState.updateHourFormat,
        isLoading: true,
      };
    },
    updateHourFormatError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.updateHourFormat.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.updateHourFormat.error = action.payload;
      } else {
        state.updateHourFormat.response = action.payload;
      }
    },
    updateHourFormatSuccess: (
      state,
      action: PayloadAction<ResponseSuccess<UpdateHourFormatRequest>>,
    ) => {
      state.updateHourFormat.isLoading = false;
      state.updateHourFormat.data = action.payload.result;
      state.updateHourFormat.response = action.payload;
    },

    resetManageSalesData: (state) => {
      state.addSalesInputType = { ...initialState.addSalesInputType };
      state.updateSalesInputType = { ...initialState.updateSalesInputType };
    },

    resetUpdateProjectedSalesState: (state) => {
      state.updateProjectedSales = { ...initialState.updateProjectedSales };
    },

    resetUpdateSalesPercentageCap: (state) => {
      state.updateSalesPercentageCap = { ...initialState.updateSalesPercentageCap };
    },

    clearSlices: (state) => {
      state.fetchSalesInputTypes = { ...initialState.fetchSalesInputTypes };
      state.updateSalesInputType = { ...initialState.updateSalesInputType };
      state.addSalesInputType = { ...initialState.addSalesInputType };
      state.updateProjectedSales = { ...initialState.updateProjectedSales };
      state.updateSalesPercentageCap = { ...initialState.updateSalesPercentageCap };
      state.updateCurrency = { ...initialState.updateCurrency };
      state.updateHourFormat = { ...initialState.updateHourFormat };
    },
  },
});

export default settings.reducer;

export const { actions } = settings;

export const selectFetchSalesInputTypesState = (state: State<InitialState>) =>
  state.settingsSlice.fetchSalesInputTypes;

export const selectAddSalesInputTypeState = (state: State<InitialState>) =>
  state.settingsSlice.addSalesInputType;

export const selectUpdateSalesInputTypeState = (state: State<InitialState>) =>
  state.settingsSlice.updateSalesInputType;

export const selectUpdateProjectedSalesState = (state: State<InitialState>) =>
  state.settingsSlice.updateProjectedSales;

export const selectUpdateSalesPercentageCapsState = (state: State<InitialState>) =>
  state.settingsSlice.updateSalesPercentageCap;

export const selectUpdateCurrencyState = (state: State<InitialState>) =>
  state.settingsSlice.updateCurrency;

export const selectUpdateHourFormatState = (state: State<InitialState>) =>
  state.settingsSlice.updateHourFormat;

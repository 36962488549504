import { useTheme, styled, Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { CSSObject } from '@emotion/react';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavLinks from '../Routes/NavLinks';
import { Config } from '../../config';

type AppDrawerProps = {
  open: boolean;
  handleDrawerClose: () => void;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: Config.DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '50px',
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: Config.DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '.MuiDrawer-paper': {
      backgroundColor: '#2b3238',
      '.MuiList-root': {
        height: '100%',
        position: 'relative',
        '.logout': {
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          width: '100%',
        },

        '.MuiListItem-root:hover': {
          backgroundColor: 'rgba(0,0,0, 0.2)',
        },

        '.MuiListItemIcon-root svg': {
          width: '21px',
          height: '21px',
        },

        '.MuiTypography-root': {
          color: '#FFF',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },

        '.active .MuiTypography-root': {
          color: theme.palette.primary.main,
        },

        '.sub-link .MuiTypography-root': {
          marginLeft: '24px',
        },
      },
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '.logout, .sub-link': {
        display: 'none',
      },
    }),
  }),
);

function AppDrawer(props: AppDrawerProps) {
  const theme = useTheme();
  const { open, handleDrawerClose } = props;
  return (
    <Drawer variant="permanent" open={open}>
      <Button
        onClick={handleDrawerClose}
        variant="text"
        sx={{ justifyContent: 'flex-end' }}
        endIcon={
          theme.direction === 'rtl' ? (
            <ChevronRightIcon htmlColor="#FFF" />
          ) : (
            <ChevronLeftIcon htmlColor="#FFF" />
          )
        }
      />

      <NavLinks open={open} />
    </Drawer>
  );
}

export default AppDrawer;

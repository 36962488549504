/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestError, ResponseError, ResponseSuccess } from '../../types/Response';
import { State } from '../../types/Store';
import utils from '../../shared/utils';
import { MonthlyRequestsData, RequestsCount, MonthlyRequestsResponse } from '../../types/Requests';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: Req;
}

export type InitialState = {
  fetchMonthlyRequests: ActionType<MonthlyRequestsData, ResponseSuccess<MonthlyRequestsResponse>>;
  fetchRequestsCount: ActionType<RequestsCount, ResponseSuccess<RequestsCount>>;
  date?: string;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
};

const initialState: InitialState = {
  fetchMonthlyRequests: { ...ACTIONS_INITIAL_STATE, data: [] },
  fetchRequestsCount: {
    ...ACTIONS_INITIAL_STATE,
    data: { exchange_pending: 0, off_pending: 0, exchange_approved: 0, off_approved: 0 },
  },
  date: undefined,
};

const updateRequest = (
  state: InitialState,
  date: string,
  count: {
    exchange_pending: number;
    off_pending: number;
    exchange_approved: number;
    off_approved: number;
  },
) => {
  const newCount: RequestsCount = { ...state.fetchRequestsCount.data };
  const newData = state.fetchMonthlyRequests.data.map((week) => {
    const newWeekData = week.map((day) => {
      if (day.date === date) {
        if (day.requests) {
          if (
            day.requests.exchange_pending === 1 &&
            count.exchange_pending === 0 &&
            newCount.exchange_pending > 0
          ) {
            newCount.exchange_pending -= 1;
          }
          if (
            day.requests.off_pending === 1 &&
            count.off_pending === 0 &&
            newCount.off_pending > 0
          ) {
            newCount.off_pending -= 1;
          }
        }
        return {
          ...day,
          requests: {
            ...day.requests,
            ...count,
          },
        };
      }
      return { ...day };
    });
    return newWeekData;
  });
  state.fetchMonthlyRequests.data = newData;
  state.fetchRequestsCount.data = newCount;
};

const getPendingRequestsCount = (data: MonthlyRequestsData) => {
  const result: RequestsCount = {
    exchange_pending: 0,
    off_pending: 0,
    exchange_approved: 0,
    off_approved: 0,
  };

  data?.forEach((week) => {
    week?.forEach((day) => {
      result.off_pending += +(day?.requests?.off_pending || 0);
      result.exchange_pending += +(day?.requests?.exchange_pending || 0);
    });
  });
  return result;
};

const RequestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    fetchMonthlyRequestsStarted: (state, action: PayloadAction<{ date: string }>) => {
      state.fetchMonthlyRequests = {
        ...initialState.fetchMonthlyRequests,
        isLoading: true,
      };
      state.date = action.payload.date;
    },
    fetchMonthlyRequestsError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchMonthlyRequests.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchMonthlyRequests.error = action.payload;
      } else {
        state.fetchMonthlyRequests.response = action.payload;
      }
    },
    fetchMonthlyRequestsSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<MonthlyRequestsResponse>;
        data: MonthlyRequestsData;
      }>,
    ) => {
      state.fetchMonthlyRequests.isLoading = false;
      state.fetchMonthlyRequests.data = action.payload.data;
      state.fetchRequestsCount.data = getPendingRequestsCount(action.payload.data);
    },
    fetchRequestsCountStarted: (state) => {
      state.fetchRequestsCount = {
        ...initialState.fetchRequestsCount,
        isLoading: true,
      };
    },
    fetchRequestsCountError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchRequestsCount.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchRequestsCount.error = action.payload;
      } else {
        state.fetchRequestsCount.response = action.payload;
      }
    },
    fetchRequestsCountSuccess: (
      state,
      action: PayloadAction<{
        response: ResponseSuccess<RequestsCount>;
      }>,
    ) => {
      state.fetchRequestsCount.isLoading = false;
      state.fetchRequestsCount.data = action.payload.response.result;
    },
    updateRequests: (
      state,
      action: PayloadAction<{
        date: string;
        count: RequestsCount;
      }>,
    ) => {
      updateRequest(state, action.payload.date, action.payload.count);
    },

    clearSlices: (state) => {
      state.fetchMonthlyRequests = { ...initialState.fetchMonthlyRequests };
      state.fetchRequestsCount = { ...initialState.fetchRequestsCount };
    },
  },
});

export default RequestsSlice.reducer;

export const requestsActions = RequestsSlice.actions;

export const selectFetchRequestsCountState = (state: State<InitialState>) =>
  state.requestsSlice.fetchRequestsCount;

export const selectFetchMonthlyRequestsState = (state: State<InitialState>) =>
  state.requestsSlice.fetchMonthlyRequests;

export const selectRequestsDateState = (state: State<InitialState>) => state.requestsSlice.date;

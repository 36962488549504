import { call, put, takeLatest, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { actions } from '../slices/shiftSlice';
import {
  AddShiftRequest,
  AddShiftResponse,
  DeleteMultiShiftRequest,
  DeleteShiftRequest,
  UpdateMultiShiftRequest,
  UpdateShiftRequest,
  WeekShifts,
} from '../../types/Shift';
import actionTypes from '../actionTypes/shift';
import utils from '../../shared/utils';
import { Response } from '../../types/Response';
import { isResponseSuccess } from '../../shared/utils/general';

function* fetchShifts({ payload }: PayloadAction<{ branchId: number }>) {
  try {
    yield put(actions.fetchShiftsStarted());
    const response: Response<WeekShifts[]> = yield call(() =>
      api.shifts.getShifts(payload.branchId),
    );
    if (isResponseSuccess(response.data)) {
      yield put(actions.fetchShiftstSuccess(response.data.result));
    } else {
      yield put(actions.fetchShiftsError(response.data));
    }
  } catch (error) {
    yield put(actions.fetchShiftsError(utils.general.convertAxiosError(error)));
  }
}

function* addShift({ payload }: PayloadAction<{ branchId: number; shift: AddShiftRequest }>) {
  try {
    yield put(actions.addShiftStarted());
    const response: Response<AddShiftResponse> = yield call(() =>
      api.shifts.postShift(payload.branchId, payload.shift),
    );
    if (isResponseSuccess(response.data)) {
      yield put(
        actions.addShiftSuccess({
          response: response.data,
          shift: payload.shift,
        }),
      );
    } else {
      yield put(actions.addShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.addShiftError(utils.general.convertAxiosError(error)));
  }
}

function* updateShift({ payload }: PayloadAction<{ branchId: number; shift: UpdateShiftRequest }>) {
  try {
    yield put(actions.updateShiftStarted(payload.shift));
    const response: Response<{ shift_id: number }> = yield call(() =>
      api.shifts.putShift(payload.branchId, payload.shift.id, payload.shift),
    );
    if (isResponseSuccess(response.data)) {
      yield put(
        actions.updateShiftSuccess({
          response: response.data,
          shift: payload.shift,
        }),
      );
    } else {
      yield put(actions.updateShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.updateShiftError(utils.general.convertAxiosError(error)));
  }
}

function* deleteShift({ payload }: PayloadAction<{ branchId: number; shift: DeleteShiftRequest }>) {
  try {
    yield put(actions.deleteShiftStarted());
    const response: Response<boolean> = yield call(() =>
      api.shifts.deleteShift(payload.branchId, payload.shift.id),
    );
    if (isResponseSuccess(response.data)) {
      yield put(actions.deleteShiftSuccess({ shift: payload.shift, response: response.data }));
    } else {
      yield put(actions.deleteShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.deleteShiftError(utils.general.convertAxiosError(error)));
  }
}

function* updateMultipleShift({
  payload,
}: PayloadAction<{ branchId: number; data: UpdateMultiShiftRequest }>) {
  try {
    yield put(actions.updateMultiShiftStarted(payload.data));
    const response: Response<number[]> = yield call(() =>
      api.shifts.updateMultiShift(payload.branchId, payload.data),
    );
    if (isResponseSuccess(response.data)) {
      yield put(
        actions.updateMultiShiftSuccess({
          response: response.data,
          data: payload.data,
        }),
      );
    } else {
      yield put(actions.updateMultiShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.updateMultiShiftError(utils.general.convertAxiosError(error)));
  }
}

function* deleteMultipleShift({
  payload,
}: PayloadAction<{ branchId: number; data: DeleteMultiShiftRequest }>) {
  try {
    yield put(actions.deleteMultiShiftStarted());
    const response: Response<boolean> = yield call(() =>
      api.shifts.deleteMultiShift(payload.branchId, payload.data),
    );
    if (isResponseSuccess(response.data)) {
      yield put(actions.deleteMultiShiftSuccess({ data: payload.data, response: response.data }));
    } else {
      yield put(actions.deleteMultiShiftError(response.data));
    }
  } catch (error) {
    yield put(actions.deleteMultiShiftError(utils.general.convertAxiosError(error)));
  }
}

function* watchManageShift() {
  yield all([
    takeLatest(actionTypes.FETCH_SHIFTS, fetchShifts),
    takeLatest(actionTypes.ADD_SHIFT, addShift),
    takeLatest(actionTypes.UPDATE_SHIFT, updateShift),
    takeLatest(actionTypes.DELETE_SHIFT, deleteShift),
    takeLatest(actionTypes.DELETE_MULTIPLE_SHIFT, deleteMultipleShift),
    takeLatest(actionTypes.UPDATE_MULTIPLE_SHIFT, updateMultipleShift),
  ]);
}

export default watchManageShift;

import { lazy } from 'react';
import Links from './Links';
import {
  SCHEDULE,
  REQUESTS,
  MANAGER_REPORTING,
  MANAGE_EMPLOYEES,
  MANAGE_POSITIONS,
  MANAGE_SHIFTS,
  NOTIFICATIONS,
  SETTINGS,
} from './NavLinksConfig';
import { Config } from '../../config';

// only private routes are exported
export default [
  {
    id: SCHEDULE.id,
    path: SCHEDULE.to,
    Element: lazy(() => import('../../modules/WeeklyScheduleDashboard/WeeklyScheduleDashboard')),
    requiredFeatures: SCHEDULE.requiredFeatures,
  },
  {
    id: 'DAILY_DASHBOARD',
    path: Links.DAILY_SCHEDULE_LINK,
    Element: lazy(() => import('../../modules/DailySchedule')),
    requiredFeatures: [Config.FEATURES_ID.DAILY_SCHEDULE],
  },
  {
    id: REQUESTS.id,
    path: REQUESTS.to,
    Element: lazy(() => import('../../modules/Requests/Requests')),
    requiredFeatures: REQUESTS.requiredFeatures,
  },
  {
    id: MANAGE_SHIFTS.id,
    path: MANAGE_SHIFTS.to,
    Element: lazy(() => import('../../modules/ManageShift/ManageShift')),
    requiredFeatures: MANAGE_SHIFTS.requiredFeatures,
  },
  {
    id: MANAGE_POSITIONS.id,
    path: MANAGE_POSITIONS.to,
    Element: lazy(() => import('../../modules/Positions')),
    requiredFeatures: MANAGE_POSITIONS.requiredFeatures,
  },
  {
    id: MANAGE_EMPLOYEES.id,
    path: MANAGE_EMPLOYEES.to,
    Element: lazy(() => import('../../modules/Employees/Employees')),
    requiredFeatures: MANAGE_EMPLOYEES.requiredFeatures,
  },
  {
    id: MANAGER_REPORTING.id,
    path: MANAGER_REPORTING.to,
    Element: lazy(() => import('../../modules/Reporting/Attendance/AttendanceReport')),
    requiredFeatures: MANAGER_REPORTING.requiredFeatures,
  },
  {
    id: NOTIFICATIONS.id,
    path: NOTIFICATIONS.to,
    Element: lazy(() => import('../../modules/Notifications')),
    requiredFeatures: NOTIFICATIONS.requiredFeatures,
  },
  {
    id: SETTINGS.id,
    path: SETTINGS.to,
    Element: lazy(() => import('../../modules/Settings')),
    requiredFeatures: SETTINGS.requiredFeatures,
  },
];

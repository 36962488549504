import { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import durationPlugin from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';
import { analytics } from '../../resources';
import storage from '../../storage';
import Setting, { getSettings, contextTypes, UserRoleSettings, ContextType } from './utility';
import useAuth from '../../hooks/useAuth';
import { SalesProjectionMethod } from '../../enums';
import { UserData } from '../../types/Authentications';
import utils from '../../shared/utils';

const SettingsContext = createContext({
  ...contextTypes,
});

type SettingsProviderTypes = {
  children: React.ReactNode;
};

export function SettingsProvider({ children }: SettingsProviderTypes) {
  const [settings, setSettings] = useState<UserRoleSettings>(Setting);
  const { auth, setAuth } = useAuth();

  const changeBranch = useCallback(
    (branchId: number) => {
      const derrivedSettings = getSettings(auth.userData, branchId);

      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.tz.setDefault(derrivedSettings.branch_timezone);
      dayjs.extend(relativeTime);
      dayjs.extend(durationPlugin);

      // update locale
      dayjs.extend(updateLocale);
      dayjs.updateLocale('en', {
        weekStart: derrivedSettings.first_weekday,
      });

      storage.setSelectedBranch(branchId);
      storage.setUserSelectedBranch(+auth.userData.id, branchId);
      storage.setSettings(derrivedSettings);
      setSettings((prev) => ({ ...prev, ...derrivedSettings }));
    },
    [auth.userData],
  );

  const changeCurrency = useCallback(
    (currencyId: number, currencySymbol: string): void => {
      const userRoleIndex = auth.userData.roles.findIndex(
        (role) => role.branch_id === settings.branch_id,
      );
      const userData: UserData = utils.general.cloneData(auth.userData);
      if (userRoleIndex > -1) {
        userData.roles[userRoleIndex].currency_id = currencyId;
        userData.roles[userRoleIndex].currency_symbol = currencySymbol;
      }

      setAuth((prev) => ({ ...prev, userData }));

      setSettings((prev) => ({
        ...prev,
        currency_id: currencyId,
        currency_symbol: currencySymbol,
      }));
    },
    [settings.branch_id, auth.userData, setAuth],
  );

  const changeHourFormat = useCallback(
    (hourFormat: number): void => {
      const userRoleIndex = auth.userData.roles.findIndex(
        (role) => role.branch_id === settings.branch_id,
      );
      const userData: UserData = utils.general.cloneData(auth.userData);
      if (userRoleIndex > -1) {
        userData.roles[userRoleIndex].is_12hr = hourFormat;
      }

      setAuth((prev) => ({ ...prev, userData }));

      setSettings((prev) => ({
        ...prev,
        is_12hr: hourFormat,
      }));
    },
    [settings.branch_id, auth.userData, setAuth],
  );

  const changeProjectedSalesMethod = useCallback(
    (projection_computation_method_id: SalesProjectionMethod): void => {
      const userRoleIndex = auth.userData.roles.findIndex(
        (role) => role.branch_id === settings.branch_id,
      );
      const userData: UserData = utils.general.cloneData(auth.userData);
      if (userRoleIndex > -1) {
        userData.roles[userRoleIndex].sales_projection_computation_method_id =
          +projection_computation_method_id;
      }

      setAuth((prev) => ({ ...prev, userData }));
      setSettings((prev) => ({
        ...prev,
        sales_projection_computation_method_id: +projection_computation_method_id,
      }));
    },
    [settings.branch_id, auth.userData, setAuth],
  );

  const changeSalesPercentageCap = useCallback(
    (percentage_cap: number): void => {
      const userRoleIndex = auth.userData.roles.findIndex(
        (role) => role.branch_id === settings.branch_id,
      );
      const userData: UserData = utils.general.cloneData(auth.userData);
      if (userRoleIndex > -1) {
        userData.roles[userRoleIndex].sales_percentage_cap = +percentage_cap;
      }

      setAuth((prev) => ({ ...prev, userData }));

      setSettings((prev) => ({
        ...prev,
        sales_percentage_cap: percentage_cap,
      }));
    },
    [auth.userData, setAuth, settings.branch_id],
  );

  useEffect(() => {
    if (auth.userData.id) {
      const accountId = +auth.userData.id;
      let selectedBranchId =
        storage.getUserSelectedBranch(accountId) || storage.getSelectedBranch();
      selectedBranchId = !Number.isNaN(selectedBranchId) ? Number(selectedBranchId) : 0;
      if (!auth.userData.roles.some((role) => role.branch_id === selectedBranchId)) {
        selectedBranchId = auth.userData.roles?.[0]?.branch_id;
      }
      const derrivedSettings = getSettings(auth.userData, selectedBranchId);
      storage.setUserSelectedBranch(accountId, selectedBranchId);
      storage.setSelectedBranch(selectedBranchId);
      storage.setSettings(derrivedSettings);

      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.tz.setDefault(derrivedSettings.branch_timezone);
      dayjs.extend(relativeTime);
      dayjs.extend(durationPlugin);

      // update locale
      dayjs.extend(updateLocale);
      dayjs.updateLocale('en', {
        weekStart: derrivedSettings.first_weekday,
      });

      setSettings(derrivedSettings);
    } else {
      setSettings(Setting);
    }
  }, [auth.userData, auth.userData.id, auth.userData.roles]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.userData.id) {
      if (hotjar.initialized()) {
        hotjar.identify(auth.userData.id, {
          email: auth.email,
          name: auth.userData.name,
        });
      }

      analytics.setUser({
        id: auth.userData.id,
        name: auth.userData.name,
        email: auth.email,
      });
    }
  }, [auth.isAuthenticated, auth.userData.id, auth.email, auth.userData.name]);

  const providerValue: ContextType = useMemo(
    () => ({
      ...settings,
      changeBranch,
      changeCurrency,
      changeHourFormat,
      changeProjectedSalesMethod,
      changeSalesPercentageCap,
    }),
    [
      settings,
      changeBranch,
      changeCurrency,
      changeHourFormat,
      changeProjectedSalesMethod,
      changeSalesPercentageCap,
    ],
  );
  return <SettingsContext.Provider value={providerValue}>{children}</SettingsContext.Provider>;
}

export default SettingsContext;

import api from './axios';
import { UserData } from '../types/Authentications';
import { Response } from '../types/Response';

const postLogin = (data: { email: string; password: string }) =>
  api.post('/login?type=manager', data) as Promise<Response<UserData>>;

const postGetUserBranches = (accountId: string) =>
  api.post(`/account/${accountId}/authenticate?type=manager`) as Promise<Response<UserData>>;

export default {
  postLogin,
  postGetUserBranches,
};

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResponseError, RequestError, ResponseSuccess } from '../../types/Response';
import utils from '../../shared/utils';
import {
  DailyScheduleData,
  DailyScheduleResponseData,
  FetchDailySchedulePayloadSuccess,
  DailyScheduleRole,
  DailyScheduleEmployee,
  DailyShiftsRequirements,
  UnAssignedEmployee,
  ColorsState,
  AssignedShift,
} from '../../types/DailySchedule';
import { AccountRole } from '../../types/Positions';
// eslint-disable-next-line import/extensions
import { State } from '../../types/Store';

interface ActionType<Req, Response> {
  response?: Response | ResponseError;
  isLoading: boolean;
  error?: RequestError;
  data: Req;
}

export type DateState = { [id: number]: string };

export type InitialState = {
  fetchDailySchedule: ActionType<DailyScheduleData, ResponseSuccess<DailyScheduleResponseData>>;
  date: DateState;
};

const ACTIONS_INITIAL_STATE = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

const SHIFT_COLORS = [
  '#CC99CC',
  '#FF6666',
  '#FF9A66',
  '#66CC66',
  '#99CC66',
  '#CC6666',
  '#66CC98',
  '#FF9900',
];

const getLatestRole: (roles: AccountRole[]) => AccountRole | undefined = (roles: AccountRole[]) => {
  let role = roles[0];
  let latestRoleStartTime = role?.start_date || '';
  roles.forEach((accRole) => {
    if (accRole.start_date > latestRoleStartTime) {
      latestRoleStartTime = accRole.start_date;
      role = { ...accRole };
    }
  });
  return role;
};

const getAssignedEmployee: (account: DailyScheduleEmployee) => DailyScheduleEmployee = (
  account,
) => ({
  first_name: account.first_name,
  last_name: account.last_name || '',
  id: account.id,
  schedule: account.schedule.sort((s1, s2) => s1.start_time.localeCompare(s2.start_time)),
});

const getDailyScheduleData = (data: DailyScheduleResponseData): DailyScheduleData => {
  const { accounts = [], shifts } = data;
  const offEmployees: UnAssignedEmployee[] = [];
  const unAssignedEmployees: UnAssignedEmployee[] = [];
  const assignedShifts: AssignedShift[] = [];
  const colors: ColorsState = {};

  let totalWage = 0;
  const roles: DailyScheduleRole[] = [];
  data.roles.forEach((role) => {
    const employees: DailyScheduleEmployee[] = [];
    let roleIndex = 0;
    accounts.forEach((account) => {
      const accountRole = getLatestRole(account.roles);
      const currentRoleIndex = account.roles.find((r) => r.id === role.id)?.role_index;
      if (!Number.isNaN(currentRoleIndex) && Number(currentRoleIndex) >= 0)
        roleIndex = Number(currentRoleIndex);

      // unassinged employees
      if (!account.schedule.length && accountRole?.id === role.id) {
        const isOff = account.off_request?.[0]?.is_approved;
        const unAssignedEmp: UnAssignedEmployee = {
          first_name: account.first_name,
          id: account.id,
          last_name: account.last_name || '',
          role: { ...accountRole },
        };
        if (isOff) offEmployees.push(unAssignedEmp);
        else unAssignedEmployees.push(unAssignedEmp);
      }

      // assigned employees
      if (accountRole && account.schedule.length && accountRole.id === role.id) {
        let workingHours = 0;
        const schedule = account.schedule.map((sched) => {
          const { name, id } = sched.shift;

          // add shift color to colors state if not added
          if (!colors[id]) {
            colors[id] = SHIFT_COLORS[Object.keys(colors).length % 8];
          }

          // calculate working hours
          workingHours += utils.date.getTimeDuration(sched.start_time, sched.end_time) || 0;

          // get assigned shifts
          const shiftIndex = assignedShifts.findIndex((sh) => sh.id === id);
          const newData: AssignedShift = {
            id,
            name,
            color: colors[id],
            count: { [role.name]: 0, ...assignedShifts?.[shiftIndex]?.count },
            requirement: {
              ...assignedShifts?.[shiftIndex]?.requirement,
              [role.name]:
                assignedShifts?.[shiftIndex]?.requirement?.[role.name] ||
                shifts
                  .find((shift) => shift.id === id)
                  ?.role_requirements.find((rl) => rl.role_id === role.id)?.num ||
                0,
            },
          };
          newData.count[role.name] += 1;
          if (shiftIndex === -1) assignedShifts.push(newData);
          else assignedShifts[shiftIndex] = newData;

          return { ...sched, shift: { ...sched.shift, color: colors[id] } };
        });
        totalWage += workingHours * +accountRole.hourly_wage;

        const assignedEmployee = getAssignedEmployee({ ...account, schedule });
        employees.push(assignedEmployee);
      }
    });
    roles.push({ ...role, employees, index: roleIndex });
  });

  // get shift requirements
  const shiftRequirements: DailyShiftsRequirements = {};
  shifts.forEach(({ id, role_requirements: roleRequirements }) => {
    shiftRequirements[id] = [...roleRequirements];
  });

  return {
    roles: utils.position.sortPositions(roles),
    off: offEmployees,
    unassigned: unAssignedEmployees,
    wage: totalWage,
    shifts_requirements: shiftRequirements,
    assignedShifts,
    colors,
  };
};

const initialState: InitialState = {
  fetchDailySchedule: {
    ...ACTIONS_INITIAL_STATE,
    data: {
      off: [],
      roles: [],
      shifts_requirements: {},
      unassigned: [],
      wage: 0,
      assignedShifts: [],
      colors: {},
    },
  },
  date: {},
};

const dailySchedule = createSlice({
  name: 'dailySchedule',
  initialState,
  reducers: {
    fetchDailyScheduleStarted: (
      state,
      action: PayloadAction<{ branchId: number; date: string }>,
    ) => {
      state.fetchDailySchedule = { ...initialState.fetchDailySchedule, isLoading: true };
      state.date[action.payload.branchId] = action.payload.date;
    },
    fetchDailyScheduleError: (state, action: PayloadAction<RequestError | ResponseError>) => {
      state.fetchDailySchedule.isLoading = false;
      if (utils.general.isRequestError(action.payload)) {
        state.fetchDailySchedule.error = action.payload;
      } else {
        state.fetchDailySchedule.response = action.payload;
      }
    },
    fetchDailyScheduleSuccess: (state, action: PayloadAction<FetchDailySchedulePayloadSuccess>) => {
      state.fetchDailySchedule.isLoading = false;
      state.date[action.payload.branchId] = action.payload.date;
      state.fetchDailySchedule.data = getDailyScheduleData(action.payload.response.result);
    },
  },
});

export default dailySchedule.reducer;

export const { fetchDailyScheduleError, fetchDailyScheduleStarted, fetchDailyScheduleSuccess } =
  dailySchedule.actions;

export const selectFetchDailyScheduleState = (state: State<InitialState>) =>
  state.dailyScheduleSlice.fetchDailySchedule;

export const selectDailyScheduleDate = (state: State<InitialState>) =>
  state.dailyScheduleSlice.date;

import { PaymentType } from '../enums';

type Payment = {
  id: number;
  label: string;
  abbr: string;
};

type PaymentObj = {
  HOURLY: Payment;
  PER_SHIFT: Payment;
  PER_DAY: Payment;
  MONTHLY: Payment;
};

const HOURLY = { id: PaymentType.HOURLY, label: 'Hourly', abbr: 'h' };
const PER_SHIFT = { id: PaymentType.PER_SHIFT, label: 'Per Shift', abbr: 'shift' };
const MONTHLY = { id: PaymentType.MONTHLY, label: 'Monthly', abbr: 'month' };
const PER_DAY = { id: PaymentType.PER_DAY, label: 'Per Day', abbr: 'day' };

export const PAYMENTS: PaymentObj = {
  HOURLY,
  PER_SHIFT,
  MONTHLY,
  PER_DAY,
} as const;

export default {
  [PAYMENTS.HOURLY.id]: PAYMENTS.HOURLY,
  [PAYMENTS.PER_SHIFT.id]: PAYMENTS.PER_SHIFT,
  [PAYMENTS.MONTHLY.id]: PAYMENTS.MONTHLY,
  [PAYMENTS.PER_DAY.id]: PAYMENTS.PER_DAY,
};

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { texts } from '../../../../resources';
import './NotificationItem.scss';

type NotificationItemProps = {
  id: number;
  title: string;
  message: string;
  time: string;
  read: number;
  onUpdate: (readStatus: number, id: number, redirect: boolean) => void;
};

function NotificationItem(props: NotificationItemProps) {
  const { id, title, message, time, read, onUpdate } = props;
  return (
    <Button
      fullWidth
      variant="text"
      onClick={() => {
        if (read === 0) {
          onUpdate(1, id, true);
        }
      }}
      className={`notification-item notification-item-${read}`}
    >
      <div className="notification-item-top">
        <div className="notification-word-wrap notification-item-title">{title}</div>
        <div className="notification-word-wrap notification-item-name">{message}</div>
      </div>
      <Tooltip
        classes={{ tooltip: 'notification-tooltip' }}
        title={read === 0 ? texts.MARK_NOTIFICATION_AS_READ : texts.MARK_NOTIFICATION_AS_UNREAD}
        placement="right"
      >
        <IconButton
          className="notification-item-toggle"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            event.stopPropagation();
            onUpdate(+!read, id, false);
          }}
        />
      </Tooltip>
      <span className="notification-item-content">{time}</span>
    </Button>
  );
}

export default NotificationItem;

import { call, takeLatest, put, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { sliceActions } from '../slices/punchSlice';
import { Response } from '../../types/Response';
import utils from '../../shared/utils';
import { EditPunchData, AddPunchData } from '../../types/Punch';
import ActionTypes from '../actionTypes/punch';
import { AddPunchResponse, PunchHistory } from '../../api/punch';
import { PunchType } from '../../api/reporting';

function* updatePunch({ payload }: PayloadAction<EditPunchData>) {
  try {
    const {
      branchId,
      accountId,
      punchId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      date_time,
      manager_id: managerId,
      note,
      type,
    } = payload;
    yield put(sliceActions.updatePunchStarted());
    const response: Response<number | boolean> = yield call(() =>
      api.punch.updatePunch(branchId, accountId, punchId, {
        date_time,
        manager_id: managerId,
        note,
        type,
      }),
    );
    if (utils.general.isResponseSuccess(response.data)) {
      yield put(sliceActions.updatePunchSuccess({ response: response.data, req: payload }));
    } else {
      yield put(sliceActions.updatePunchError(response.data));
    }
  } catch (error) {
    yield put(sliceActions.updatePunchError(utils.general.convertAxiosError(error)));
  }
}

function* addPunch({ payload }: PayloadAction<AddPunchData>) {
  try {
    const {
      branch_id: branchId,
      account_id: accountId,
      manager_id: managerId,
      addedMissingPunches,
      punch,
    } = payload;
    yield put(sliceActions.addPunchStarted());
    const res: Response<AddPunchResponse> = yield call(() =>
      api.punch.addPunch(branchId, managerId, accountId, punch),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(
        sliceActions.addPunchSuccess({
          response: res.data,
          addedMissingPunches,
          req: payload,
        }),
      );
    } else {
      yield put(sliceActions.addPunchError(res.data));
    }
  } catch (error) {
    yield put(sliceActions.addPunchError(utils.general.convertAxiosError(error)));
  }
}

function* fetchPunchHistory({
  payload,
}: PayloadAction<{
  branchId: number;
  accountId: number;
  punchId: number;
  type: PunchType;
}>) {
  try {
    yield put(sliceActions.fetchPunchHistoryStarted());
    const res: Response<PunchHistory> = yield call(() =>
      api.punch.fetchEditHistory(
        payload.branchId,
        payload.accountId,
        payload.punchId,
        payload.type,
      ),
    );
    if (utils.general.isResponseSuccess(res.data)) {
      yield put(sliceActions.fetchPunchHistorySuccess(res.data));
    } else {
      yield put(sliceActions.fetchPunchHistoryError(res.data));
    }
  } catch (error) {
    yield put(sliceActions.fetchPunchHistoryError(utils.general.convertAxiosError(error)));
  }
}

function* watchManagePunch() {
  yield all([
    takeLatest(ActionTypes.FETCH_PUNCH_HISTORY, fetchPunchHistory),
    takeLatest(ActionTypes.EDIT_PUNCH, updatePunch),
    takeLatest(ActionTypes.ADD_PUNCH, addPunch),
  ]);
}

export default watchManagePunch;

import { Sales, UpdateWeeklySalesInputTypeData } from '../types/Sales';
import {
  AddSalesTypeRequest,
  SalesInputType,
  UpdateProjectedSalesRequest,
  UpdateSalesPercentageCapRequest,
} from '../types/Settings';
import api from './axios';
import { Response } from '../types/Response';

const getWeekSales = (branchId: number, date: string) =>
  api.get(`/branch/${branchId}/sales_week?date=${date}`) as Promise<Response<Sales>>;

const postSales = (branchId: number, data: UpdateWeeklySalesInputTypeData) =>
  api.post(`/branch/${branchId}/sales`, data) as Promise<Response<boolean>>;

const getSalesInputTypes = (branchId: number) =>
  api.get(`/branch/${branchId}/sales_input_type`) as Promise<Response<SalesInputType[]>>;

const postSalesInputType = (branchId: number, data: AddSalesTypeRequest) =>
  api.post(`/branch/${branchId}/sales_input_type`, data) as Promise<Response<SalesInputType>>;

const putSalesInputType = (
  branchId: number,
  salesInputTypeId: number,
  data: {
    is_active: number;
    date: string;
  },
) =>
  api.put(`/branch/${branchId}/sales_input_type/${salesInputTypeId}`, data) as Promise<
    Response<SalesInputType>
  >;

const postSalesPercentageCap = (branchId: number, data: UpdateSalesPercentageCapRequest) =>
  api.post(`/branch/${branchId}/sales_percentage_cap`, data) as Promise<Response<string>>;

const postSalesProjectionComputation = (branchId: number, data: UpdateProjectedSalesRequest) =>
  api.post(`/branch/${branchId}/sales_projection_computation`, data) as Promise<Response<string>>;

export default {
  getSalesInputTypes,
  getWeekSales,
  postSales,
  postSalesInputType,
  postSalesPercentageCap,
  postSalesProjectionComputation,
  putSalesInputType,
};

import { FC } from 'react';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';

interface LeftRightArrowsProps {
  handleLeftClick: () => void;
  handleRightClick: () => void;
  disabled?: boolean;
}

const LeftRightArrows: FC<LeftRightArrowsProps> = function LeftRightArrows(
  props: LeftRightArrowsProps,
) {
  const { handleLeftClick, handleRightClick, disabled } = props;
  return (
    <div className="arrow-container">
      <IconButton disabled={disabled} onClick={handleLeftClick}>
        <ArrowBackIos htmlColor="#757575" style={{ width: '18px', height: '18px' }} />
      </IconButton>
      <IconButton disabled={disabled} onClick={handleRightClick}>
        <ArrowForwardIos htmlColor="#757575" style={{ width: '18px', height: '18px' }} />
      </IconButton>
    </div>
  );
};

LeftRightArrows.defaultProps = {
  disabled: false,
};

export default LeftRightArrows;

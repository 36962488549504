import { GenericResponse, Response } from '../types/Response';
import api from './axios';

export type PunchType = 'IN' | 'OUT';

interface PunchData {
  date_time: string;
  note: string | null;
}

export type AddPunchRequest = { in?: PunchData; out?: PunchData };

export type AddPunchResponse =
  | { in_id: number }
  | { out_id: number }
  | { in_id: number; out_id: number };

export interface UpdatePunchRequest extends PunchData {
  manager_id: number;
  type: PunchType;
}

export type AddMultiPunchRequest = {
  punches: {
    [account_id: string]: {
      [branch_id: string]: {
        date: string;
        position_id: number;
        index: number;
        in?: { date_time: string; note: string | null };
        out?: { date_time: string; note: string | null };
      }[];
    };
  };
};

export type AddMultiPunchResponseErrorData = {
  account_id: number;
  branch_id: number;
  message: string;
  punch: {
    position_id: number;
    date: string;
    index: number;
    in?: { date_time: string; note: string | null };
    out?: { date_time: string; note: string | null };
  };
}[];

export type PunchHistory = {
  id: number;
  date_time: string;
  date_created: string;
  note: string | null;
  is_valid: 1 | 0;
  distance: number | null;
  is_original: boolean;
  manager_id: number | null;
  manager_first_name: string | null;
  manager_last_name: string | null;
  manager_note: string | null;
}[];

const fetchEditHistory = (branchId: number, accountId: number, punchId: number, type: PunchType) =>
  api.get(
    `punching/${branchId}/account/${accountId}/punch/${punchId}/history?type=${type}`,
  ) as Promise<Response<PunchHistory>>;

const updatePunch = (
  branchId: number,
  accountId: number,
  punchId: number,
  data: UpdatePunchRequest,
) =>
  api.post(`reporting/${branchId}/account/${accountId}/punch/${punchId}`, data) as Promise<
    Response<number>
  >;

const addPunch = (branchId: number, managerId: number, accountId: number, data: AddPunchRequest) =>
  api.post(
    `reporting/${branchId}/manager/${managerId}/account/${accountId}/punch/v1`,
    data,
  ) as Promise<Response<AddPunchResponse>>;

const addMultiPunch = (branchId: number, managerId: number, data: AddMultiPunchRequest) =>
  api.post(`reporting/${branchId}/manager/${managerId}/punches`, data) as Promise<
    GenericResponse<boolean, AddMultiPunchResponseErrorData>
  >;

export default {
  fetchEditHistory,
  addMultiPunch,
  addPunch,
  updatePunch,
};

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { texts } from '../../../../resources';
import NotificationItem from '../NotificationItem/NotificationItem';
import Links from '../../../Routes/Links';
import GoIconIcon from '../../../../assets/icons/goIcon.svg';
import {
  fetchNotifications,
  updateNotification,
  updateNotificationMulti,
} from '../../../../store/actions/notifications';
import useAuth from '../../../../hooks/useAuth';
import {
  selectGetNotificationsState,
  selectUpdateNotificationState,
} from '../../../../store/slices/notificationsSlice';
import useAlertSnackbar from '../../../../hooks/useAlertSnackbar';
import { ResponseStatus } from '../../../../enums';
import utils from '../../../../shared/utils';
import useDispatchWhenConnectionIsBack from '../../../../hooks/useDispatchWhenConnectionIsBack';

import './NotificationPopover.scss';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: 6,
    right: '2px',
    fontSize: '9px',
    minWidth: '15px',
    height: '18px',
    width: '18px',
  },
}));

function NotificationPopover() {
  const [showNotificationPopup, setShowNotoficationsPopup] = useState(false);
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const { data, isLoading, error: fetchError } = useSelector(selectGetNotificationsState);
  const { error, response } = useSelector(selectUpdateNotificationState);
  const { setError } = useAlertSnackbar();

  const notificationCount = useMemo(() => {
    return data.filter((elem) => elem.read === 0).length;
  }, [data]);

  useEffect(() => {
    dispatch(fetchNotifications(auth.userData.id, null, 0, 9999));
  }, [dispatch, auth.userData.id]);

  useDispatchWhenConnectionIsBack({
    actionCreator: fetchNotifications,
    isFetchingData: isLoading,
    error: fetchError,
    args: [auth.userData.id, null, 0, 9999],
  });

  useEffect(() => {
    if (error?.message || (response?.status === ResponseStatus.Fail && response.user_message))
      setError(response?.user_message || error?.message);
  }, [error?.message, response?.status, response?.user_message, setError]);

  const handleClickAway = useCallback(() => {
    if (showNotificationPopup) {
      setShowNotoficationsPopup(false);
    }
  }, [showNotificationPopup]);

  const handleUpdateNotification = useCallback(
    (readStatus: number, id: number, redirect: boolean) => {
      dispatch(updateNotification(readStatus, id));
      if (redirect) {
        setShowNotoficationsPopup(false);
      }
    },
    [dispatch],
  );

  const markPendingAsSeen = useCallback(
    (ids: number[]) => {
      dispatch(
        updateNotificationMulti(
          ids.map(() => 1),
          ids,
        ),
      );
    },
    [dispatch],
  );

  const handleOpenNotificationPopUp = useCallback(() => {
    setShowNotoficationsPopup((prev) => !prev);
    if (!showNotificationPopup) {
      const pendingNotificationsIds = data.filter((e) => e.read === 0).map((e) => e.id);
      markPendingAsSeen(pendingNotificationsIds);
    }
  }, [setShowNotoficationsPopup, markPendingAsSeen, data, showNotificationPopup]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="notification-popover">
        <IconButton
          sx={{ width: '30px', height: '30px' }}
          onClick={handleOpenNotificationPopUp}
          size="small"
        >
          <StyledBadge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            max={99}
            badgeContent={notificationCount}
            color="error"
          >
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
        {showNotificationPopup && (
          <div className="filter-popup">
            <div className="down-arrow" />
            <div className="title">
              {notificationCount > 0
                ? `${notificationCount} Pending Notifications`
                : 'No Pending Notifications'}
              <NavLink
                className="edit-shift"
                to={Links.NOTIFICATIONS_LINK}
                onClick={() => setShowNotoficationsPopup(false)}
              >
                <img
                  alt={texts.GO_TO_NOTIFICATIONS}
                  src={GoIconIcon}
                  className="go-to-notifications-img"
                />
              </NavLink>
            </div>
            <div className="filter-content">
              {data.map((item) => {
                const payload = JSON.parse(item.payload);
                const title = payload.title?.en || payload.title;
                const body = payload.body?.en || payload.body;
                const time = utils.date.fromNow(item.date_created);

                return (
                  <NotificationItem
                    key={item.id}
                    id={item.id}
                    title={title}
                    message={body}
                    read={item.read}
                    onUpdate={handleUpdateNotification}
                    time={time}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default NotificationPopover;

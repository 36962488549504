import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  FC,
  memo,
  forwardRef,
} from 'react';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const SlideTransition: FC<SlideProps> = memo(function SlideTransition(props: SlideProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction="left" />;
});

const Alert: FC<AlertProps> = memo(
  forwardRef((props: AlertProps, ref) => {
    // eslint-disable-next-line react/prop-types
    const { severity = 'error' } = props;

    return (
      <MuiAlert
        elevation={6}
        ref={ref}
        variant="standard"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        slotProps={{
          closeIcon: {
            color: severity === 'error' ? 'error' : 'primary',
          },
        }}
      />
    );
  }),
);

interface TransitionsSnackbarProps extends SnackbarProps {
  error: boolean;
  message?: string;
  handleClose: () => void;
}

const TransitionsSnackbar: FC<TransitionsSnackbarProps> = memo(function TransitionsSnackbar(
  props: TransitionsSnackbarProps,
) {
  const { open, handleClose, message, error } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClick={(e) => {
        if (e?.preventDefault) {
          e.preventDefault();
        }
      }}
      onClose={(e) => {
        if (e?.preventDefault) {
          e.preventDefault();
        }
        handleClose();
      }}
      TransitionComponent={SlideTransition}
      message={message}
    >
      <Alert
        onClose={(e) => {
          e.preventDefault();
          handleClose();
        }}
        severity={error ? 'error' : 'success'}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
});

TransitionsSnackbar.defaultProps = {
  message: '',
};

type ContextType = {
  error?: string;
  successMessage?: string;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  removeSuccessMessage: () => void;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  removeError: () => void;
};

const contextDefaultState: ContextType = {
  error: '',
  successMessage: '',
  setSuccessMessage: () => {},
  removeSuccessMessage: () => {},
  setError: () => {},
  removeError: () => {},
};

const AlertContext = createContext({
  ...contextDefaultState,
});

interface AlertProviderProps {
  children: React.ReactNode;
}

export const AlertProvider: FC<AlertProviderProps> = function AlertProvider(
  props: AlertProviderProps,
) {
  const [error, setError] = useState<string>();
  const [showAler, setShowAlert] = useState<boolean>();
  const [successMessage, setSuccessMessage] = useState<string>();

  const removeSuccessMessage = useCallback(() => setSuccessMessage(''), []);
  const removeError = useCallback(() => setError(''), []);

  const { children } = props;

  const handleCloseAlert = useCallback(() => {
    setShowAlert(false);
  }, []);

  const providerValue = useMemo(
    () => ({
      error,
      removeError,
      setError,
      successMessage,
      setSuccessMessage,
      removeSuccessMessage,
    }),
    [error, removeError, removeSuccessMessage, successMessage],
  );

  useEffect(() => {
    if (error || successMessage) setShowAlert(true);
  }, [error, successMessage]);

  useEffect(() => {
    if (!showAler) {
      const timeOut = setTimeout(() => {
        if (successMessage) setSuccessMessage('');
        if (error) setError('');
      }, 200);

      return () => {
        clearTimeout(timeOut);
      };
    }
    return () => {};
  }, [error, showAler, successMessage]);

  return (
    <AlertContext.Provider value={providerValue}>
      <TransitionsSnackbar
        message={error || successMessage}
        open={showAler && !!(error || successMessage)}
        error={!!error}
        handleClose={handleCloseAlert}
      />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;

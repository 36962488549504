import BadgeIcon from '@mui/icons-material/Badge';
import CalendarViewIcon from '@mui/icons-material/CalendarViewMonth';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapHoizIcon from '@mui/icons-material/SwapHoriz';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { Config } from '../../config';
import Links from './Links';

export type NavLinkType = {
  id: string;
  label: string;
  to?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
  requiredFeatures: number[];
  navItems?: NavLinkType[];
};

export const SCHEDULE: NavLinkType = {
  id: 'SCHEDULE',
  label: 'Schedule',
  to: Links.WEEKLY_SCHEDULE_LINK,
  Icon: CalendarViewIcon,
  requiredFeatures: [],
};

export const REQUESTS: NavLinkType = {
  id: 'REQUESTS',
  label: 'Requests',
  to: Links.REQUESTS_LINK,
  Icon: SwapHoizIcon,
  requiredFeatures: [Config.FEATURES_ID.EXCHANGE_REQUEST, Config.FEATURES_ID.OFF_DAY],
};

export const MANAGE_SHIFTS: NavLinkType = {
  id: 'MANAGE_SHIFTS',
  label: 'Shifts',
  to: Links.MANAGE_SHIFTS_LINK,
  requiredFeatures: [],
};

export const MANAGE_POSITIONS: NavLinkType = {
  id: 'MANAGE_POSITIONS',
  label: 'Positions',
  to: Links.MANAGE_POSITIONS_LINK,
  requiredFeatures: [],
};

export const MANAGE_EMPLOYEES: NavLinkType = {
  id: 'MANAGE_EMPLOYEES',
  label: 'Employees',
  to: Links.MANAGE_EMPLOYEES_LINK,
  requiredFeatures: [],
};

const MANAGE_NAV: NavLinkType = {
  id: 'MANAGER_NAV',
  label: 'Manage',
  Icon: BadgeIcon,
  navItems: [MANAGE_SHIFTS, MANAGE_POSITIONS, MANAGE_EMPLOYEES],
  requiredFeatures: [],
};

export const MANAGER_REPORTING: NavLinkType = {
  id: 'MANAGER_REPORTING',
  label: 'Attendance Report',
  to: Links.MANAGER_REPORTING_LINK,
  Icon: InsertChartIcon,
  requiredFeatures: [Config.FEATURES_ID.REPORTING],
};

export const NOTIFICATIONS: NavLinkType = {
  id: 'NOTIFICATIONS',
  label: 'Notifications',
  to: Links.NOTIFICATIONS_LINK,
  Icon: NotificationsIcon,
  requiredFeatures: [],
};

export const SETTINGS: NavLinkType = {
  id: 'SETTINGS',
  label: 'Settings',
  to: Links.SETTINGS_LINK,
  Icon: SettingsIcon,
  requiredFeatures: [],
};

export default [SCHEDULE, REQUESTS, MANAGE_NAV, MANAGER_REPORTING, NOTIFICATIONS, SETTINGS];

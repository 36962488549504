import ReactGA from 'react-ga';
import Mixpanel from 'mixpanel-browser';
import pageViews from './pageViews';
import categories from './eventCategories';
import { Constants } from '../../constants';

const NODE_ENV = Constants.NODE_ENV.PRODUCTION;

const init = () => {
  if (import.meta.env.MODE === NODE_ENV) {
    Mixpanel.init('acad237ec44362c702857f9b91e7c2ed');
    ReactGA.initialize('UA-133343088-1');
  } else {
    // Mixpanel.init('de87d0bfe83dcf2186bca588958d9269');
  }
};

const setUser = (user: { id: string; name: string; email: string }) => {
  if (import.meta.env.MODE === NODE_ENV) {
    Mixpanel.identify(user.id);
    Mixpanel.people.set({
      $email: user.email,
      USER_ID: user.id,
      name: user.name,
    });

    ReactGA.set({ userId: user.id });
  }
};

const sendAnalyticEvent: (category: string, action?: string, data?: unknown[]) => void = (
  category,
  action = '',
  data = [],
) => {
  if (import.meta.env.MODE === NODE_ENV) {
    ReactGA.event({ category, action, ...data });
    Mixpanel.track(category, { action, ...data });
  }
};

const trackPageView = (pageName: string) => {
  if (import.meta.env.MODE === NODE_ENV) {
    ReactGA.pageview(window.location.pathname);
    Mixpanel.track('Page View', { page: pageName });
  }
};

const logout = () => {
  if (import.meta.env.MODE === NODE_ENV) {
    Mixpanel.reset();
  }
};

export default {
  setUser,
  sendAnalyticEvent,
  init,
  trackPageView,
  pageViews,
  categories,
  logout,
};

export const ADD_POSITION = 'manage/positions/add';
export const UPDATE_POSITION = 'manage/positions/update';
export const UPDATE_POSITION_INDEX = 'manage/positions/index/update';
export const DELETE_POSITION = 'manage/positions/delete';
export const GET_POSITIONS = 'manage/positions/get';

export default {
  ADD_POSITION,
  UPDATE_POSITION,
  DELETE_POSITION,
  GET_POSITIONS,
  UPDATE_POSITION_INDEX,
};

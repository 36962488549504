import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { usePreviousPersistent } from '.';
import useNetworkStatus from './useNetworkStatus';
import { RequestError } from '../types/Response';
import { RequestErrorCode } from '../enums';
import { Constants } from '../constants';

interface Props<T extends unknown[]> {
  actionCreator: (...args: T) => AnyAction;
  args: T;
  error?: RequestError;
  isFetchingData: boolean;
}

const useDispatchWhenConnectionIsBack = <T extends unknown[]>({
  actionCreator,
  args,
  error,
  isFetchingData,
}: Props<T>) => {
  const { isOnline } = useNetworkStatus();
  const prevWorkStatus = usePreviousPersistent(isOnline);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeOut: number;
    if (
      !prevWorkStatus &&
      isOnline &&
      error?.code === RequestErrorCode.ERR_NETWORK &&
      !isFetchingData
    ) {
      timeOut = setTimeout(() => dispatch(actionCreator(...args)), Constants.PING_GOOGLE_TIMEOUT);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [actionCreator, args, dispatch, error?.code, isFetchingData, isOnline, prevWorkStatus]);
};

export default useDispatchWhenConnectionIsBack;

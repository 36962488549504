export const GET_SALES_INPUT_TYPES = 'settings/sales/get';
export const UPDATE_SALES_INPUT_TYPE = 'settings/sales/update';
export const ADD_SALES_INPUT_TYPE = 'settings/sales/add';
export const UPDATE_PROJECTED_SALES_COMPUTATION_METHOD =
  'settings/projected/sales/computation/update';
export const UPDATE_SALES_PERCENTAGE_CAP = 'settings/sales/cap/update';
export const UPDATE_CURRENCY = 'settings/currency/update';
export const UPDATE_HOUR_FORMAT = 'settings/hour_format/update';

export default {
  GET_SALES_INPUT_TYPES,
  UPDATE_SALES_INPUT_TYPE,
  ADD_SALES_INPUT_TYPE,
  UPDATE_PROJECTED_SALES_COMPUTATION_METHOD,
  UPDATE_SALES_PERCENTAGE_CAP,
  UPDATE_CURRENCY,
  UPDATE_HOUR_FORMAT,
};

import {
  AddOpenShiftData,
  AddOpenShiftRequestData,
  EmployeesUnAvailability,
  EmployeesUnAvailabilityFromSchedule,
  OpenShiftEmployee,
  OpenShiftFormData,
  OpenShiftFormRole,
  OpenShiftRequest,
  UpdateOpenShiftData,
  UpdateOpenShiftRequestData,
} from '../../types/OpenShift';
import shiftUtils from './shift';
import dateUtils from './date';
import generalUtils from './general';
import { WeeklyScheduleData } from '../../types/WeeklySchedule';

export const getAddOpenShiftRequestData: (data: AddOpenShiftData) => AddOpenShiftRequestData = (
  data,
) => ({
  account_id: data.account_id,
  date: data.date,
  employees:
    data.role.employees.length === data.employees.length ? [] : data.employees.map((emp) => emp.id),
  end_time: data.end_time,
  start_time: data.start_time,
  is_close: data.is_close,
  number_of_employees: data.number_of_employees,
  role_id: data.role.id,
  ...(data.break_time ? { break_time: data.break_time } : {}),
  note: data.note,
  ...(data.payment_type
    ? {
        payment_type: data.payment_type,
        wage: data.wage,
      }
    : {}),
  ...(data.shift.id === null
    ? { custom_shift_name: data.shift.name }
    : { shift_id: data.shift.id }),
});

export const getUpdateOpenShiftRequestData: (
  data: UpdateOpenShiftData,
) => UpdateOpenShiftRequestData = (data) => ({
  account_id: data.account_id,
  date: data.date,
  ...(data.employees
    ? {
        employees: data?.employees.map((emp) => emp.id),
      }
    : {}),
  ...(data.number_of_employees ? { number_of_employees: data.number_of_employees } : {}),
  ...('note' in data ? { note: data.note } : {}),
});

const getEmployeesUnAvailabilityFromSchedule = (data: WeeklyScheduleData, date: string) => {
  const result: EmployeesUnAvailabilityFromSchedule = {};
  const filteredDays = [
    dateUtils.setDate(date, { subtractDays: 1 }),
    date,
    dateUtils.setDate(date, { addDays: 1 }),
  ];

  data.forEach((role) => {
    result[role.id] = [];
    role.data.forEach((employee) => {
      const schedules = employee.schedules.filter((_sched) => filteredDays.includes(_sched.date));
      const unavailabilities: { start_datetime: string; end_datetime: string }[] = [];
      schedules.forEach((sched) => {
        if (sched?.schedule?.length) {
          sched?.schedule.forEach((shift) => {
            const { startTime, endTime } = shiftUtils.getShiftIntervalDateTime(
              sched.date,
              shift.start_time,
              shift.end_time,
            );
            unavailabilities.push({
              start_datetime: startTime,
              end_datetime: endTime,
            });
          });
        }
      });

      result[role.id].push({
        id: employee.id,
        unavailabilities,
      });
    });
  });
  return result;
};

const getFormInitialValues: (
  data: OpenShiftRequest | undefined,
  roles: OpenShiftFormRole[],
  enableCustomPayment: boolean,
  showBreakTime: boolean,
  isEdit: boolean,
) => OpenShiftFormData = (data, roles, enableCustomPayment, showBreakTime, isEdit) => {
  let employees = data?.requested_employees || [];
  if (isEdit && !employees.length) {
    employees = roles.find((role) => role.id === data?.role.id)?.employees || [];
  }

  return {
    start_time: data?.start_time || '',
    end_time: data?.end_time || '',
    is_close: !!data?.is_close,
    shift_name: data?.shift.name || '',
    role_id: String(data?.role.id || ''),
    note: data?.note || '',
    number_of_employees: data?.number_of_employees || 1,
    requested_employees: employees.map((emp) => +emp.id).sort((a, b) => a - b),
    shift_id: data?.shift.id || undefined,
    ...(showBreakTime ? { break_time: data?.break_time || 0 } : {}),

    ...(enableCustomPayment
      ? {
          wage: Number(generalUtils.formatDecimalNumber(data?.custom_payment?.wage || '0', 3)),
          payment_type: data?.custom_payment?.payment_type_id || 0,
        }
      : {}),
  };
};

const getAvailableAndUnAvailableEmployees = (
  date: string,
  roleId: number,
  startTime: string,
  endTime: string,
  employeesScheduleUnavailability: EmployeesUnAvailabilityFromSchedule,
  unavailabilities: EmployeesUnAvailability,
) => {
  if (!roleId) return { availableEmployees: [], unAvailableEmployees: [] };
  const { startTime: shiftStartTime, endTime: shiftEndTime } = shiftUtils.getShiftIntervalDateTime(
    date,
    startTime,
    endTime,
  );

  const roleShifts = employeesScheduleUnavailability[roleId] || [];
  const roleEmployees =
    unavailabilities
      ?.filter((emp) => emp.role_ids?.includes(+roleId))
      .map((emp) => {
        const employeeShifts = roleShifts.find((account) => account.id === emp.id) || {
          unavailabilities: [],
        };
        return {
          ...emp,
          unavailabilities: [...emp.unavailabilities, ...employeeShifts.unavailabilities],
        };
      }) || [];

  const availableEmployees: OpenShiftEmployee[] = [];
  const unAvailableEmployees: OpenShiftEmployee[] = [];

  roleEmployees?.forEach((emp) => {
    const isUnavailable = emp?.unavailabilities?.some((unavailableTime) => {
      if (unavailableTime.start_datetime === unavailableTime.end_datetime) {
        return true;
      }
      return (
        dateUtils.getDateTimeRangeOverlap(
          { start: shiftStartTime, end: shiftEndTime },
          { start: unavailableTime.start_datetime, end: unavailableTime.end_datetime },
        ) > 0
      );
    });
    if (isUnavailable) unAvailableEmployees.push({ id: emp.id, name: emp.name });
    else availableEmployees.push({ id: emp.id, name: emp.name });
  });

  return { availableEmployees, unAvailableEmployees };
};

export default {
  getAddOpenShiftRequestData,
  getUpdateOpenShiftRequestData,
  getEmployeesUnAvailabilityFromSchedule,
  getFormInitialValues,
  getAvailableAndUnAvailableEmployees,
};

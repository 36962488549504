/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ActionStatus, DialogName } from '../../enums';
import { State } from '../../types/Store';
import { ScheduleFormData } from '../../types/Schedule';
import { DeleteShiftDialogData } from '../../types/Shift';
import { RequestsEmployees } from '../../types/Requests';
import utils from '../../shared/utils';
import { OpenShiftRequest, SelectedEmployeesDialogData } from '../../types/OpenShift';
import { PunchDialogData } from '../../modules/Reporting/types/ReportTable';
import { Employee } from '../../types/Employees';

export interface OpenDialogArgs<T> {
  data: T;
  date?: string | undefined;
  action?: ActionStatus | undefined;
  dialogId: DialogName;
}

interface DialogState<T> extends Omit<OpenDialogArgs<T>, 'dialogId'> {
  open: boolean;
}

const initialDialogState: DialogState<object> = {
  open: false,
  data: {},
  date: undefined,
  action: undefined,
};

export interface InitialState<T> {
  [DialogName.SCHEDULE_DIALOG]: DialogState<ScheduleFormData>;
  [DialogName.REMOVE_SHIFT_DIALOG]: DialogState<DeleteShiftDialogData>;
  [DialogName.REMOVE_EMPLOYEE_DIALOG]: DialogState<T>;
  [DialogName.IMPORT_EMPLOYEE_DIALOG]: DialogState<T>;
  [DialogName.ADD_SHIFT_DIALOG]: DialogState<T>;
  [DialogName.REQUEST_DIALOG]: DialogState<RequestsEmployees[]>;
  [DialogName.ERROR_DIALOG]: DialogState<T>;
  [DialogName.SELECTED_SHIFTS_DIALOG]: DialogState<T>;
  [DialogName.SELECTED_DEFAULT_SHIFTS_DIALOG]: DialogState<T>;
  [DialogName.SALES_INPUT_TYPES_DIALOG]: DialogState<T>;
  [DialogName.PROJECTED_SALES_COMPUTATION_DIALOG]: DialogState<T>;
  [DialogName.ADD_TEMPORARY_EMPLOYEE_DIALOG]: DialogState<T>;
  [DialogName.TEMPORARY_EMPLOYEE_LIST_DIALOG]: DialogState<T>;
  [DialogName.CUSTOM_TRANSFER_DURATION_DIALOG]: DialogState<T>;
  [DialogName.CREATE_PASSWORD_DIALOG]: DialogState<T>;
  [DialogName.RESET_PASSWORD_DIALOG]: DialogState<Employee | undefined>;
  [DialogName.MANAGE_POSITIONS_DIALOG]: DialogState<T>;
  [DialogName.MANAGE_SALES_INPUT_TYPES_DIALOG]: DialogState<T | undefined>;
  [DialogName.SALES_PERCENTAGE_CAP]: DialogState<T | undefined>;
  [DialogName.NOTIFY_OPEN_SHIFT_EMPLOYEES]: DialogState<OpenShiftRequest | undefined>;
  [DialogName.OPEN_SHIFT_ACCEPTED_EMPLOYEES]: DialogState<OpenShiftRequest | undefined>;
  [DialogName.OPEN_SHIFT_CONFIRM_EMPLOYEES]: DialogState<OpenShiftRequest | undefined>;
  [DialogName.MANAGE_OPEN_SHIFT_DIALOG]: DialogState<OpenShiftRequest | undefined>;
  [DialogName.OPEN_SHIFT_SELECTED_EMPLOYEES_DIALOG]: DialogState<SelectedEmployeesDialogData>;
  [DialogName.REMOVE_OPEN_SHIFT_DIALOG]: DialogState<OpenShiftRequest | undefined>;
  [DialogName.SELECTED_SCHEDULE_DIALOG]: DialogState<undefined>;
  [DialogName.COPY_FROM_DEMO_DIALOG]: DialogState<undefined>;
  [DialogName.MULTI_SELECTED_SCHEDULES_ACTION_DEMO]: DialogState<undefined>;
  [DialogName.PRINT_EXCEL_FILE_DEMO]: DialogState<undefined>;
  [DialogName.ADD_MULTI_PUNCH_TIP_DIALOG]: DialogState<undefined>;
  [DialogName.PUNCH_DIALOG]: DialogState<PunchDialogData | null>;
}

const initialState: InitialState<object> = {
  [DialogName.SCHEDULE_DIALOG]: {
    ...initialDialogState,
    data: {
      account: { first_name: '', last_name: '', id: 0, roleId: 0 },
      branchId: 0,
      date: '',
      role: { hourly_wage: '', id: 0, payment_type: 0 },
      schedule: [],
      scheduleId: undefined,
      preferedShifts: null,
      unavailableShifts: null,
    },
  },
  [DialogName.REMOVE_SHIFT_DIALOG]: {
    ...initialDialogState,
    data: {
      shift: { end_hour: '', id: 0, name: '', role_requirements: [], start_hour: '' },
      weekday: 0,
    },
  },
  [DialogName.REMOVE_EMPLOYEE_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.IMPORT_EMPLOYEE_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.ADD_SHIFT_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.REQUEST_DIALOG]: { ...initialDialogState, data: [] },
  [DialogName.ERROR_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.SELECTED_SHIFTS_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.SELECTED_DEFAULT_SHIFTS_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.SALES_INPUT_TYPES_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.PROJECTED_SALES_COMPUTATION_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.ADD_TEMPORARY_EMPLOYEE_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.TEMPORARY_EMPLOYEE_LIST_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.CUSTOM_TRANSFER_DURATION_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.CREATE_PASSWORD_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.RESET_PASSWORD_DIALOG]: { ...initialDialogState, data: undefined },
  [DialogName.MANAGE_POSITIONS_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.MANAGE_SALES_INPUT_TYPES_DIALOG]: { ...initialDialogState, data: {} },
  [DialogName.SALES_PERCENTAGE_CAP]: { ...initialDialogState, data: {} },
  [DialogName.NOTIFY_OPEN_SHIFT_EMPLOYEES]: { ...initialDialogState, data: undefined },
  [DialogName.OPEN_SHIFT_ACCEPTED_EMPLOYEES]: { ...initialDialogState, data: undefined },
  [DialogName.OPEN_SHIFT_CONFIRM_EMPLOYEES]: { ...initialDialogState, data: undefined },
  [DialogName.MANAGE_OPEN_SHIFT_DIALOG]: { ...initialDialogState, data: undefined },
  [DialogName.OPEN_SHIFT_SELECTED_EMPLOYEES_DIALOG]: {
    ...initialDialogState,
    data: { availableEmployees: [], selectedEmployees: [], unAvailableEmployees: [] },
  },
  [DialogName.REMOVE_OPEN_SHIFT_DIALOG]: {
    ...initialDialogState,
    data: undefined,
  },
  [DialogName.SELECTED_SCHEDULE_DIALOG]: { ...initialDialogState, data: undefined },
  [DialogName.COPY_FROM_DEMO_DIALOG]: { ...initialDialogState, data: undefined },
  [DialogName.MULTI_SELECTED_SCHEDULES_ACTION_DEMO]: { ...initialDialogState, data: undefined },
  [DialogName.PRINT_EXCEL_FILE_DEMO]: { ...initialDialogState, data: undefined },
  [DialogName.ADD_MULTI_PUNCH_TIP_DIALOG]: { ...initialDialogState, data: undefined },
  [DialogName.PUNCH_DIALOG]: { ...initialDialogState, data: null },
};

export const dialog = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: <T>(state: InitialState<T>, action: PayloadAction<OpenDialogArgs<T>>) => {
      const { payload } = action;
      state[payload.dialogId].open = true;
      state[payload.dialogId].action = payload.action;
      state[payload.dialogId].date = payload.date;
      state[payload.dialogId].data = payload.data;
    },
    closeDialog: (state, action: PayloadAction<DialogName>) => {
      state[action.payload].open = false;
    },
    setDate: (
      state,
      action: PayloadAction<{ dialogName: DialogName; date: string; step: number }>,
    ) => {
      // set date tool
      state[action.payload.dialogName].date = utils.date.setDate(action.payload.date, {
        addDays: action.payload.step,
      });
    },
  },
});

export default dialog.reducer;

export const { closeDialog, setDate } = dialog.actions;

export function openDialog<T = 'Data type parameter is required.', AT extends T = T>(
  args: OpenDialogArgs<AT>,
) {
  return {
    payload: args,
    type: 'dialog/openDialog',
  };
}

export const selectDialogState =
  <T>(dialogId: DialogName) =>
  (state: State<InitialState<T>>) =>
    state.dialogSlice[dialogId] as DialogState<T>;

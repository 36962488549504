import { UpdateCurrencyRequest, UpdateHourFormatRequest } from '../types/Settings';
import api from './axios';
import { Response } from '../types/Response';

const updateHourFormat = (branchId: number, is12hr: UpdateHourFormatRequest) =>
  api.put(`/branch/${branchId}/hour_format`, is12hr) as Promise<Response<string>>;
const updateCurrency = (branchId: number, data: UpdateCurrencyRequest) =>
  api.put(`/branch/${branchId}/currency`, data) as Promise<Response<string>>;

export default {
  updateCurrency,
  updateHourFormat,
};

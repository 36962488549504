import {
  AddOpenShiftRequestData,
  AddOpenShiftResponse,
  UpdateOpenShiftRequestData,
} from '../types/OpenShift';
import api from './axios';
import { Response } from '../types/Response';

const getBranchWeek = (branchId: number, date: string) =>
  api.get(`branch/${branchId}/open_shift/week`, { params: { date } });

const postOpenShift = (branchId: number, data: AddOpenShiftRequestData) =>
  api.post(`branch/${branchId}/open_shift`, data) as Promise<Response<AddOpenShiftResponse>>;
const putOpenShift = (
  branchId: number,
  openShiftRequestId: number,
  data: UpdateOpenShiftRequestData,
) =>
  api.put(`branch/${branchId}/open_shift/${openShiftRequestId}`, data) as Promise<Response<number>>;
const deleteOpenShift = (branchId: number, openShiftRequestId: number, keepAccepted: 0 | 1) =>
  api.delete(
    `branch/${branchId}/open_shift/${openShiftRequestId}?keep_accepted=${keepAccepted}`,
  ) as Promise<Response<number>>;

const notifyOpenShiftEmployees = (
  branchId: number,
  openShiftRequestId: number,
  notifyRejected: 0 | 1,
) =>
  api.post(
    `branch/${branchId}/open_shift/${openShiftRequestId}/notify`,
    notifyRejected
      ? {
          notify_rejected: notifyRejected,
        }
      : undefined,
  ) as Promise<Response<string[]>>;
const bulkApproveOpenShiftEmployees = (
  accountId: number,
  openShiftRequestId: number,
  data: {
    approve: number[];
    unapprove: number[];
  },
) =>
  api.put(`/account/${accountId}/open_shift/${openShiftRequestId}/approve/bulk`, data) as Promise<
    Response<boolean>
  >;

export default {
  bulkApproveOpenShiftEmployees,
  getBranchWeek,
  deleteOpenShift,
  postOpenShift,
  putOpenShift,
  notifyOpenShiftEmployees,
};
